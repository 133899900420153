import styled from "styled-components";

export const PrimaryIntakeContainer = styled.div`
  width: 500px;
  height: 500px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;

  .intake__title {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    color: #131313;
    border-bottom: 1px solid var(--theme-color);
  }
  .intake__btnn {
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: 0;
    left: 0;

    display: flex;

    button {
      width: 100px;
      height: 30px;
      margin: 50px 4px;
      border: none;
      border-radius: 5px;
      background-color: var(--theme-color);
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #fff;
        color: var(--theme-color);
        border: 1px solid var(--theme-color);
      }
    }
  }
  form {
    position: relative;
    padding: 10px;
    width: 100%;

    display: flex;
    align-items: center;

    flex-direction: column;
    gap: 8px;
    overflow-y: scroll;

    .intake__input {
      width: 100%;
      min-height: 40px;
      display: flex;
      align-items: center;

      justify-content: space-between;
      gap: 8px;
      textarea {
        width: 50%;
        border: 1px solid #131313;
        border-radius: 5px;
        padding: 0 10px;
        font-size: 1rem;
        font-weight: 500;
        color: #131313;
      }
      .foraddclient {
        height: 40px;
      }
      .long {
        width: 130px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 600;
        color: var(--theme-color);
      }
      label {
        width: 130px;
        /* height: 40px; */
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 600;
        color: var(--theme-color);
      }
      input[type="checkbox"] {
        width: 20px;
      }

      input {
        width: 50%;
        height: 40px;
        border: 1px solid #131313;
        border-radius: 5px;
        padding: 0 10px;
        font-size: 1rem;
        font-weight: 500;
        color: #131313;

        &:focus {
          outline: none;
        }
      }

      select {
        width: 50%;
        height: 80%;
        border: 1px solid #131313;
        border-radius: 5px;
        padding: 0 10px;
        font-size: 1rem;
        font-weight: 500;
        color: #131313;
        text-align: left;
      }
    }
    .intake__inputs {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;

      justify-content: space-between;
      gap: 8px;
      .foraddclient {
        height: 40px;
      }
      .long {
        width: 130px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 600;
        color: var(--theme-color);
      }
      label {
        width: 152px;
        /* height: 40px; */
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 600;
        color: var(--theme-color);
      }
      input[type="checkbox"] {
        width: 20px;
      }

      input {
        width: 50%;
        height: 40px;
        border: 1px solid #131313;
        border-radius: 5px;
        padding: 0 10px;
        font-size: 1rem;
        font-weight: 500;
        color: #131313;

        &:focus {
          outline: none;
        }
      }

      select {
        width: 50%;
        height: 80%;
        border: 1px solid #131313;
        border-radius: 5px;
        padding: 0 10px;
        font-size: 1rem;
        font-weight: 500;
        color: #131313;
        text-align: left;
      }
    }
    .death_place_title {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
    }

    .intake__btn {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
    }
  }
`;
