import styled from "styled-components";

export const EmailContainer = styled.div`
  width: 100%;
  height: 98.5%;
  padding: 20px;
  border-radius: 5px;
  overflow-y: scroll;
  #aboutemail {
    width: 100%;
    height: 100%;
  }

  .emailtemplatediv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 20px 0px;
    font-size: 13px;
    gap: 4px;
    .radio {
      box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
        rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
        rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
      padding: 8px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      position: relative;
    }
    input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
  .checks {
    display: none;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 8px;
    padding: 6px 4px;
    margin-bottom: 6px;

    .ch {
      margin: 0px 4px;
      display: flex;
      align-items: center;

      label {
        font-size: 12px;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        padding: 4px;
        margin: 4px;
        border: 1px solid grey;
        border-radius: 6px;
        cursor: pointer;
      }
    }
    button {
      align-self: center;
      width: 50px;
      height: 25px;
      border: none;
      border-radius: 5px;
      background-color: var(--theme-color);
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #fff;
        color: var(--theme-color);
        border: 1px solid var(--theme-color);
      }
    }
  }
  #email {
    width: 100%;
    height: 70%;
    .subject {
      div {
        padding: 4px;
        border: 1px solid grey;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
      }
    }
    .to,
    .cc,
    .subject {
      div,
      input {
        width: 100%;
        padding: 4px;
        border: 1px solid grey;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
      }
    }

    .message {
      overflow-y: scroll;
      width: 100%;
      height: 55%;
      padding: 10px;
      border: 1px solid grey;
    }
  }
  .btn {
    button {
      margin-top: 10px;
      margin-right: 10px;
      width: 100px;
      height: 30px;
      border: none;
      border-radius: 5px;
      background-color: var(--theme-color);
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      margin-bottom: 20px;
      &:hover {
        background-color: #fff;
        color: var(--theme-color);
        border: 1px solid var(--theme-color);
      }
    }
  }
  #wait-modal {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }
  #gif {
    aspect-ratio: 1/1;
    width: 40px;
  }
  #sending {
    color: white;
  }
`;
