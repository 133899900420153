import { ProfileBannerContainer } from "../../styles/ProfileBanner.styled";
import "styles/index.css";
import { BiArrowBack } from "react-icons/bi";
import clientimg from "../../images/profile.jpg";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import servlabes from "../../data/servicelabels";
import axios from "axios";

const ProfileBanner = ({
  client,
  setShowForms2,
  setLifeInsurance,
  setMemorialCard,
  setMemorialInfo,
  setCasketMerch,
  setFlowerInstructions,
  setToDoList,
}) => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [decimg, setDecimg] = useState(null);
  const [referral, setRefferal] = useState();
  const [def, setDefault] = useState();

  const formatDate = (date) => {
    const d = new Date(date);
    const month = d.toLocaleString("default", { month: "long" });
    const day = d.getDate();
    const year = d.getFullYear();
    return `${month} ${day}, ${year}`;
  };
  useEffect(() => {
    if (client?.image_data) {
      setDecimg(client.image_data);
    }
  }, [client]);

  const handleUpload = async (e) => {
    console.log(e.target.files[0]);

    const reader = new FileReader();
    let img = new Promise((resolve, reject) => {
      // reader.readAsArrayBuffer(file);
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = resolve;
      reader.onerror = reject;
    });
    img = await img;
    if (img.target.result.length >= 100000) {
      alert("Image is too large");
      return;
    }
    const data = {
      image_data: img.target.result,
      id: client.id,
    };
    const res = await axios.put(
      `https://api5.teamfdjfs.com/client/image/${client.id}`,
      data
    );
    if (res.data === "success") {
      console.log("success");
      setDecimg(img.target.result);
      alert("Image uploaded successfully");
    } else {
      console.log("error");
      alert("Error uploading image");
    }
  };

  async function submitRef(value) {
    console.log(value);
    await axios
      .post("https://api5.teamfdjfs.com/referral/saveReferral", {
        refer: value,
        clientId,
      })
      .then((res) => {
        alert("Success");
        console.log(res);
      })
      .catch((err) => {
        alert("Error setting referral");
      });
  }

  useEffect(() => {
    async function getinfo() {
      await axios
        .get("https://api5.teamfdjfs.com/client/getinfo")
        .then((res) => {
          console.log(res.data);
          setRefferal(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    async function getDefault() {
      await axios
        .get(`https://api5.teamfdjfs.com/referral/get/${clientId}`)
        .then((res) => {
          console.log(res.data);
          if (res.data.length > 0) {
            res.data.map((cl) => {
              if (cl.client_id == clientId) {
                setDefault(cl.decname);
                console.log(cl.decname);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getDefault();
    getinfo();
  }, []);

  return (
    <ProfileBannerContainer>
      <div className="banner">
        <BiArrowBack
          className="icon"
          onClick={() => {
            navigate(-1);
            // navigate(`/user/${window.localStorage.getItem("id")}`);
          }}
        />
        <span className="text">Deceased Profile</span>
      </div>
      <div className="profile">
        <div className="profile__img">
          <label htmlFor="decimg">
            <img src={decimg ? decimg : clientimg} alt="profile" />
          </label>
          <input
            type="file"
            id="decimg"
            onChange={handleUpload}
            style={{ display: "none" }}
          />
        </div>
        <div className="profile__name">
          <h3>{client?.decname}</h3>
        </div>
      </div>

      <div className="profile-details">
        <div className="profile-details-title">
          <span>Client Details</span>
        </div>

        <div className="notesbtn">
          <button
            onClick={() => {
              setLifeInsurance(true);
              setShowForms2(true);
              setMemorialCard(false);
              setMemorialInfo(false);
              setCasketMerch(false);
              setFlowerInstructions(false);
              setToDoList(false);
            }}
          >
            Life Insurance
          </button>
          <button
            onClick={() => {
              setMemorialCard(true);
              setShowForms2(true);
              setLifeInsurance(false);
              setMemorialInfo(false);
              setCasketMerch(false);
              setFlowerInstructions(false);
              setToDoList(false);
            }}
          >
            Memorial Card
          </button>

          <button
            onClick={() => {
              setMemorialCard(false);
              setShowForms2(true);
              setLifeInsurance(false);
              setMemorialInfo(true);
              setCasketMerch(false);
              setFlowerInstructions(false);
              setToDoList(false);
            }}
          >
            Memorial Program
          </button>
          <button
            onClick={() => {
              setMemorialCard(false);
              setShowForms2(true);
              setLifeInsurance(false);
              setMemorialInfo(false);
              setCasketMerch(true);
              setFlowerInstructions(false);
              setToDoList(false);
            }}
          >
            Casket/Urn and Merchandise Selection
          </button>

          <button
            onClick={() => {
              setMemorialCard(false);
              setShowForms2(true);
              setLifeInsurance(false);
              setMemorialInfo(false);
              setCasketMerch(false);
              setFlowerInstructions(true);
              setToDoList(false);
            }}
          >
            Flower Instructions
          </button>
          <button
            onClick={() => {
              setMemorialCard(false);
              setShowForms2(true);
              setLifeInsurance(false);
              setMemorialInfo(false);
              setCasketMerch(false);
              setFlowerInstructions(false);
              setToDoList(true);
            }}
          >
            To Do List
          </button>
          <div className="referral">
            <label>Referral Status</label>

            {referral && (
              <select
                defaultValue={def ?? ""}
                onChange={(e) => {
                  submitRef(e.target.value);
                }}
              >
                <option value="">Select</option>
                {referral &&
                  referral.map((ref, key) => {
                    return (
                      <option key={key} value={JSON.stringify(ref)}>
                        {ref.decname}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>
        </div>

        <div className="profile-details__content">
          <div className="profile-details__content__item">
            <span className="profile-details__content__item__title">
              Name of Contact:
            </span>
            <span className="profile-details__content__item__value">
              {client?.firstname}
            </span>
          </div>
          <div className="profile-details__content__item">
            <span className="profile-details__content__item__title">
              Email:
            </span>
            <span className="profile-details__content__item__value">
              {client?.email}
            </span>
          </div>
          <div className="profile-details__content__item">
            <span className="profile-details__content__item__title">
              Telephone:
            </span>
            <span className="profile-details__content__item__value">
              {client?.telephone}
            </span>
          </div>
          <div className="profile-details__content__item">
            <span className="profile-details__content__item__title">
              Relationship:
            </span>
            <span className="profile-details__content__item__value">
              {client?.relationship === "auth_guardian"
                ? "Authorized Guardian"
                : `${
                    client?.relationship?.charAt(0)?.toUpperCase() +
                    client?.relationship?.slice(1)
                  }`}
            </span>
          </div>

          <div className="profile-details__content__item">
            <span className="profile-details__content__item__title">
              Service Type:
            </span>
            <span className="profile-details__content__item__value">
              <ul>
                {client?.servicetype?.split(", ").map((item) => {
                  return <li key={item}>{servlabes[item]},</li>;
                })}
              </ul>
            </span>
          </div>

          <div className="profile-details__content__item">
            <span className="profile-details__content__item__title">
              Inquiry Status:
            </span>
            {client?.updatedinquiry !== null ? (
              <span className="profile-details__content__item__value">
                {client?.inquirystatus} {formatDate(client?.datecreated)} to{" "}
                {client?.updatedinquiry} {formatDate(client?.dateupdated)}
              </span>
            ) : (
              <span className="profile-details__content__item__value">
                {client?.inquirystatus}
              </span>
            )}
          </div>
          <div className="profile-details__content__item">
            <span className="profile-details__content__item__title">
              Notes:
            </span>
            <span className="profile-details__content__item__value">
              {client?.notes}
            </span>
          </div>
        </div>
      </div>
    </ProfileBannerContainer>
  );
};

export default ProfileBanner;
