import styled from "styled-components";

export const MobileLayoutContainer = styled.section`
  max-width: 768px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  position: relative;
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    width: 100%;
    height: 8vh;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    .nav__menu {
      font-size: 1.5rem;
      cursor: pointer;
    }
    .nav__logo {
      width: 100px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .sidebar {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 40%;
    height: 98%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    z-index: 10;

    .profile {
      width: 100%;
      height: 10%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2px;

      .image {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 90%;
          height: 90%;
          object-fit: cover;
        }
      }
      .detail {
        width: calc(100% - 35px);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .name {
          font-size: 14px;
          font-weight: 600;
        }
        .role {
          font-size: 10px;
          font-weight: 400;
        }
      }
      .close {
        font-size: 1.5rem;
        cursor: pointer;
      }
    }

    .footer {
      width: 100%;
      align-self: flex-end;
    }
  }

  .list {
    width: 100%;
    padding: 0 0.7rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .sublist {
    width: 100%;
    padding: 0 0.7rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    li {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .nav-icon {
        font-size: 1.2rem;
        margin-right: 0.5rem;
      }

      span {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }

    .active {
      background: var(--theme-color);
      color: #fff;
    }
  }
`;
