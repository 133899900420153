import { useState, useEffect } from "react";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { Document, Page } from "react-pdf/dist/esm/entry.parcel2";
import { useParams } from "react-router-dom";
import { format, isValid, parse } from "date-fns";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import file1 from "./Files/Bereavement Flight Request Letter.pdf";
import file2 from "./Files/Blank Contract.pdf";
import file3 from "./Files/Clothing _ Belongings Checklist.pdf";
import file4 from "./Files/Excusal Letter.pdf";
import file5 from "./Files/Flight Itinerary.pdf";
import file6 from "./Files/Funeral-Service-Arrangement-Forms.pdf";
import file7 from "./Files/Medical Contingency Letter.pdf";
import file8 from "./Files/NO PICTURES AT FUNERAL .pdf";
import file9 from "./Files/Pre-need Casket agreement and guarantee.pdf";
import file10 from "./Files/Receipt Template.pdf";
import file11 from "./Files/STATEMENT OF AUTHORITY.pdf";

import file12 from "./Files/Assignment Form/Assignment Form for multiple beneficiaries.pdf";
import file13 from "./Files/Assignment Form/Assignment Form.pdf";

import file14 from "./Files/Funeral Service Arrangement Forms/1 Funeral Service Record Sheet.pdf";
import file15 from "./Files/Funeral Service Arrangement Forms/2 Authorization for Removal Embalming Preparation.pdf";
import file16 from "./Files/Funeral Service Arrangement Forms/3 Control of disposition form.pdf";
import file17 from "./Files/Funeral Service Arrangement Forms/4 At Need Written Statement Of Person Having the Right to Control Disposition.pdf";
import file18 from "./Files/Funeral Service Arrangement Forms/5 Website Agreement.pdf";

import file19 from "./Files/Visa Requests/Canada Visa Request .pdf";
import file20 from "./Files/Visa Requests/Visa Request Template(Chile).pdf";
import file21 from "./Files/Visa Requests/Visa Request Template(Colombia).pdf";
import file22 from "./Files/Visa Requests/Visa Request Template(DR).pdf";
import file23 from "./Files/Visa Requests/Visa Request Template(France).pdf";
import file24 from "./Files/Visa Requests/Visa Request Template(Guyana).pdf";
import file25 from "./Files/Visa Requests/Visa Request Template(Haiti).pdf";
import file26 from "./Files/Visa Requests/Visa Request Template(Jamaica).pdf";
import file27 from "./Files/Visa Requests/Visa Request Template(London).pdf";
import file28 from "./Files/Visa Requests/Visa Request Template(Panama).pdf";
import file29 from "./Files/Visa Requests/Visa Request Template(Paris).pdf";
import file30 from "./Files/Visa Requests/Visa Request(Nicolas Laguerre).pdf";

import file31 from "./Files/SSA-721.pdf";
import file32 from "./Files/Limo Itinerary.pdf";

import { FormsContainer } from "../../styles/Forms.styled";

const formFiles = [
  "Bereavement Flight Request Letter",
  "Blank Contract",
  "Clothing Belongings Checklist",
  "Excusal Letter",
  "Flight Itinerary",
  "Funeral Service Arrangement Forms",
  "Medical Contingency Letter",
  "No Pictures at Funeral",
  "Pre-need Casket Agreement and Guarantee",
  "Receipt Template",
  "Statement of Authority",
  "SSA-721",
  "Limo Itinerary",
];
const assignment = [
  "Assignment Form for Multiple Beneficiaries",
  "Assignment Form",
];
const funeral = [
  "Funeral Service Record Sheet",
  "Authorization for Removal Embalming Preparation",
  "Control of Disposition Form",
  "At Need Written Statement Of Person Having the Right to Control Disposition",
  "Website Agreement",
];
const visa = [
  "Canada Visa Request",
  "Visa Request (Chile)",
  "Visa Request (Colombia)",
  "Visa Request (DR)",
  "Visa Request (France)",
  "Visa Request (Guyana)",
  "Visa Request (Haiti)",
  "Visa Request (Jamaica)",
  "Visa Request (London)",
  "Visa Request (Panama)",
  "Visa Request (Paris)",
  "Visa Request (Nicolas Laguerre)",
];
const formData = [
  file1,
  file2,
  file3,
  file4,
  file5,
  file6,
  file7,
  file8,
  file9,
  file10,
  file11,
  file31,
  file32,
];

const assignmentforms = [file12, file13];

const funeralservice = [file14, file15, file16, file17, file18];
const visaforms = [
  file19,
  file20,
  file21,
  file22,
  file23,
  file24,
  file25,
  file26,
  file27,
  file28,
  file29,
  file30,
];

let combinedformdata = [
  ...formData,
  ...assignmentforms,
  ...funeralservice,
  ...visaforms,
];
let combinedformnames = [...formFiles, ...assignment, ...funeral, ...visa];

let formultiselect = [];
combinedformdata.forEach((formdata, key) => {
  let option = {};
  option.label = combinedformnames[key];
  option.value = formdata;
  option.id = key;
  formultiselect.push(option);
});
// console.log(formultiselect);
// console.log(combinedformdata, combinedformnames);

export async function fetchPdf(
  arrselected,
  filebytes,
  filenames,
  filesetter,
  valuesfromclient,
  objectsetter,
  thumnbnailsetter
) {
  //console.log(typeof arrselected);

  if (typeof arrselected === "object") {
    let fetchtofile = [];
    for (const form of arrselected) {
      // console.log(form);
      const response = await fetch(filebytes[form]);
      //console.log(response);
      const blob = await response.blob();
      console.log(blob);
      const toFile = new File([blob], filenames[form], {
        type: "application/pdf",
      });

      fetchtofile.push(toFile);
    }

    filesetter(fetchtofile); //setFiles
    toBlob(fetchtofile, valuesfromclient, objectsetter);
  } else if (typeof arrselected === "string") {
    const response = await fetch(combinedformdata[arrselected]);
    //console.log(response);
    const blob = await response.blob();
    console.log(blob);
    const toFile = new File([blob], combinedformnames[arrselected], {
      type: "application/pdf",
    });
    console.log(toFile instanceof File);
    toBlob(toFile, null, null, thumnbnailsetter);
  }
}

export async function toBlob(
  arroffiles,
  valuesfromclient,
  objectsetter,
  thumnbnailsetter
) {
  if (arroffiles instanceof Array) {
    objectsetter([]);
    let existingPdfBytes = [];
    for (let i = 0; i < arroffiles.length; i++) {
      existingPdfBytes[i] = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(arroffiles[i]);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
    }

    for (let i = 0; i < existingPdfBytes.length; i++) {
      const pdfDoc = await PDFDocument.load(existingPdfBytes[i]);

      const form = pdfDoc.getForm();
      const fields = form.getFields();
      let Objectkeys = Object?.keys(valuesfromclient);
      let Objectentries = valuesfromclient;
      let currdate = new Date();
      let formatdate = format(new Date(currdate), "MMMM dd, yyyy");

      const courier = await pdfDoc.embedFont(StandardFonts.Courier);

      fields.forEach((field) => {
        const type = field.constructor.name;
        const name = field.getName();
        console.log(type);
        try {
          field?.setFontSize(12);
        } catch (e) {
          console.log("");
        }

        // if (type === "PDFTextField") {
        // form.getTextField(name).updateAppearances(courier);
        if (Objectkeys.includes(name)) {
          if (
            Objectentries[name] &&
            name != "sign1" &&
            name != "sign2" &&
            name != "sign3" &&
            name != "sign4"
          ) {
            form.getTextField(name).setText(Objectentries[name].toString());
          }
          if (
            name === "waketime" &&
            Objectentries.waketime &&
            Objectentries.waketimeend
          ) {
            form
              .getTextField(name)
              .setText(
                format(
                  parse(Objectentries.waketime, "HH:mm:ss", new Date()),
                  "hh:mm aa"
                ) +
                  " - " +
                  format(
                    parse(Objectentries.waketimeend, "HH:mm:ss", new Date()),
                    "hh:mm aa"
                  )
              );
          }
          if (
            name === "funeraltime" &&
            Objectentries.funeraltime &&
            Objectentries.funeraltimeend
          ) {
            form
              .getTextField(name)
              .setText(
                format(
                  parse(Objectentries.funeraltime, "HH:mm:ss", new Date()),
                  "hh:mm aa"
                ) +
                  " - " +
                  format(
                    parse(Objectentries.funeraltimeend, "HH:mm:ss", new Date()),
                    "hh:mm aa"
                  )
              );
          }
          if (name == "deathplace") {
            form
              .getTextField(name)
              .setText(
                Objectentries.died_at ??
                  Objectentries.deathplace +
                    ", " +
                    Objectentries.deathcity +
                    ", " +
                    Objectentries.deathstate
              );
          }

          if (
            (name === "deathdate" ||
              name === "wakedate" ||
              name === "funeraldate") &&
            !isValid(new Date(Objectentries[name]))
          ) {
            console.log("blank");
            form.getTextField(name).setText("");
          } else if (
            (name === "wakedate" ||
              name === "funeraldate" ||
              name === "deathdate") &&
            Objectentries[name] &&
            Objectentries[name]
          ) {
            let formdate = isValid(new Date(Objectentries[name]))
              ? format(new Date(Objectentries[name]), "EEEE, MMMM dd, yyyy")
              : "";
            if (name === "deathdate") {
              formdate = isValid(new Date(Objectentries[name]))
                ? format(new Date(Objectentries[name]), "MMMM dd, yyyy")
                : "";
            }
            form.getTextField(name).setText(formdate);
          }
        } else {
          if (
            (name === "birthmonth" ||
              name === "birthday" ||
              name === "birthyear") &&
            Objectentries["birthdate"] &&
            isValid(new Date(Objectentries["birthdate"]))
          ) {
            let forbirthformat = null;
            if (name === "birthmonth") forbirthformat = "MMMM";
            if (name === "birthday") forbirthformat = "dd";
            if (name === "birthyear") forbirthformat = "yyyy";
            console.log(Objectentries["birthdate"]);

            form
              .getTextField(name)
              .setText(
                format(new Date(Objectentries["birthdate"]), forbirthformat)
              );
          }
          if (name === "namehw") {
            if (Objectentries["fnamehw"]) {
              form
                .getTextField(name)
                .setText(
                  Objectentries["fnamehw"] +
                    " " +
                    Objectentries["mnamehw"] +
                    " " +
                    Objectentries["lnamehw"]
                );
            }
          }
          if (name == "ifveteran") {
            form
              .getTextField(name)
              .setText(Objectentries.veteran + " " + Objectentries.ifveteran);
          }

          if (name === "date") {
            form.getTextField(name).setText(formatdate);
          }

          if (name === "informant") {
            let fullname =
              Objectentries.inffirstname ?? Objectentries.firstname ?? "";

            form.getTextField(name).setText(fullname);
          }
        }
        // } else if (type === "PDFCheckBox") {
        if (name === Objectentries["maritalstat"]) {
          console.log(name);
          form.getCheckBox(Objectentries["maritalstat"]).check(true);
        }
        if (name == "husbwife") {
          const husbwifename =
            Objectentries.fnamehw +
            " " +
            Objectentries.mnamehw +
            " " +
            Objectentries.lnamehw;
          form.getTextField(name).setText(husbwifename);
        }
        if (name == "city" || name == "state" || name == "country") {
          const namekey = "dec" + name;
          form.getTextField(name).setText(Objectentries[namekey]);
        }
        if (name == "address") {
          form.getTextField(name).setText(Objectentries.infaddress);
        }
        if (name == "birthplace") {
          form
            .getTextField(name)
            .setText(
              Objectentries.birthcity +
                ", " +
                Objectentries.birthstate +
                ", " +
                Objectentries.birthcountry
            );
        }
        if (name == "cemeterywithaddress") {
          form
            .getTextField(name)
            .setText(
              Objectentries.cemetary_name + " // " + Objectentries.cemetery_info
            );
        }
        if (
          name == "funeraldate1" &&
          isValid(new Date(Objectentries.funeraldate))
        ) {
          form
            .getTextField(name)
            .setText(format(new Date(Objectentries.funeraldate), "MM/dd/yyyy"));
        }
        if (
          (name == "funeralday" ||
            name == "funeralmonth" ||
            name == "funeralyear") &&
          isValid(new Date(Objectentries.funeraldate))
        ) {
          switch (name) {
            case "funeralday":
              form
                .getTextField(name)
                .setText(format(new Date(Objectentries.funeraldate), "dd"));
              break;
            case "funeralmonth":
              form
                .getTextField(name)
                .setText(format(new Date(Objectentries.funeraldate), "MM"));
              break;
            case "funeralyear":
              form
                .getTextField(name)
                .setText(format(new Date(Objectentries.funeraldate), "yyyy"));
              break;
          }
        }
        // if (name == "birthmonth" || name == "birthyear" || name == "birthday") {
        //   switch (name) {
        //     case "birthmonth": {
        //       form
        //         .getTextField(name)
        //         .setText(format(new Date(Objectentries.birthdate), "MMMM"));

        //       break;
        //     }
        //     case "birthyear": {
        //       form
        //         .getTextField(name)
        //         .setText(format(new Date(Objectentries.birthdate), "yyyy"));
        //       break;
        //     }
        //     case "birthday": {
        //       form
        //         .getTextField(name)
        //         .setText(format(new Date(Objectentries.birthdate), "dd"));
        //       break;
        //     }
        //   }
        // }
        // if (
        //   name == "deathdate" ||
        //   name == "funeraldate" ||
        //   name == "wakedate"
        // ) {
        //   let thedate = "";
        //   switch (name) {
        //     case "deathdate": {
        //       thedate = Objectentries.date_of_death;
        //       break;
        //     }
        //     case "funeraldate": {
        //       thedate = Objectentries.service_date;
        //       break;
        //     }
        //     case "wakedate": {
        //       thedate = Objectentries.viewing_date;
        //       break;
        //     }
        //   }

        //   const fin = isValid(new Date(thedate), "EEEE MMMM dd, yyyy")
        //     ? format(new Date(thedate), "EEEE MMMM dd, yyyy")
        //     : "";
        //   form.getTextField(name).setText(fin);
        // }
        // }
      });

      //const pages = pdfDoc.getPages();

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      objectsetter((prev) => [...prev, blob]); //setObj
    }
  } else if (arroffiles instanceof File) {
    console.log("file");
    let existingPdfBytes = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(arroffiles);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    thumnbnailsetter(blob); //setThumbnailSrc
  }
}

export default function TestForms() {
  const [fromClientValues, setFromClientValues] = useState();
  const [selected, setSelected] = useState([]);
  const [files, setFiles] = useState([]);
  const [obj, setObj] = useState([]);
  const [thumbnails, setThumbnails] = useState();
  const [thumbnailsrc, setThumbnailSrc] = useState();
  const [position, setPosition] = useState();
  const [show, setShow] = useState();
  const [time, setTime] = useState();
  const [searchresult, setSearchResult] = useState([]);

  const { clientId } = useParams();

  useEffect(() => {
    async function fromClient() {
      let combined = {};

      await axios
        .get(
          `https://api5.teamfdjfs.com/forms/getDetailsFromTables/${clientId}`
          //   {
          //   params: { clientId },
          // }
        )
        .then((res) => {
          combined = { ...res.data };
        });
      await axios
        .get(`https://api5.teamfdjfs.com/forms/getFromClient`, {
          params: { clientId },
        })
        .then((res) => {
          for (const key in res.data) {
            if (res.data.hasOwnProperty(key)) {
              // Check if the property exists in obj1 and has a non-null value
              if (combined.hasOwnProperty(key) && combined[key]) {
                console.log(key);
                continue; // Skip if obj1 has the property with non-null value
              }
              console.log(key);
              combined[key] = res.data[key]; // Add the property to the merged object
            }
          }
        });
      console.log(combined);
      setFromClientValues({ ...combined });
    }
    fromClient();
  }, []);

  // useEffect(() => {
  //   async function getFiles() {
  //     await axios
  //       .get("https://api5.teamfdjfs.com/files/readFiles")
  //       .then((data) => {
  //         console.log(typeof data);
  //       });
  //   }
  //   getFiles();
  // }, []);

  useEffect(() => {
    if (thumbnails) {
      fetchPdf(thumbnails, null, null, null, null, null, setThumbnailSrc);
    }
  }, [thumbnails]);

  function selectForms() {
    const checkboxes = document.getElementsByClassName("formcheckbox");
    const select = [...selected];

    Array.from(checkboxes).forEach((checkbox) => {
      if (checkbox.checked && !select.includes(checkbox.value)) {
        select.push(checkbox.value);
      }
    });
    //const searchArr = searchresult.map((service) => service.value);

    setSelected(select);
    document.getElementById("wait-modal").style.display = "flex";
    fetchPdf(
      select,
      combinedformdata,
      combinedformnames,
      setFiles,
      fromClientValues,
      setObj
    );
    document.getElementById("wait-modal").style.display = "none";
  }

  function setFormDisplay(e) {
    document.getElementById("iframediv").style.display = "flex";
    document.getElementById("modform-iframe").src = URL.createObjectURL(obj[e]);
  }

  function showThumbnail(e) {
    setTime(
      setTimeout(() => {
        setThumbnails(e.target.children[0]?.value);
        setPosition({ x: e.pageX, y: e.pageY, z: 1 });
        setShow(true);
      }, 700)
    );
  }
  function hideThumbnail() {
    setShow(false);
    setThumbnailSrc();
    clearTimeout(time);
  }

  function generateRadioForForms(arrayoffiles) {
    return arrayoffiles.forEach((file, key) => {
      return (
        <div
          className="checkboxdiv"
          onMouseEnter={(e) => {
            showThumbnail(e);
          }}
          onMouseLeave={() => hideThumbnail()}
        >
          <input
            key={key}
            className="formcheckbox"
            value={file}
            id={key}
            name={file}
            type="checkbox"
          />
          <label htmlFor={file}>{file}</label>
        </div>
      );
    });
  }

  function searchform(e) {
    if (!e.target.value) {
      setSearchResult([]);
      return;
    }
    let prevsearch = [];
    document.querySelectorAll(".checkboxdiv").forEach((el) => {
      if (
        String(el.innerText)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        prevsearch.push(el.firstChild);
      }
    });
    setSearchResult(prevsearch);
  }
  useEffect(() => {
    if (searchresult.length > 0) {
      let index = [...selected];
      let forname = [];
      let data = [];
      searchresult.forEach((el, key) => {
        if (!index.includes(String(el.id))) {
          forname.push(el.label);
          data.push(el.value);
          index.push(String(el.id));
        }
      });

      setSelected(index);
      document.getElementById("wait-modal").style.display = "flex";
      fetchPdf(
        index,
        combinedformdata,
        combinedformnames,
        setFiles,
        fromClientValues,
        setObj
      );
      document.getElementById("wait-modal").style.display = "none";
    }
  }, [searchresult]);

  return (
    <FormsContainer id="mainformdiv">
      <div id="wait-modal">
        <div id="loading">
          <img
            id="gif"
            src="https://media.tenor.com/5o2p0tH5LFQAAAAi/hug.gif"
          />
        </div>
        <h5 id="sending">Fetching PDFs. This may take some time...</h5>
      </div>
      <div className="formss">
        {/* {show && (
          <div
            className="hover"
            style={{
              left: position?.x + 20,
              top: position?.y - 20,
              zIndex: "10",
              position: "absolute",
            }}
          >
            <Document file={thumbnailsrc} className="doc">
              <Page
                className="pdf-page"
                pageNumber={1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </Document>
          </div>
        )} */}
        <div className="side-nav">
          <div className="nav-option">
            <input
              type="checkbox"
              id="Files"
              style={{ display: "none" }}
              onChange={() => {
                if (
                  document.getElementById("file-opt").style.display === "block"
                ) {
                  document.getElementById("file-opt").style.display = "none";
                } else {
                  document.getElementById("file-opt").style.display = "block";
                }
              }}
            />
            <label className="title-toggle" htmlFor="Files">
              Files
            </label>

            <ul id="file-opt" style={{ display: "none" }}>
              {formData.map((filedata, key) => (
                <li
                  tabIndex={0}
                  key={key}
                  className="checkboxdiv"
                  onMouseEnter={(e) => {
                    showThumbnail(e);
                  }}
                  onMouseLeave={() => hideThumbnail()}
                >
                  <label htmlFor={key}>
                    <input
                      className="formcheckbox"
                      value={key}
                      id={key}
                      type="checkbox"
                      name={key}
                    />
                    {formFiles[key]}
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className="nav-option">
            <input
              type="checkbox"
              id="Forms"
              style={{ display: "none" }}
              onChange={() => {
                if (
                  document.getElementById("form-opt").style.display === "block"
                ) {
                  document.getElementById("form-opt").style.display = "none";
                } else {
                  document.getElementById("form-opt").style.display = "block";
                }
              }}
            />
            <label className="title-toggle" htmlFor="Forms">
              Forms
            </label>
            <ul id="form-opt" style={{ display: "none" }}>
              {assignmentforms.map((filedata, key) => {
                let newkey = key + 11;
                return (
                  <li
                    tabIndex={0}
                    key={newkey}
                    className="checkboxdiv"
                    onMouseEnter={(e) => {
                      showThumbnail(e);
                    }}
                    onMouseLeave={() => hideThumbnail()}
                  >
                    <label htmlFor={newkey}>
                      <input
                        className="formcheckbox"
                        value={newkey}
                        id={newkey}
                        type="checkbox"
                        name={newkey}
                      />
                      {assignment[key]}
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="nav-option">
            <input
              type="checkbox"
              id="FSRS"
              style={{ display: "none" }}
              onChange={() => {
                if (
                  document.getElementById("fsrs-opt").style.display === "block"
                ) {
                  document.getElementById("fsrs-opt").style.display = "none";
                } else {
                  document.getElementById("fsrs-opt").style.display = "block";
                }
              }}
            />
            <label className="title-toggle" htmlFor="FSRS">
              Funeral Service Arrangement Forms
            </label>
            <ul id="fsrs-opt" style={{ display: "none" }}>
              {funeralservice.map((filedata, key) => {
                let newkey = key + 13;
                return (
                  <li
                    tabIndex={0}
                    key={newkey}
                    className="checkboxdiv"
                    onMouseEnter={(e) => {
                      showThumbnail(e);
                    }}
                    onMouseLeave={() => hideThumbnail()}
                  >
                    <label htmlFor={newkey}>
                      <input
                        className="formcheckbox"
                        value={newkey}
                        id={newkey}
                        type="checkbox"
                        name={newkey}
                      />
                      {funeral[key]}
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="nav-option">
            <input
              type="checkbox"
              id="Visa"
              style={{ display: "none" }}
              onChange={() => {
                if (
                  document.getElementById("visa-opt").style.display === "block"
                ) {
                  document.getElementById("visa-opt").style.display = "none";
                } else {
                  document.getElementById("visa-opt").style.display = "block";
                }
              }}
            />
            <label className="title-toggle" htmlFor="Visa">
              Visa Forms
            </label>
            <ul id="visa-opt" style={{ display: "none" }}>
              {visaforms.map((filedata, key) => {
                let newkey = key + 18;
                return (
                  <li
                    tabIndex={0}
                    key={newkey}
                    className="checkboxdiv"
                    onMouseEnter={(e) => {
                      showThumbnail(e);
                    }}
                    onMouseLeave={() => hideThumbnail()}
                  >
                    <label htmlFor={newkey}>
                      <input
                        className="formcheckbox"
                        value={newkey}
                        id={newkey}
                        type="checkbox"
                        name={newkey}
                      />
                      {visa[key]}
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {/* <details
            onMouseEnter={(e) => {
              e.target.parentElement.setAttribute("open", true);
              e.target.parentElement.style.zIndex = "100";
              console.log(e.target.parentElement);
            }}
            onMouseLeave={(e) => {
              e.target.parentElement.removeAttribute("open");
            }}
          >
            <summary>Files</summary>
            <div className="sum">
              {formData.map((filedata, key) => (
                <div
                  key={key}
                  className="checkboxdiv"
                  onMouseEnter={(e) => {
                    showThumbnail(e);
                  }}
                  onMouseLeave={() => hideThumbnail()}
                >
                  <input
                    className="formcheckbox"
                    value={key}
                    id={key}
                    type="checkbox"
                    name={key}
                  />
                  <label htmlFor={key}>{formFiles[key]}</label>
                </div>
              ))}
            </div>
          </details>
          <details
            onMouseEnter={(e) => {
              e.target.parentElement.setAttribute("open", true);
              e.target.parentElement.style.zIndex = "100";
              console.log(e.target.parentElement);
            }}
            onMouseLeave={(e) => {
              e.target.parentElement.removeAttribute("open");
            }}
          >
            <summary>Assignment Forms</summary>
            <div className="sum">
              {assignmentforms.map((filedata, key) => {
                let newkey = key + 11;
                return (
                  <div
                    key={newkey}
                    className="checkboxdiv"
                    onMouseEnter={(e) => {
                      showThumbnail(e);
                    }}
                    onMouseLeave={() => hideThumbnail()}
                  >
                    <input
                      className="formcheckbox"
                      value={newkey}
                      id={newkey}
                      type="checkbox"
                      name={newkey}
                    />
                    <label htmlFor={newkey}>{assignment[key]}</label>
                  </div>
                );
              })}
            </div>
          </details>
          <details
            onMouseEnter={(e) => {
              e.target.parentElement.setAttribute("open", true);
              e.target.parentElement.style.zIndex = "100";
              console.log(e.target.parentElement);
            }}
            onMouseLeave={(e) => {
              e.target.parentElement.removeAttribute("open");
            }}
          >
            <summary>Funerasl Service Arrangement Forms</summary>
            <div className="sum">
              {funeralservice.map((filedata, key) => {
                let newkey = key + 13;
                return (
                  <div
                    key={newkey}
                    className="checkboxdiv"
                    onMouseEnter={(e) => {
                      showThumbnail(e);
                    }}
                    onMouseLeave={() => hideThumbnail()}
                  >
                    <input
                      className="formcheckbox"
                      value={newkey}
                      id={newkey}
                      type="checkbox"
                      name={newkey}
                    />
                    <label htmlFor={newkey}>{funeral[key]}</label>
                  </div>
                );
              })}
            </div>
          </details>
          <details
            onMouseEnter={(e) => {
              e.target.parentElement.setAttribute("open", true);
              e.target.parentElement.style.zIndex = "100";
              console.log(e.target.parentElement);
            }}
            onMouseLeave={(e) => {
              e.target.parentElement.removeAttribute("open");
            }}
          >
            <summary>Visa Requests Forms</summary>
            <div className="sum">
              {visaforms.map((filedata, key) => {
                let newkey = key + 18;
                return (
                  <div
                    key={newkey}
                    className="checkboxdiv"
                    onMouseEnter={(e) => {
                      showThumbnail(e);
                    }}
                    onMouseLeave={() => hideThumbnail()}
                  >
                    <input
                      className="formcheckbox"
                      value={newkey}
                      id={newkey}
                      type="checkbox"
                      name={newkey}
                    />
                    <label htmlFor={newkey}>{visa[key]}</label>
                  </div>
                );
              })}
            </div>
          </details> */}
      </div>

      <div id="iframediv">
        <div id="divformgen">
          <iframe id="modform-iframe" style={{ zoom: 1 }} />
          <button
            onClick={() =>
              (document.getElementById("iframediv").style.display = "none")
            }
          >
            Close
          </button>
        </div>
      </div>

      <div id="rendereddocdiv">
        <div id="gensearch">
          <button id="buttonsearch" onClick={selectForms}>
            Generate
          </button>
          {/* <input
            onChange={(e) => {
              searchform(e);
            }}
            type="search"
            placeholder="Search here"
          /> */}
          <MultiSelect
            style={{ width: "100%" }}
            options={formultiselect}
            value={searchresult}
            onChange={setSearchResult}
            labelledBy="Search forms "
          />
        </div>
        {/* <div>
          <ul>
            {searchresult &&
              searchresult.map((el, key) => (
                <div key={key}>
                  <li dangerouslySetInnerHTML={{ __html: el.outerHTML }} />
                </div>
              ))}
          </ul>
        </div> */}
        <div className="formms">
          {selected &&
            selected.map((file, key) => (
              <div className="genform" id={key} key={key}>
                <Document
                  file={files[key]}
                  className="doc"
                  onClick={(e) => {
                    setFormDisplay(key);
                  }}
                >
                  <Page
                    className="pdf-page"
                    pageNumber={1}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                  {combinedformnames[selected[key]]}
                </Document>
              </div>
            ))}
        </div>
      </div>
    </FormsContainer>
  );
}
