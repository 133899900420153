import { useState, useEffect } from "react";
import { LoginPageSection } from "../styles/LoginPage.styled";
import axios from "axios";
import { useSignIn, useIsAuthenticated } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import logo from "../images/1.jpg";
import { toast } from "react-hot-toast";

const LoginPage = () => {
  const navigate = useNavigate();
  let isAuth = useIsAuthenticated();
  console.log(isAuth());

  const signIn = useSignIn();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password) {
      toast.error("Please enter all fields");
    }

    const user = {
      username: username,
      password: password,
    };

    const { data } = await axios.post(
      "https://api5.teamfdjfs.com/auth/login",
      user
    );

    console.log(data.message);
    if (data.message === "Logged in") {
      console.log(data.user);

      signIn({
        token: data.user.id,
        tokenType: "Bearer",
        expiresIn: 3600,
        authState: {
          loggedIn: true,
          user_firstname: data.user.firstname,
          user_lastname: data.user.lastname,
          user_id: data.user.id,
          user_email: data.user.email,
          user_role: data.user.role,
        },
      });

      window.localStorage.setItem("id", data.user.id);
      window.localStorage.setItem("firstname", data.user.firstname);
      window.localStorage.setItem("lastname", data.user.lastname);
      window.localStorage.setItem("email", data.user.email);
      window.localStorage.setItem("role", data.user.role);

      navigate(`/user/${data.user.id}`);
    } else {
      setUsername("");
      setPassword("");
      toast.error("Wrong username/password combination");
    }
  };

  useEffect(() => {
    if (isAuth()) navigate(`/user/${window.localStorage.getItem("id")}`);
  }, []);

  return isAuth() ? (
    <div></div>
  ) : (
    <LoginPageSection>
      <div className="login-container">
        <form onSubmit={onSubmit}>
          <img src={logo} alt="logo" />
          <h2>Welcome Back</h2>
          <p>Sign in to continue to your account</p>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit">Sign In</button>
        </form>
      </div>
      {/* <div className="login-box">
        <p>Login</p>
        <form onSubmit={onSubmit}>
          <div className="user-box">
            <input
              required
              name="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <label>Username</label>
          </div>
          <div className="user-box">
            <input
              required
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label>Password</label>
          </div>
          <div className="btn">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div> */}
    </LoginPageSection>
  );
};

export default LoginPage;
