import { PrimaryIntakeContainer } from "../../styles/ProfilePrimaryIntake.styled";
import { getInfo, submitFunc } from "./MemorialCard";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const ToDoList = ({ setShowForms2, setToDoList }) => {
  let { clientId } = useParams();
  clientId = Number(clientId);

  useEffect(() => {
    getInfo(
      "https://api5.teamfdjfs.com/standby/saveData",
      ".todolist",
      clientId,
      "todolist"
    );
  }, []);

  return (
    <PrimaryIntakeContainer id="addclient">
      <form>
        <h3>To Do List</h3>
        <div className="intake__inputs">
          <label>Memorial Card Photo and Scripture : </label>
          <input className="todolist" type="date" />
        </div>

        <div className="intake__inputs">
          <label> Limo Pick Up Address and Drop-off : </label>
          <input className="todolist" type="date" />
        </div>

        <div className="intake__inputs">
          <label>Program Info (Order of Service and Obituary)</label>
          <input className="todolist" type="date" />
        </div>

        <div className="intake__inputs">
          <label>Date of payment and clothing drop-off : </label>
          <input className="todolist" type="date" />
        </div>

        <div className="intake__inputs">
          <label>Total : </label>
          <input className="todolist" type="text" />
        </div>

        <div className="intake__inputs">
          <label>Printed : </label>
          <input className="todolist" type="text" />
        </div>

        <div className="intake__inputs">
          <label>Preferred Method of Payment : </label>
          <select className="todolist">
            <option value="">Select</option>
            <option value="Cash">Cash</option>
            <option value="Bank Certified Check">Bank Certified Check</option>
          </select>
        </div>
      </form>
      <div className="intake__btnn">
        <button
          onClick={() => {
            submitFunc(
              "https://api5.teamfdjfs.com/standby/saveData",
              ".todolist",
              clientId,
              "todolist"
            );
          }}
        >
          Save
        </button>

        <button
          type="button"
          onClick={() => {
            setShowForms2(false);
            setToDoList(false);
          }}
        >
          Exit Window
        </button>
      </div>
    </PrimaryIntakeContainer>
  );
};

export default ToDoList;
