import styled from "styled-components";

export const AddTaskContainer = styled.div`
  width: 30%;

  background-color: var(--theme-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  input {
    height: 40px;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 0px 5px;
  }
  select {
    height: 40px;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 0px 5px;
  }
  button {
    height: 30px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
  }
`;
