import { useEffect } from "react";
import { MessageBoardContainer } from "../../styles/MessageBoard.styled";
// import { BsFillTrash2Fill } from "react-icons/bs";
import { AiOutlineHistory } from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import io from "socket.io-client";
import axios from "axios";
const socket = io.connect("https://api5.teamfdjfs.com/message");

const MessageBoard = ({
  message,
  setMessage,
  history,
  setHistory,
  messageData,
  setMessageData,
}) => {
  useEffect(() => {
    let loaded = true;
    if (loaded) {
      axios.get("https://api5.teamfdjfs.com/message").then((res) => {
        let temp = [res.data.message, res.data.history];

        setMessage(temp[0].reverse());
        setHistory(temp[1].reverse());
      });
    }
    return () => {
      loaded = false;
    };
  }, []);
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    return `${month}-${day}-${year}`;
  };

  useEffect(() => {
    socket.on("message", (data) => {
      let temp = data.message;
      setMessage(temp.reverse());
      if (
        data.message[data.message.length - 1].sender !==
        window.localStorage.getItem("firstname") +
          " " +
          window.localStorage.getItem("lastname")
      ) {
        toast.custom(
          <div
            className="notif"
            style={{
              borderRadius: "10px",
              background: "#F9FAFB",
              color: "#1E293B",
              padding: "16px",
              boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            <span style={{ fontSize: "24px" }}>📩</span>
            <span>
              New message from {data.message[data.message.length - 1].sender}
            </span>
          </div>
        );
      }
    });

    socket.on("delete", (data) => {
      let temp = [data.message, data.history];
      setHistory(temp[1].reverse());
      setMessage(temp[0].reverse());
      toast.success("Message Deleted");
    });

    socket.on("delete_history", (data) => {
      setHistory(data.history);
    });
  }, [socket]);

  const generateUniqueId = () => {
    const timestamp = new Date().getTime().toString();
    const randomNumber = Math.floor(Math.random() * 1000).toString();
    return `${timestamp}_${randomNumber}`;
  };
  return (
    <MessageBoardContainer>
      <Toaster />
      <div className="message-board">
        <div className="input">
          <input
            type="text"
            value={messageData}
            onChange={(e) => setMessageData(e.target.value)}
          />

          <button
            type="button"
            onClick={() => {
              const tempID = generateUniqueId();
              if (messageData === "") {
                return alert("Please enter a message");
              }
              socket.emit("message", {
                id: tempID,
                date: new Date().toLocaleString(),
                body: messageData,
                sender:
                  window.localStorage.getItem("firstname") +
                  " " +
                  window.localStorage.getItem("lastname"),
              });
              setMessageData("");
            }}
          >
            Send
          </button>
          <button
            className="history-btn"
            type="button"
            onClick={() => {
              console.log(history);
              document.getElementById("history").classList.toggle("show");
            }}
          >
            <AiOutlineHistory />
          </button>
        </div>
        <select>
          <option value="general">General</option>
          <option value="announcements">Announcements</option>
          <option value="events">Events</option>
          <option value="suggestions">Suggestions</option>
          <option value="urgent">Urgent</option>
        </select>
        <div id="history">
          <div className="history__header">History</div>
          {history.length > 0 ? (
            history
              .filter((item) => item.deletedby !== null && item.date !== null)
              .map((item) => (
                <div className="item" key={item?.id}>
                  <div className="btn">
                    <button
                      type="button"
                      onClick={() => {
                        const confirm = window.confirm(
                          "Are you sure you want to delete this message?"
                        );
                        if (confirm) {
                          socket.emit("delete_history", item);
                        }
                      }}
                    >
                      x
                    </button>
                  </div>
                  <div className="item__header">
                    <div className="item__header__date">
                      {formatDate(item?.date)}
                    </div>
                  </div>
                  <div className="item__body">{item?.body}</div>
                  <div className="item__footer">
                    <div className="item__footer__sender">- {item?.sender}</div>
                    <div className="deletedby">
                      Deleted by: {item?.deletedby}
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div className="empty">No message history</div>
          )}
        </div>
        {message.length > 0 ? (
          message.map((item) => (
            <div className="item" key={item.id}>
              <div className="btn">
                <button
                  type="button"
                  onClick={() => {
                    let temp = item;
                    temp.deletedby =
                      window.localStorage.getItem("firstname") +
                      " " +
                      window.localStorage.getItem("lastname");

                    const confirm = window.confirm(
                      "Are you sure you want to delete this message?"
                    );
                    if (confirm) {
                      socket.emit("delete", temp);
                    }
                  }}
                >
                  x
                </button>
              </div>
              <div className="item__header">
                <div className="item__header__date">
                  {formatDate(item.date)}
                </div>
              </div>
              <div className="item__body">{item.body}</div>
              <div className="item__footer">
                <div className="item__footer__sender">- {item.sender}</div>
              </div>
            </div>
          ))
        ) : (
          <div className="empty">No messages</div>
        )}
      </div>
    </MessageBoardContainer>
  );
};

export default MessageBoard;
