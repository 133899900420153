import { PrimaryIntakeContainer } from "../../styles/ProfilePrimaryIntake.styled";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { submitFunc, getInfo } from "./MemorialCard";

const MemorialInfo = ({ setShowForms2, setMemorialInfo }) => {
  let { clientId } = useParams();
  clientId = Number(clientId);

  useEffect(() => {
    getInfo(
      "https://api5.teamfdjfs.com/standby/saveData",
      ".memorialinfo",
      clientId,
      "memorialinfo"
    );
  }, []);

  return (
    <PrimaryIntakeContainer id="addclient">
      <form>
        <h3>Memorial Program Info</h3>
        <div className="intake__input">
          <label>No Memorial Program: </label>
          <input className="memorialinfo" type="checkbox" />
        </div>

        <div className="intake__input">
          <label> Photo: </label>
          <input className="memorialinfo" type="ttxt" />
        </div>

        <div className="intake__input">
          <label>Order of Service:</label>
          <input className="memorialinfo" type="text" />
        </div>

        <div className="intake__input">
          <label>Obituary: </label>
          <input className="memorialinfo" type="text" />
        </div>

        <div className="intake__input">
          <label>Designed: </label>
          <select className="memorialinfo">
            <option value="">Not Started</option>
            <option value="In Progress">In Progress</option>
            <option value="Completed">Completed</option>
          </select>
        </div>

        <div className="intake__input">
          <label>Printed: </label>
          <select className="memorialinfo">
            <option value="">Not Started</option>
            <option value="In Progress">In Progress</option>
            <option value="Completed">Completed</option>
          </select>
        </div>

        <div className="intake__input">
          <label>Quantity : </label>
          <input className="memorialinfo" type="number" />
        </div>
      </form>
      <div className="intake__btnn">
        <button
          onClick={() => {
            submitFunc(
              " https://api5.teamfdjfs.com/standby/saveData",
              ".memorialinfo",
              clientId,
              "memorialinfo"
            );
          }}
        >
          Save
        </button>

        <button
          type="button"
          onClick={() => {
            setShowForms2(false);
            setMemorialInfo(false);
          }}
        >
          Exit Window
        </button>
      </div>
    </PrimaryIntakeContainer>
  );
};

export default MemorialInfo;
