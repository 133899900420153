import styled from "styled-components";

export const FormsContainer = styled.div`
  * {
    ul {
      li {
        list-style: none;
      }
    }
  }
  padding: 10px;
  width: 100%;
  height: 96%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  border: 1px solid grey;
  @media only screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    height: 100%;
    .formss {
      height: 100px;
      margin: 20px 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
    }
    #rendereddocdiv {
      display: flex;
      padding: 20px;
      overflow: hidden;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 50px;
      height: 100%;
      max-width: 100%;
    }
  }
  #wait-modal {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }
  #gif {
    aspect-ratio: 1/1;
    width: 40px;
  }
  #sending {
    color: white;
  }

  .genform {
    height: 70%;
    width: 250px;
    border: 1px solid black;
    background-color: lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px 4px;
  }
  #mainformdiv {
    width: 100%;
    height: 100%;
  }

  .formss {
    height: 95%;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 6;

    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 10px; /* width of the entire scrollbar */

      border-radius: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #fff; /* color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {
      background-color: grey; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 3px solid #fff; /* creates padding around scroll thumb */
    }
    /* kaw na pre mag design nung sa dropdown hahahaha */
    .side-nav {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;
      .nav-option {
        width: 100%;
        .title-toggle {
          margin-top: 5px;
          margin-bottom: 5px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 40px;
          border-radius: 5px;
          transition: all 0.3s ease-in-out;
          box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
            rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
          padding: 5px;
        }
        label {
          cursor: pointer;
          font-size: 13px;
        }
      }
    }

    /* yan pre yung sa dropdown */

    .sum {
      position: absolute;
      border: 1px solid black;
      background-color: white;
      z-index: 100;
    }

    .checkboxdiv {
      box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
        rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
        rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
      margin: 6px;
      padding: 5px;
      border-radius: 5px;
      font-size: 13px;

      vertical-align: center;
      justify-content: center;
      align-items: center;

      input {
        margin-right: 5px;
      }
    }
    button {
      padding: 5px;
      border: none;
      border-radius: 5px;
      background-color: var(--theme-color);
      color: #fff;
      font-size: 13px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #fff;
        color: var(--theme-color);
        border: 1px solid var(--theme-color);
      }
    }
  }
  #iframediv {
    width: 100%;
    height: 100%;

    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    //blur background

    #divformgen {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 80%;
      height: 80%;
      align-items: center;
      justify-content: center;
    }
    button {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 85px;
      margin-right: -60px;
      padding: 5px;
      border: none;
      border-radius: 2px;
      background-color: var(--theme-color);
      background-color: #fff;
      color: var(--theme-color);
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: var(--theme-color);
        color: #fff;
        border: 1px solid var(--theme-color);
      }
    }
    #modform-iframe {
      width: 100%;
      height: 100%;
    }
  }
  .doc {
    width: 100%;
    height: 100%;
  }
  .pdf-page {
    width: 100%;
    height: 100%;
  }
  .react-pdf__Page__canvas {
    max-width: 100%;
    max-height: 100%;
  }
  #rendereddocdiv {
    flex: 6;
    display: flex;
    padding: 20px;

    flex-wrap: wrap;
    flex-direction: row;
    gap: 50px;
    height: 100%;
    max-width: 100%;

    .formms {
      width: 100%;
      height: 85%;
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 50px;
      ::-webkit-scrollbar {
        width: 10px; /* width of the entire scrollbar */

        border-radius: 8px;
      }
      ::-webkit-scrollbar-track {
        background: #fff; /* color of the tracking area */
      }
      ::-webkit-scrollbar-thumb {
        background-color: grey; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 3px solid #fff; /* creates padding around scroll thumb */
      }
    }

    #gensearch {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 100%;
      height: 35px;
      gap: 20px;

      #buttonsearch {
        width: 120px;

        padding: 5px;
        outline: none;
        border: none;
        border-radius: 2px;
        background-color: var(--theme-color);
        color: #fff;
        border-radius: 10px;

        font-size: 15px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: #fff;
          color: var(--theme-color);
          border: 1px solid var(--theme-color);
        }
      }
      /* input {
        width: 300px;
        border: none;
        border: 1px solid grey;
        border-radius: 5px;
        padding: 5px;
        outline: none;
      } */
      .multi-select {
        width: 500px;
        max-width: 500px;
      }

      .dropdown-content {
        li {
          width: -webkit-fill-available;
          height: 50px;

          box-shadow: #000;

          label {
            padding: 10px;
            max-width: -webkit-fill-available;
            padding: 10px;
            height: 100%;
          }
        }
      }
    }
    // button {
    //   width: 120px;

    //   padding: 5px;

    //   border: none;
    //   border-radius: 2px;
    //   background-color: var(--theme-color);
    //   color: #fff;
    //   border-radius: 10px;

    //   font-size: 15px;
    //   cursor: pointer;
    //   transition: all 0.3s ease-in-out;

    //   &:hover {
    //     background-color: #fff;
    //     color: var(--theme-color);
    //   }
    //   border: 1px solid var(--theme-color);
    // }
  }

  .hover {
    aspect-ratio: 8/10;
    width: 250px;
    max-width: 250px;
    border: 1px solid black;
  }
`;
