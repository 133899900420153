import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export default function Redirect() {
  const navigate = useNavigate();
  const params = useParams();
  console.log(params.clientid);
  if (
    Boolean(params.setTask) === true &&
    params.taskid &&
    params.taskid !== 0
  ) {
    console.log("redirecting");
    navigate(`/profile/redirect/${Boolean(params.setTask)}/${params.clientid}`);
    // window.location.href = `/profile/redirect/${Boolean(params.setTask)}/${
    //   params.clientid
    // }`;
  } else {
    console.log("not");
  }

  return <div>Redirect</div>;
}
