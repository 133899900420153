import React from "react";
import { Outlet } from "react-router-dom";
import logo from "images/fdjfslogo.jpg";
import { MobileDashboardContainer } from "styles/MobileDashboard.styled";
const MobileDashboard = () => {
  return (
    <MobileDashboardContainer>
      <div className="nav">
        <div className="nav__logo">
          <img
            src={
              logo
                ? logo
                : "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
            }
            alt="logo"
          />
        </div>
      </div>
      <div className="content">
        <Outlet />
      </div>
    </MobileDashboardContainer>
  );
};

export default MobileDashboard;
