import { useState, useEffect } from "react";
import { AddClientContainer } from "../../styles/AddClient.styled";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";

const AddClient = ({
  setShowForm,
  setAddClient,
  donthidebutton,
  setNewClient,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [relationship, setRelationship] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [decName, setDecName] = useState("");
  const [serviceTypes, setServiceType] = useState();
  const [services, setServices] = useState([]);
  const [inquiryStatus, setInquiryStatus] = useState("");
  const [notes, setNotes] = useState("");
  const [totalprice, setTotalPrice] = useState(0);
  const [viewservice, setViewService] = useState();

  useEffect(() => {
    if (viewservice) {
      window.open(`/contracts/${viewservice}`, "_blank");

      //     let totalprice = 0;
      //     services.map((sel) => {
      //       totalprice += sel.price;
      //     });
      //     console.log(totalprice);
      //     setTotalPrice(totalprice);
      //   } else {
      //     setTotalPrice(0);
    }
  }, [viewservice]);

  useEffect(() => {
    console.log(services);
  }, [services]);

  useEffect(() => {
    const getprice = async () => {
      await axios
        .get("https://api5.teamfdjfs.com/prices/getPrices")
        .then((res) => {
          console.log(res);
          let obj = [];
          res.data.map((serv) => {
            let perserv = {};
            perserv.label = `${
              serv.name +
              "---" +
              "$" +
              Number(serv.TOTALFUNERALCHARGES.toFixed(2)).toLocaleString(
                "en-US",
                { minimumFractionDigits: 2 }
              )
            }`;
            perserv.value = serv.service;
            perserv.price = serv.TOTALFUNERALCHARGES;
            obj.push(perserv);
          });
          console.log(obj);
          setServiceType(obj);
        });
    };
    getprice();
  }, []);

  const onServiceTypeChange = (e) => {
    if (e.target.checked) {
      setServices([...services, e.target.id]);
    } else {
      let filteredServices = services.filter((service) => {
        return service !== e.target.id;
      });
      setServices(filteredServices);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (services?.length === 0) {
      alert("Please select at least one service");
      return;
    }
    const serArr = await services.map((service) => service.value).join(", ");
    try {
      const { data } = await axios.post("https://api5.teamfdjfs.com/client", {
        firstName,
        middleName,
        lastName,
        relationship,
        telephone,
        email,
        decName,
        serArr,
        inquiryStatus,
        notes,
      });

      if (data === "Client already exist") {
        alert("Client already exist");

        return;
      } else {
        alert("Client added");

        window.location.reload();
        setShowForm(false);
        setAddClient(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const serArr = services.map((service) => service.value).join(", ");
    setNewClient((prev) => ({
      ...prev,
      ...{
        firstName,
        middleName,
        lastName,
        relationship,
        telephone,
        email,
        decName,
        serArr,
        inquiryStatus,
        notes,
      },
    }));
  }, [
    firstName,
    middleName,
    lastName,
    relationship,
    telephone,
    email,
    decName,
    services,
    inquiryStatus,
    notes,
  ]);
  return (
    <AddClientContainer id="addclient">
      <form onSubmit={onSubmit}>
        {/* inputs */}
        <div className="left">
          <div className="inputs">
            <label htmlFor="FirstName">Contact Name</label>
            <input
              className="foraddclient required"
              type="text"
              required
              defaultValue={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          {/* <div className="addClient__body__input">
          <label htmlFor="MiddleName">Middle Name</label>
          <input
            type="text"
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
          />
        </div>

        <div className="addClient__body__input">
          <label htmlFor="LastName">Last Name</label>
          <input
            type="text"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div> */}

          <div className="inputs">
            <label htmlFor="Relationship">Relationship</label>
            <select
              className="foraddclient required"
              defaultValue={relationship}
              onChange={(e) => setRelationship(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Self">Self</option>
              <option value="Authorized Guardian">Authorized Guardian</option>
              <option value="Husband">Husband</option>
              <option value="Wife">Wife</option>
              <option value="Son">Son</option>
              <option value="Daughter">Daughter</option>
              <option value="Brother">Brother</option>
              <option value="Sister">Sister</option>
              <option value="Father">Father</option>
              <option value="Mother">Mother</option>
              <option value="Niece">Niece</option>
              <option value="Nephew">Nephew</option>
              <option value="Cousin">Cousin</option>
              <option value="Friend">Friend</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="inputs">
            <label htmlFor="Telephone">Telephone</label>
            <input
              className="foraddclient  required"
              type="text"
              required
              defaultValue={telephone}
              onChange={(e) => setTelephone(e.target.value)}
            />
          </div>

          <div className="inputs">
            <label htmlFor="Email">Email</label>
            <input
              className="foraddclient required"
              type="email"
              required
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="inputs">
            <label htmlFor="DecName">Decedent Name</label>
            <input
              className="foraddclient required"
              type="text"
              required
              defaultValue={decName}
              onChange={(e) => setDecName(e.target.value)}
            />
          </div>
          {/* <div className="div">
          <span>Total price: ${totalprice}</span>
        </div> */}

          {/*  */}
          <div className="inputs">
            <label htmlFor="Notes">Inquiry Status</label>
            <select
              className="foraddclient required"
              defaultValue={inquiryStatus}
              onChange={(e) => setInquiryStatus(e.target.value)}
            >
              <option value="">Select</option>
              <option value="At need">At-need </option>
              <option value="Pre-need">Pre-need</option>
              <option value="Merchandise">Merchandise</option>
              <option value="Miscellaneous">Miscellaneous</option>
              <option value="Pre-need to At-need">Pre-need to At-need</option>
              <option value="Pending Pre-need">Pending Pre-need</option>
              <option value="Pending At-need">Pending At-need</option>
              <option value="Pricing for At-need">Pricing for At-need</option>
              <option value="Pricing for Pre-need">Pricing for Pre-need</option>
            </select>
          </div>

          {/* <div className="inputs">
            <label htmlFor="Notes">Notes</label>
            <input
              className="foraddclient"
              type="textfield"
              defaultValue={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div> */}
          <div className="inputss">
            <label htmlFor="ServiceType">Service Type</label>

            <div className="services">
              {serviceTypes && (
                // <MultiSelect
                //   style={{ width: "100%" }}
                //   options={serviceTypes}
                //   value={services}
                //   onChange={setServices}
                //   labelledBy="Select"
                //   className="multi foraddclient "
                // />
                <div style={{ position: "relative", width: "200%" }}>
                  <MultiSelect
                    style={{ width: "100%" }}
                    options={serviceTypes}
                    value={services}
                    onChange={setServices}
                    ItemRenderer={({ option, checked, onClick }) => {
                      return (
                        <div
                          style={{
                            // backgroundColor: "yellow",
                            display: "flex",
                            flexDirection: "row",

                            width: "100%",
                            // position: "relative",
                            marginBottom: "5px",
                          }}
                        >
                          <label
                            style={{
                              border: "1px solid #ccc",
                              // backgroundColor: "teal",
                              // sposition: "absolute",
                              fontSize: "10px",
                              width: "100%",
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={checked}
                              onChange={onClick}
                            />
                            {option.label}
                          </label>

                          <button
                            style={{ display: "inline-block" }}
                            type="button"
                            onClick={() => {
                              //console.log(`Button clicked for ${option.label}`);
                              setViewService(option.value);
                            }}
                          >
                            View
                          </button>
                        </div>
                      );
                    }}
                    labelledBy="Select"
                    className="multi foraddclient"
                  />
                </div>
              )}
            </div>

            {donthidebutton && (
              <div className="btns">
                {/* <button
              type="button"
              onClick={() => {
                const serArr = services
                  .map((service) => service.value)
                  .join(", ");
                console.log(serArr);
              }}
            >
              Test
            </button> */}
                <button
                  type="button"
                  onClick={() => {
                    setShowForm(false);
                    setAddClient(false);
                  }}
                >
                  Exit Window
                </button>
                <button type="submit">Add Client</button>
              </div>
            )}
          </div>
        </div>
      </form>
    </AddClientContainer>
  );
};

export default AddClient;
