import { useState, useEffect } from "react";
import { FuneralDetailsContainer } from "../../styles/ProfileFuneralDetail.styled";
import axios from "axios";
import { format, isValid } from "date-fns";

const ProfileFuneralDetails = ({
  setShowForms,
  setFuneralDetails,
  clientId,
  toast,
  donthidebutton,
  setNewClient,
  newClient,
}) => {
  const [formData, setFormData] = useState({
    religion: newClient?.funeraldetails?.religion ?? "",
    church_affil: newClient?.funeraldetails?.church_affil ?? "",
    service_location: newClient?.funeraldetails?.service_location ?? "",
    service_info: newClient?.funeraldetails?.service_info ?? "",
    service_date: newClient?.funeraldetails?.service_date ?? "",
    service_time: newClient?.funeraldetails?.service_time ?? "",
    service_endtime: newClient?.funeraldetails?.service_endtime ?? "",
    viewing_location: newClient?.funeraldetails?.viewing_location ?? "",
    viewing_info: newClient?.funeraldetails?.viewing_info ?? "",
    viewing_date: newClient?.funeraldetails?.viewing_date ?? "",
    viewing_time: newClient?.funeraldetails?.viewing_time ?? "",
    viewing_endtime: newClient?.funeraldetails?.viewing_endtime ?? "",
    cemetary_status: newClient?.funeraldetails?.cemetary_status ?? "",
    cemetary_name: newClient?.funeraldetails?.cemetary_name ?? "",
    cemetery_info: newClient?.funeraldetails?.cemetery_info ?? "",
    cemetary_booking: newClient?.funeraldetails?.cemetary_booking ?? "",
    arrival_date: newClient?.funeraldetails?.arrival_date ?? "",
    arrival_time: newClient?.funeraldetails?.arrival_time ?? "",
    type_of_disposition: newClient?.funeraldetails?.type_of_disposition ?? "",
  });
  const [update, setUpdate] = useState(false);
  const formatDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month =
      newDate.getMonth() + 1 > 9
        ? newDate.getMonth() + 1
        : "0" + (newDate.getMonth() + 1);
    const day =
      newDate.getDate() > 9 ? newDate.getDate() : "0" + newDate.getDate();
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    const getFuneralDetails = async () => {
      const serviceVerified = document.getElementById("serviceLocation");
      const cemetaryVerified = document.getElementById("cemetary_status");
      const cemetaryNameVerified = document.getElementById("cemetary_name");
      // await axios
      //   .get(`https://api5.teamfdjfs.com/funeral/${clientId}`)
      //   .then((res) => {
      //     console.log(res.data);
      if (newClient?.funeraldetails) {
        console.log(newClient.funeraldetails);
        setUpdate(true);
        const data = newClient?.funeraldetails;

        const keys = Object.entries(data).reduce((acc, [key, value]) => {
          if (value !== null && key !== "client_id" && key !== "id") {
            acc.push(key);
          }
          return acc;
        }, []);

        const newFormData = keys.reduce((acc, key) => {
          if (
            key === "service_date" ||
            key === "viewing_date" ||
            key === "arrival_date"
          ) {
            acc[key] = formatDate(data[key]);
          } else {
            if (
              key === "cemetary_status" ||
              key === "cemetary_name" ||
              key === "service_location"
            ) {
              if (data[key].includes("TBD")) {
                if (key === "cemetary_status") {
                  cemetaryVerified.checked = false;
                } else if (key === "cemetary_name") {
                  cemetaryNameVerified.checked = false;
                } else if (key === "service_location") {
                  serviceVerified.checked = false;
                }
              } else {
                if (key === "cemetary_status") {
                  cemetaryVerified.checked = true;
                } else if (key === "cemetary_name") {
                  cemetaryNameVerified.checked = true;
                } else if (key === "service_location") {
                  serviceVerified.checked = true;
                }
              }
            }
            acc[key] = data[key];
          }
          return acc;
        }, {});

        //setFormData(newClient.funeraldetails);
      }
      // })
      // .catch((e) => {
      //   console.log(e);
      // });
    };
    if (clientId) getFuneralDetails();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    axios
      .post("https://api5.teamfdjfs.com/funeral", formData)
      .then((res) => {
        console.log(res);
        toast.success("Funeral Details Added");
        setShowForms(false);
        setFuneralDetails(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onUpdate = (e) => {
    e.preventDefault();
    const serviceVerified = document.getElementById("serviceLocation");
    const cemetaryVerified = document.getElementById("cemetary_status");
    const cemetaryNameVerified = document.getElementById("cemetary_name");

    const funeralDetails = {
      service_location:
        serviceVerified.checked === false &&
        formData.service_location !== "" &&
        formData?.service_location?.includes("TBD") === false
          ? formData.service_location + " (TBD)"
          : formData.service_location,
      service_date: formData.service_date ? formData.service_date : null,
      service_time: formData.service_time ? formData.service_time : null,
      viewing_date: formData.viewing_date ? formData.viewing_date : null,
      viewing_time: formData.viewing_time ? formData.viewing_time : null,
      cemetary_status:
        cemetaryVerified.checked === false &&
        formData.cemetary_status !== "" &&
        formData?.cemetary_status?.includes("TBD") === false
          ? formData.cemetary_status + " (TBD)"
          : formData.cemetary_status,
      cemetary_name:
        cemetaryNameVerified.checked === false &&
        formData.cemetary_name !== "" &&
        formData?.cemetary_name?.includes("TBD") === false
          ? formData.cemetary_name + " (TBD)"
          : formData.cemetary_name,
      cemetary_booking: formData.cemetary_booking
        ? formData.cemetary_booking
        : null,
      arrival_date: formData.arrival_date ? formData.arrival_date : null,
      arrival_time: formData.arrival_time ? formData.arrival_time : null,
      type_of_disposition: formData.type_of_disposition
        ? formData.type_of_disposition
        : null,
    };
    console.log(funeralDetails);

    axios
      .put(`https://api5.teamfdjfs.com/funeral/${clientId}`, funeralDetails)
      .then((res) => {
        console.log(res);
        toast.success("Funeral Details Updated");
        setShowForms(false);
        //setFuneralDetails(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (setNewClient && clientId) {
      setNewClient((prev) => ({ ...prev, funeraldetails: formData }));
    } else {
      setNewClient((prev) => ({ ...prev, ...formData }));
    }
  }, [formData, setNewClient]);

  return (
    <FuneralDetailsContainer>
      <div className="funeral-details">
        <div className="funeral-details__title">
          <h3>Service Details</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="funeral-details__input">
            <input type="checkbox" name="religion" id="religion" />
            <label htmlFor="serviceLocation">Religion</label>
            <select
              className="religion"
              value={formData?.religion}
              onChange={(e) =>
                setFormData({ ...formData, religion: e.target.value })
              }
            >
              <option value="">Select</option>
              <option value="Christian">Christian</option>
              <option value="Catholic">Catholic </option>
              <option value="Seventh Day Adventist">
                Seventh Day Adventist
              </option>
              <option value="Jehovah Witness">Jehovah Witness</option>
              <option value="Muslim">Muslim</option>
              <option value="Other">Other</option>
              <option value="Unknown">Unknown</option>
            </select>
          </div>
          <div className="funeral-details__input">
            <input type="checkbox" name="church_affil" id="church_affil" />
            <label htmlFor="church_affil">Church Affiliation (if any)</label>
            <input
              className="church_affil"
              name="church_affil"
              type="text"
              defaultValue={formData?.church_affil}
              onChange={(e) =>
                setFormData({ ...formData, church_affil: e.target.value })
              }
            />
          </div>

          <div className="funeral-details__input">
            <label htmlFor="viewinglocation"> Viewing Location</label>
            <input
              className="foraddclient"
              list="viewinglocation"
              name="viewinglocation"
              defaultValue={
                formData?.viewing_location ? formData?.viewing_location : ""
              }
              onChange={(e) => {
                console.log(e.target.value);
                setFormData({ ...formData, viewing_location: e.target.value });
              }}
            />
            <datalist id="viewinglocation">
              {/* <option value="">Select</option> */}
              <option value="FDJ">FDJ</option>
              <option value="FSBC (Clermont)">FSBC (Clermont)</option>
              <option value="Option 4">Option 4</option>
            </datalist>
          </div>
          <div className="funeral-details__input">
            <label htmlFor="viewinginfo"> Viewing Address</label>
            <input
              className="foraddclient"
              type="text"
              name="viewinginfo"
              defaultValue={formData?.viewing_info ?? ""}
              onChange={(e) =>
                setFormData({ ...formData, viewing_info: e.target.value })
              }
            />
          </div>
          <div className="funeral-details__input">
            <label htmlFor="viewingDate">Viewing Date</label>
            <input
              className="foraddclient"
              type="date"
              name="viewingDate"
              defaultValue={
                isValid(new Date(formData?.viewing_date))
                  ? format(new Date(formData?.viewing_date), "yyyy-MM-dd")
                  : ""
              }
              onChange={(e) =>
                setFormData({ ...formData, viewing_date: e.target.value })
              }
            />
          </div>
          <div className="funeral-details__input">
            <label htmlFor="viewingTime">Viewing Time</label>
            <input
              className="foraddclient"
              type="time"
              name="viewingTime"
              defaultValue={
                formData?.viewing_time ? formData?.viewing_time : ""
              }
              onChange={(e) =>
                setFormData({ ...formData, viewing_time: e.target.value })
              }
            />
          </div>
          <div className="funeral-details__input">
            <label htmlFor="viewingEndTime">Viewing End Time</label>
            <input
              className="foraddclient"
              type="time"
              name="viewingENdTime"
              defaultValue={formData?.viewing_endtime ?? ""}
              onChange={(e) =>
                setFormData({ ...formData, viewing_endtime: e.target.value })
              }
            />
          </div>
          <div className="funeral-details__input">
            <input
              type="checkbox"
              name="serviceLocation"
              id="serviceLocation"
            />
            <label htmlFor="serviceLocation">Service Location</label>
            <input
              list="servicelocation"
              name="serviceLocation"
              className="foraddclient"
              value={formData?.service_location}
              onChange={(e) =>
                setFormData({ ...formData, service_location: e.target.value })
              }
            />
            <datalist id="servicelocation">
              {/* <option value="">Select</option> */}
              <option value="FDJ">FDJ</option>
              <option value="FSBC (Clermont)">FSBC (Clermont)</option>
              <option value="Option 4">Option 4</option>
            </datalist>
          </div>
          <div className="funeral-details__input">
            <label htmlFor="serviceinfo"> Service Address</label>
            <input
              className="foraddclient"
              type="text"
              name="serviceinfo"
              defaultValue={formData?.service_info ?? ""}
              onChange={(e) =>
                setFormData({ ...formData, service_info: e.target.value })
              }
            />
          </div>
          <div className="funeral-details__input">
            <label htmlFor="serviceDate">Service Date</label>
            <input
              className="foraddclient"
              type="date"
              name="serviceDate"
              defaultValue={
                isValid(new Date(formData?.service_date))
                  ? format(new Date(formData?.service_date), "yyyy-MM-dd")
                  : ""
              }
              onChange={(e) =>
                setFormData({ ...formData, service_date: e.target.value })
              }
            />
          </div>
          <div className="funeral-details__input">
            <label htmlFor="serviceTime">Service Time</label>
            <input
              className="foraddclient"
              type="time"
              name="serviceTime"
              defaultValue={
                formData?.service_time ? formData?.service_time : ""
              }
              onChange={(e) =>
                setFormData({ ...formData, service_time: e.target.value })
              }
            />
          </div>
          <div className="funeral-details__input">
            <label htmlFor="serviceEndTime">Service End Time</label>
            <input
              className="foraddclient"
              type="time"
              name="serviceEndTime"
              defaultValue={
                formData?.service_endtime ? formData?.service_endtime : ""
              }
              onChange={(e) =>
                setFormData({ ...formData, service_endtime: e.target.value })
              }
            />
          </div>
          <div className="funeral-details__input">
            <input
              type="checkbox"
              name="cemetary_status"
              id="cemetary_status"
            />
            <label htmlFor="cemetary_status">Cemetery Status</label>
            <select
              className="foraddclient"
              value={formData?.cemetary_status}
              onChange={(e) =>
                setFormData({ ...formData, cemetary_status: e.target.value })
              }
            >
              <option value="">Select</option>
              <option value="Family to Choose">Family to Choose</option>
              <option value="Family to Select">Family to Select</option>
              <option value="Family Owns">Family Owns</option>
              <option value="Cemetery Selected">Cemetery Selected</option>
            </select>
          </div>
          <div className="funeral-details__input">
            <input type="checkbox" name="cemetary_name" id="cemetary_name" />
            <label htmlFor="cemetary_name">Cemetery Name</label>
            <input
              list="cemeteryname"
              className="foraddclient"
              value={formData?.cemetary_name}
              onChange={(e) =>
                setFormData({ ...formData, cemetary_name: e.target.value })
              }
            />
            <datalist id="cemeteryname">
              <option value="">Select</option>
              <option value="Canarsie">Canarsie</option>
              <option value="Cypress">Cypress</option>
              <option value="Rosehill/Rosedale">Rosehill/Rosedale</option>
              <option value="Greenfield">Greenfield</option>
              <option value="Pinelawn">Pinelawn</option>
              <option value="Ernst">Ernst</option>
              <option value="St. Charles">St. Charles</option>
            </datalist>
          </div>
          <div className="funeral-details__input">
            <label htmlFor="cemeteryinfo"> Cemetery Address</label>
            <input
              className="foraddclient"
              type="text"
              name="cemeteryinfo"
              defaultValue={formData?.cemetery_info ?? ""}
              onChange={(e) =>
                setFormData({ ...formData, cemetery_info: e.target.value })
              }
            />
          </div>
          <div className="funeral-details__input">
            <label htmlFor="cemetary_booking">Cemetery Booking</label>
            <select
              className="foraddclient"
              name="cemetary_booking"
              value={formData?.cemetary_booking || ""}
              onChange={(e) =>
                setFormData({ ...formData, cemetary_booking: e.target.value })
              }
            >
              <option value="">* Select Cemetery Booking</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="No">Pending</option>
            </select>
          </div>
          <div className="funeral-details__input">
            <label htmlFor="arrival_date">Cemetery Arrival Date</label>
            <input
              className="foraddclient"
              type="date"
              name="arrival_date"
              defaultValue={
                isValid(new Date(formData?.arrival_date))
                  ? format(new Date(formData?.arrival_date), "yyyy-MM-dd")
                  : ""
              }
              onChange={(e) =>
                setFormData({ ...formData, arrival_date: e.target.value })
              }
            />
          </div>
          <div className="funeral-details__input">
            <label htmlFor="arrival_time">Cemetery Arrival Time</label>
            <input
              className="foraddclient"
              type="time"
              name="arrival_time"
              defaultValue={formData?.arrival_time}
              onChange={(e) =>
                setFormData({ ...formData, arrival_time: e.target.value })
              }
            />
          </div>
          <div className="funeral-details__input">
            <label htmlFor="type_of_disposition">Type of Disposition</label>
            <select
              className="foraddclient"
              name="type_of_disposition"
              value={formData?.type_of_disposition || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  type_of_disposition: e.target.value,
                })
              }
            >
              <option value="">* Select Type of Disposition</option>
              <option value="Burial">Burial</option>
              <option value="Cremation">Cremation</option>
              <option value="Entombment">Entombment</option>
            </select>
          </div>

          {donthidebutton && (
            <div className="funeral-details__btn">
              {update ? (
                <button type="button" onClick={onUpdate}>
                  Update
                </button>
              ) : (
                <button type="submit">Submit</button>
              )}

              <button
                type="button"
                onClick={() => {
                  setShowForms(false);
                }}
              >
                Exit Window
              </button>
            </div>
          )}
        </form>
      </div>
    </FuneralDetailsContainer>
  );
};

export default ProfileFuneralDetails;
