import styled from "styled-components";

export const UserSideContainer = styled.ul`
  display: inline-block;
  height: 100%;
  list-style-type: none;
  margin-right: 10px;
  position: relative;

  .toggle {
    position: absolute;
    right: -10px;
    top: 50%;
    font-size: 1.2rem;
    color: #131313;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06);

    z-index: 1;
  }

  .full {
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 0;
  }
  .mini {
    width: 90px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 0;
    border-radius: 8px;
  }
  .Off {
    display: none;
  }
  .sub-menu {
    display: none;
  }

  .side-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 10px 0 0 10px;
    gap: 10px;
    padding: 0 10px;
    height: 60px;
    width: 95%;

    .logo {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #f5f5f5;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
      }
    }
    .logo-title {
      font-size: 1.1rem;
      color: #131313;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .side-nav ::-webkit-scrollbar-track {
    background-color: #f0f2f5; /* Background color of the track */
    border-radius: 8px; /* Rounded corners of the track */
  }
  .side-nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f5f5f5;
    border-radius: 0 10px 10px 0;
    gap: 10px;
    padding: 0 10px;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 10px; /* width of the entire scrollbar */

      border-radius: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #fff; /* color of the tracking area */
    }
    ::-webkit-scrollbar-thumb {
      background-color: grey; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 3px solid #fff; /* creates padding around scroll thumb */
    }
    .active {
      border-radius: 6px;
      background-color: var(--theme-color);
      color: #fff;
      .icons {
        font-size: 1.2rem;
        color: #fff;
        margin: 0 10px;
      }
      .Title {
        font-size: 14px;
        color: #fff;
      }
    }
    li {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 20px;
      cursor: pointer;
      :hover {
        border-radius: 6px;
        background-color: var(--theme-color);
        color: #fff;
        .icons {
          font-size: 1.2rem;
          color: #fff;
          margin: 0 10px;
        }
        .Title {
          font-size: 14px;
          color: #fff;
        }
      }

      min-height: 50px;

      .icons {
        font-size: 1.2rem;
        color: #131313;
        margin: 0 10px;
      }
      .Title {
        font-size: 14px;
        color: #131313;
      }
    }
  }
  .on {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 10px 0 0 10px;
    gap: 10px;
    padding: 0 10px;
    cursor: pointer;
  }
  .bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 10px 0 0 10px;
    gap: 10px;
    padding: 0 10px;
    height: 50px;
    width: 95%;
    cursor: pointer;

    .bottom_img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #f5f5f5;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;

        object-fit: cover;
      }
    }
    .bottom-title {
      font-size: 1rem;
      color: #131313;
    }

    .icons {
      font-size: 1rem;
      color: #131313;
      margin: 0 10px;
    }

    .off {
      display: none;
    }
  }
`;
