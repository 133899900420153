import emailjs from "emailjs-com";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SimpleCrypto from "simple-crypto-js";
import { EmailContainer } from "../../styles/ProfileEmail.styled";
import io from "socket.io-client";
import axios from "axios";
import { toast } from "react-hot-toast";
import templates from "./emailtemplate";
import { format, isValid } from "date-fns";

const socket = io.connect("https://api5.teamfdjfs.com/email");
export const secretKey = "secretkey";
export const simpleCrypto = new SimpleCrypto(secretKey);

//send Email
function sendEmail() {
  let to_email = document.getElementById("emailto");
  if (to_email.value) {
    var templateParams = {
      subject: document.getElementById("emailsubject").value,
      to_email: to_email.value,
      message: document.getElementById("emailmessage").innerHTML,
      cc: document.getElementById("ccto").value.split(","),
    };
    console.log(templateParams);
    document.getElementById("wait-modal").style.display = "flex";
    emailjs
      .send(
        "service_rm06mtg",
        "template_dydduuy",
        templateParams,
        "cjJ7mtwcOJr_6Ojha"
      )
      .then(
        function (response) {
          document.getElementById("wait-modal").style.display = "none";
          alert("Email Sent");
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          document.getElementById("wait-modal").style.display = "none";
          alert("Failed to send email");
          console.log("FAILED...", error);
        }
      );
  } else {
    to_email.focus();
  }
}
export default function Email({ setEmail, clientEmail, deceased, allservice }) {
  //states
  const [message, setMessage] = useState();
  const [toemail, setToEmail] = useState();
  const [subject, setSubject] = useState();

  const [cc, setCc] = useState();
  const [selectedtemplate, setTemplate] = useState();
  const [selectedchecks, setSelectedCheck] = useState();
  const [employees, setEmployees] = useState([]);
  const [which, setWhich] = useState();
  let { clientId } = useParams();

  useEffect(() => {
    console.log(which);
    if (localStorage.getItem("emailchecks")) {
      let data = JSON.parse(localStorage.getItem("emailchecks"));
      console.log(data);
      if (data?.length > 0) {
        data?.forEach((email) => {
          console.log(email);
          if (email.client_id == clientId && email.whatemail === which) {
            let emailchbox = email.emailchecks.split(", ");
            emailchbox.map((element) => {
              document.getElementById(element).checked = true;
            });
            console.log(emailchbox);
            setSelectedCheck(emailchbox);
          } else {
            document.querySelectorAll(".emcheck").forEach((el) => {
              el.checked = false;
              setSelectedCheck();
            });
          }
        });
      }
    }
  }, [localStorage.getItem("emailchecks"), which]);

  useEffect(() => {
    socket.on("setemailchecks", (emails) => {
      toast.success("A new email templates saved ");
      localStorage.setItem("emailchecks", JSON.stringify(emails));
    });

    socket.on("getemailchecks", (data) => {
      if (data.length > 0) {
        data.forEach((email) => {
          if (email.client_id === clientId && email.whatemail === which) {
            let emailchbox = email.emailchecks.split(", ");
            emailchbox.map((element) => {
              document.getElementById(element).checked = true;
            });
            setSelectedCheck(emailchbox);
          }
        });
        localStorage.setItem("emailchecks", JSON.stringify(data));
      }
    });
  }, [socket]);

  useEffect(() => {
    if (selectedtemplate) {
      console.log(selectedtemplate);
      console.log(selectedchecks);
      let toinsertinbody = selectedtemplate.body;
      if (selectedchecks && selectedtemplate.withcheckbox) {
        selectedchecks.forEach((element) => {
          if (selectedtemplate[element]) {
            toinsertinbody += selectedtemplate[element];
          }
          if (element === "clientpage") {
            let url = encodeURIComponent(simpleCrypto.encrypt(clientId));
            toinsertinbody += ` <a href='https://teamfdjfs.com/client/fillup/${url}'>Click Here</a><br><br>`;
          }
        });
      }
      // if (selectedtemplate.client) {
      //   let url = encodeURIComponent(simpleCrypto.encrypt(clientId));
      //   toinsertinbody += `<br> <a href='https://teamfdjfs.com/client/fillup/${url}'>https://teamfdjfs.com/client/fillup/${url}</a>`;
      // }
      const servicelabels = deceased?.client?.servicetype?.split(", ");
      console.log(servicelabels);
      let price = 0;
      let names = "";
      if (allservice && allservice.length > 0) {
        allservice.forEach((element) => {
          if (servicelabels?.includes(element.value)) {
            price += element.price;
            names +=
              element.label.substring(0, element.label.indexOf("-")) + ", ";
          }
        });
      }

      toinsertinbody = toinsertinbody
        .replace(
          /{{inserttotal}}/g,
          "$" +
            Number(price.toFixed(2)).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })
        )
        .replace(/{{decname}}/g, deceased.client.decname)
        .replace(
          /{{service_date}}/g,
          isValid(new Date(deceased.funeraldetails.service_date)) &&
            deceased.funeraldetails.service_date !== null &&
            deceased.funeraldetails.service_date !== undefined
            ? format(
                new Date(deceased?.funeraldetails?.service_date),
                "MMMM dd, yyyy"
              )
            : ""
        )
        .replace(/{{service_time}}/g, deceased?.funeraldetails?.service_time)
        .replace(/{{insertservice}}/g, names)
        .replace(/{{contactname}}/g, deceased?.client?.firstname);

      document.getElementById("emailsubject").value =
        selectedtemplate.subject + deceased.client.decname;
      setMessage(DOMPurify.sanitize(toinsertinbody));
    }
  }, [selectedtemplate, selectedchecks]);

  function onchange() {
    if (selectedtemplate) {
      document.querySelectorAll(".emcheck").forEach((element) => {
        if (element.checked) {
          console.log(element.value);
          console.log(selectedtemplate[element.value]);
        }
      });
    }
  }

  //selectig template
  function selectTemplate(e) {
    let template = templates[e.target.value];
    let body = template.body;
    setTemplate(template);

    if (
      e.target.value === "funeralarrangement" ||
      e.target.value === "prearrangement"
    ) {
      let toinsertinbody = "";
      document.getElementById("checks").style.display = "flex";
    } else {
      document.getElementById("checks").style.display = "none";
      document.querySelectorAll(".emcheck").forEach((element) => {
        element.checked = false;
      });
    }

    document.getElementById("emailsubject").value = template.subject + deceased;
    setMessage(DOMPurify.sanitize(body));
  }

  let emailtemplist = [
    "funeralarrangement",
    "prearrangement",
    "obituaryandyoutube",
    "visarequest",
    "familyvisa",
    "zellepayment",
  ];
  let emailsub = [
    "Funeral Arrangement",
    "Pre-arrangement",
    "Obituary and Youtube",
    "Visa request",
    "Family Visa",
    "Zelle payment",
  ];

  let checks = [
    "greeting",
    "authorization",
    "clientpage",
    "funeralpreference",
    "pricing",
    "bookingdisclaimer",
    "casketlink",
    "urnflipbook",

    "outro",
  ];

  let checkslabels = [
    "Greeting",
    "Authorization Expectations",
    "Client Page Link Generator",
    "Funeral Preference",
    "Pricing",
    "Booking Disclaimer(if necessary)",
    "Casket Link",
    "Urn Flipbook link",
    "Outro and Reminder",
  ];

  return (
    <EmailContainer>
      <div id="wait-modal">
        <div id="loading">
          <img
            id="gif"
            src="https://media.tenor.com/5o2p0tH5LFQAAAAi/hug.gif"
          />
        </div>
        <h5 id="sending">Sending Email...</h5>
      </div>
      <div id="aboutemail">
        <h5>Email Templates</h5>
        <div className="emailtemplatediv">
          {emailtemplist.map((emailtemp, key) => (
            <div key={key} className="radio">
              <input
                type="radio"
                value={emailtemp}
                name="emailformat"
                onChange={(e) => {
                  //selectTemplate(e);

                  if (
                    e.target.value === "funeralarrangement" ||
                    e.target.value === "prearrangement"
                  ) {
                    document.getElementById("checks").style.display = "flex";
                    setWhich(e.target.value);
                  } else {
                    //setSelectedCheck();
                    document.getElementById("checks").style.display = "none";
                    // document.querySelectorAll(".emcheck").forEach((element) => {
                    //   element.checked = false;
                    // });
                  }

                  setTemplate(templates[e.target.value]);
                }}
              ></input>
              <label>{emailsub[key]}</label>
            </div>
          ))}
        </div>
        <div id="checks" className="checks">
          {checks.map((ch, key) => (
            <div className="ch" style={{ display: "flex" }} key={key}>
              <label>
                <input
                  id={ch}
                  onChange={() => {
                    let selected = [];
                    document.querySelectorAll(".emcheck").forEach((element) => {
                      if (element.checked) {
                        selected.push(element.value);
                      }
                      setSelectedCheck(selected);
                    });
                  }}
                  className="emcheck"
                  value={ch}
                  type="checkbox"
                />
                {checkslabels[key]}
              </label>
            </div>
          ))}
          {/* {
            <div className="ch" style={{ display: "flex", width: "200px" }}>
              <label>
                <input
                  onChange={() => {
                    let selected = [];
                    document.querySelectorAll(".emcheck").forEach((element) => {
                      if (element.checked) {
                        selected.push(element.value);
                      }
                      setSelectedCheck(selected);
                    });
                  }}
                  className="emcheck"
                  type="checkbox"
                />
                Service Types Contracts
              </label>
            </div>
          } */}
          {localStorage.getItem("role") === "admin" && (
            <button
              onClick={() => {
                socket.emit("email-checkbox", {
                  data: selectedchecks,
                  clientId: clientId,
                  whatemail: which ?? "",
                });
              }}
            >
              Save
            </button>
          )}
        </div>
        <div id="email">
          <div className="subject">
            <h5>Subject:</h5>
            <input type="text" id="emailsubject"></input>
          </div>
          <div className="to">
            <h5>To:</h5>
            <input id="emailto" type="email" defaultValue={clientEmail} />
          </div>
          <div className="cc">
            <h5>Cc:</h5>
            <input type="email" multiple={true} id="ccto"></input>
          </div>
          <div className="message">
            <h5>Message: </h5>
            <div
              dangerouslySetInnerHTML={{ __html: message }}
              contentEditable="true"
              id="emailmessage"
            ></div>
          </div>
          <div className="btn">
            <button onClick={sendEmail} id="sendemail">
              Send Email
            </button>
            {/* <button
              onClick={() => {
                setEmail(false);
              }}
            >
              Exit Window
            </button> */}
          </div>
        </div>
      </div>
    </EmailContainer>
  );
}
