import { useState, useEffect } from "react";
import { format, isValid } from "date-fns";
import axios from "axios";
import { PrimaryIntakeContainer } from "../../styles/ProfilePrimaryIntake.styled";
const PrimaryIntake = ({
  setShowForms,
  setPrimaryIntake,
  clientId,
  toast,
  donthidebutton,
  setNewClient,
  newClient,
}) => {
  const [update, setUpdate] = useState(false);
  const [caseNumber, setCaseNumber] = useState(
    newClient?.primaryintake?.casenumber ?? ""
  );
  const [dateOfDeath, setDateOfDeath] = useState(
    newClient?.primaryintake?.date_of_death ?? ""
  );
  const [diedAt, setDiedAt] = useState(newClient?.primaryintake?.died_at ?? "");

  const [apartmentNumber, setApartmentNumber] = useState(
    newClient?.primaryintake?.apartment_number ?? ""
  );
  const [deathPlace, setDeathPlace] = useState(
    newClient?.primaryintake?.death_place ?? ""
  );
  const [deathCity, setDeathCity] = useState(
    newClient?.primaryintake?.death_city ?? ""
  );
  const [deathState, setDeathState] = useState(
    newClient?.primaryintake?.death_state ?? ""
  );
  const [deathCountry, setDeathCountry] = useState(
    newClient?.primaryintake?.death_country ?? ""
  );
  const [deathMarital, setDeathMarital] = useState(
    newClient?.primaryintake?.death_marital ?? ""
  );
  const [nok, setNok] = useState(newClient?.primaryintake?.nok ?? "");
  const [beneficiary, setBeneficiary] = useState(
    newClient?.primaryintake?.beneficiary ?? ""
  );
  const [nokaddress, setNokAddress] = useState(
    newClient?.primaryintake?.nok_address ?? ""
  );
  const [intakerelationship, setIntakeRelationship] = useState(
    newClient?.primaryintake?.intake_relationship ?? ""
  );
  const [intaketelephone, setIntakeTelephone] = useState(
    newClient?.primaryintake?.intake_telephone ?? ""
  );
  const [intakeemail, setIntakeEmail] = useState(
    newClient?.primaryintake?.intake_email ?? ""
  );
  const [additionalcontact, setAdditionalContact] = useState(
    newClient?.primaryintake?.additional_contact ?? ""
  );
  const [facialhair, setFacialHair] = useState(
    newClient?.primaryintake?.facial_hair ?? ""
  );

  const formatDate = (date) => {
    if (date) {
      const newDate = new Date(date);
      const year = newDate.getFullYear();
      const month =
        newDate.getMonth() + 1 > 9
          ? newDate.getMonth() + 1
          : `0${newDate.getMonth() + 1}`;
      const day =
        newDate.getDate() > 9 ? newDate.getDate() : `0${newDate.getDate()}`;

      return `${year}-${month}-${day}`;
    }
    return "";
  };
  useEffect(() => {
    const getPrimaryIntake = async () => {
      const caseVerified = document.getElementById("caseNumber");
      const deathPlaceVerified = document.getElementById("deathPlace");
      const deathCityVerified = document.getElementById("deathCity");
      const deathStateVerified = document.getElementById("deathState");
      const deathCountryVerified = document.getElementById("deathCountry");
      // const { data } = await axios.get(
      //   `https://api5.teamfdjfs.com/intake/${clientId}`
      // );
      const data = newClient.primaryintake;

      if (data.length > 0) {
        setUpdate(true);
        const date = formatDate(data.date_of_death);

        const verified1 = data.casenumber
          ?.split(" ")
          ?.find((item) => item === "(TBD)");
        verified1
          ? (caseVerified.checked = false)
          : (caseVerified.checked = true);

        // setCaseNumber(data.casenumber);

        // setDateOfDeath(date);
        const verified2 = data.death_place
          ?.split(" ")
          ?.find((item) => item === "(TBD)");
        verified2
          ? (deathPlaceVerified.checked = false)
          : (deathPlaceVerified.checked = true);
        // setDeathPlace(data.death_place);
        const verified3 = data.death_city
          ?.split(" ")
          ?.find((item) => item === "(TBD)");
        verified3
          ? (deathCityVerified.checked = false)
          : (deathCityVerified.checked = true);
        // setDeathCity(data.death_city);
        const verified4 = data.death_state
          ?.split(" ")
          ?.find((item) => item === "(TBD)");
        verified4
          ? (deathStateVerified.checked = false)
          : (deathStateVerified.checked = true);
        // setDeathState(data.death_state);
        const verified5 = data.death_country
          ?.split(" ")
          ?.find((item) => item === "(TBD)");
        verified5
          ? (deathCountryVerified.checked = false)
          : (deathCountryVerified.checked = true);

        // setDeathCountry(data.death_country);
        // setDeathMarital(data.death_marital);
        // setNok(data.nok);
        // setNokAddress(data.nok_address);
        // setBeneficiary(data.beneficiary);
        // setIntakeRelationship(data.intake_relationship);
        // setIntakeTelephone(data.intake_telephone);
        // setIntakeEmail(data.intake_email);
        // setAdditionalContact(data.additional_contact);
        // setFacialHair(data.facial_hair);
      }
    };
    if (clientId) getPrimaryIntake();
  }, [clientId]);

  useEffect(() => {
    if (setNewClient && clientId) {
      setNewClient((prev) => ({
        ...prev,
        primaryintake: {
          caseNumber,
          dateOfDeath,
          diedAt,
          apartmentNumber,
          deathPlace,
          deathCity,
          deathState,
          deathCountry,
          deathMarital,
          nok,
          nokaddress,
          intakeemail,
          intaketelephone,
          intakerelationship,
          beneficiary,
          additionalcontact,
          facialhair,
        },
      }));
    } else {
      setNewClient((prev) => ({
        ...prev,
        caseNumber,
        dateOfDeath,
        diedAt,
        apartmentNumber,
        deathPlace,
        deathCity,
        deathState,
        deathCountry,
        deathMarital,
        nok,
        nokaddress,
        beneficiary,
        intakerelationship,
        intaketelephone,
        intakeemail,
        additionalcontact,
        facialhair,
      }));
    }
  }, [
    caseNumber,
    dateOfDeath,
    diedAt,
    apartmentNumber,
    deathPlace,
    deathCity,
    deathMarital,
    nok,
    nokaddress,
    beneficiary,
    intakerelationship,
    intaketelephone,
    intakeemail,
    additionalcontact,
    facialhair,
  ]);
  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   const caseVerified = document.getElementById("caseNumber").value;
  //   console.log(caseVerified);
  //   const primaryIntake = {
  //     clientId,
  //     caseNumber,
  //     dateOfDeath,
  //     deathPlace,
  //     deathCity,
  //     deathState,
  //     deathCountry,
  //     deathMarital,
  //   };
  //   axios
  //     .post("https://api5.teamfdjfs.com/intake", primaryIntake)
  //     .then((res) => {
  //       console.log(res);
  //       toast.success("Primary Intake Form Submitted");
  //       setShowForms(false);
  //       setPrimaryIntake(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const onUpdate = (e) => {
  //   e.preventDefault();
  //   const caseVerified = document.getElementById("caseNumber");
  //   const deathPlaceVerified = document.getElementById("deathPlace");
  //   const deathCityVerified = document.getElementById("deathCity");
  //   const deathStateVerified = document.getElementById("deathState");
  //   const deathCountryVerified = document.getElementById("deathCountry");

  //   // if (caseVerified.checked === true && caseNumber !== "") {
  //   //   let temp = caseNumber + " (TBD)";
  //   //   setCaseNumber(temp);
  //   // }
  //   // if (deathPlaceVerified.checked === true && deathPlace !== "") {
  //   //   let temp = deathPlace + " (TBD)";
  //   //   setDeathPlace(temp);
  //   // }
  //   // if (deathCityVerified.checked === true && deathCity !== "") {
  //   //   let temp = deathCity + " (TBD)";
  //   //   setDeathCity(temp);
  //   // }
  //   // if (deathStateVerified.checked === true && deathState !== "") {
  //   //   let temp = deathState + " (TBD)";
  //   //   setDeathState(temp);
  //   // }
  //   // if (deathCountryVerified.checked === true && deathCountry !== "") {
  //   //   let temp = deathCountry + " (TBD)";
  //   //   setDeathCountry(temp);
  //   // }

  //   const primaryIntake = {
  //     clientId: clientId,
  //     caseNumber:
  //       caseVerified.checked === false &&
  //       caseNumber !== "" &&
  //       caseNumber.includes("TBD") === false
  //         ? caseNumber + " (TBD)"
  //         : caseNumber,
  //     dateOfDeath: dateOfDeath,
  //     deathPlace:
  //       deathPlaceVerified.checked === false &&
  //       deathPlace !== "" &&
  //       deathPlace.includes("TBD") === false
  //         ? deathPlace + " (TBD)"
  //         : deathPlace,
  //     deathCity:
  //       deathCityVerified.checked === false &&
  //       deathCity !== "" &&
  //       deathCity.includes("TBD") === false
  //         ? deathCity + " (TBD)"
  //         : deathCity,
  //     deathState:
  //       deathStateVerified.checked === false &&
  //       deathState !== "" &&
  //       deathState.includes("TBD") === false
  //         ? deathState + " (TBD)"
  //         : deathState,
  //     deathCountry:
  //       deathCountryVerified.checked === false &&
  //       deathCountry !== "" &&
  //       deathCountry.includes("TBD") === false
  //         ? deathCountry + " (TBD)"
  //         : deathCountry,
  //     deathMarital: deathMarital,
  //   };

  //   console.log(primaryIntake);
  //   axios
  //     .put(`https://api5.teamfdjfs.com/intake/${clientId}`, primaryIntake)
  //     .then((res) => {
  //       // console.log(res.data);
  //       setCaseNumber(res.data[0]?.casenumber);
  //       setDateOfDeath(formatDate(res.data[0]?.date_of_death));
  //       setDeathPlace(res.data[0]?.death_place);
  //       setDeathCity(res.data[0]?.death_city);
  //       setDeathState(res.data[0]?.death_state);
  //       setDeathCountry(res.data[0]?.death_country);
  //       setDeathMarital(res.data[0]?.death_marital);

  //       toast.success("Primary Intake Form Updated");
  //       setShowForms(false);
  //       // setPrimaryIntake(false);
  //     });
  // };

  return (
    <PrimaryIntakeContainer>
      <div className="intake__title">
        <h2>Primary Intake</h2>
      </div>
      <form>
        <div className="intake__input">
          <input type="checkbox" name="caseNumber" id="caseNumber" />
          <label htmlFor="caseNumber">Case Number</label>
          <input
            className="foraddclient"
            type="text"
            value={caseNumber ? caseNumber : ""}
            onChange={(e) => setCaseNumber(e.target.value)}
          />
        </div>
        {/* <div className="intake__input">
          <input type="checkbox" name="beneficiary" id="beneficiary" />
          <label htmlFor="beneficiary">Name of decedent/beneficiary</label>
          <input
            className="foraddclient"
            type="text"
            value={beneficiary ? beneficiary : ""}
            onChange={(e) => setBeneficiary(e.target.value)}
          />
        </div> */}

        <div className="intake__input">
          <label htmlFor="dateOfDeath">Date of Death</label>
          <input
            className="foraddclient"
            type="date"
            defaultValue={
              isValid(new Date(dateOfDeath))
                ? format(new Date(dateOfDeath), "yyyy-MM-dd")
                : ""
            }
            onChange={(e) => setDateOfDeath(e.target.value)}
          />
        </div>
        <span className="death_place_title">
          <h3>Place of Death</h3>
        </span>
        <div className="intake__input">
          <input type="checkbox" name="diedAt" id="diedAt" />
          <label htmlFor="diedAt">Died At</label>
          <input
            className="foraddclient"
            type="text"
            defaultValue={diedAt ? diedAt : ""}
            onChange={(e) => setDiedAt(e.target.value)}
          />
        </div>
        <div className="intake__input">
          <input type="checkbox" name="apartmentNumber" id="apartmentNumber" />
          <label htmlFor="apartmentNumber">Floor/Apartment Number</label>
          <input
            className="foraddclient"
            type="text"
            value={apartmentNumber ? apartmentNumber : ""}
            onChange={(e) => setApartmentNumber(e.target.value)}
          />
        </div>

        <div className="intake__input">
          <input type="checkbox" name="deathCity" id="deathCity" />
          <label htmlFor="deathCity">City</label>
          <input
            className="foraddclient"
            type="text"
            defaultValue={deathCity ? deathCity : ""}
            onChange={(e) => setDeathCity(e.target.value)}
          />
        </div>
        <div className="intake__input">
          <input type="checkbox" name="deathState" id="deathState" />
          <label htmlFor="deathState">State</label>
          <input
            className="foraddclient"
            type="text"
            value={deathState ? deathState : ""}
            onChange={(e) => setDeathState(e.target.value)}
          />
        </div>
        <div className="intake__input">
          <input type="checkbox" name="deathPlace" id="deathPlace" />
          <label htmlFor="deathPlace">Zip</label>
          <input
            className="foraddclient"
            type="text"
            value={deathPlace ? deathPlace : ""}
            onChange={(e) => setDeathPlace(e.target.value)}
          />
        </div>
        <div className="intake__input">
          <input type="checkbox" name="deathCountry" id="deathCountry" />
          <label htmlFor="deathCountry">Country</label>
          <input
            className="foraddclient"
            type="text"
            value={deathCountry ? deathCountry : ""}
            onChange={(e) => setDeathCountry(e.target.value)}
          />
        </div>
        <div className="intake__input">
          <label htmlFor="deathMarital">Marital Status</label>
          <select
            className="foraddclient"
            defaultValue={deathMarital ? deathMarital : ""}
            onChange={(e) => setDeathMarital(e.target.value)}
          >
            <option vaue="">Select</option>
            <option value="Single">Single (Never Married) </option>
            <option value="Married">Married</option>
            <option value="Married but separated">Married but separated</option>
            <option value="Divorced">Divorced</option>
            <option value="Widowed">Widowed</option>
          </select>
        </div>
        <div className="intake__input">
          <input type="checkbox" name="nokAuthorizer" id="nokAuthorizer" />
          <label htmlFor="nokAuthorizer">Next of Kin/Authorizer</label>
          <input
            className="foraddclient"
            type="text"
            defaultValue={nok ? nok : ""}
            onChange={(e) => setNok(e.target.value)}
          />
        </div>
        <div className="intake__input">
          <label htmlFor="Relationship">Relationship</label>
          <select
            className="foraddclient "
            defaultValue={intakerelationship ? intakerelationship : ""}
            onChange={(e) => setIntakeRelationship(e.target.value)}
          >
            <option value="">Select</option>
            <option value="Self">Self</option>
            <option value="Authorized Guardian">Authorized Guardian</option>
            <option value="Husband">Husband</option>
            <option value="Wife">Wife</option>
            <option value="Son">Son</option>
            <option value="Daughter">Daughter</option>
            <option value="Brother">Brother</option>
            <option value="Sister">Sister</option>
            <option value="Father">Father</option>
            <option value="Mother">Mother</option>
            <option value="Niece">Niece</option>
            <option value="Nephew">Nephew</option>
            <option value="Cousin">Cousin</option>
            <option value="Friend">Friend</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="intake__input">
          <input
            type="checkbox"
            name="nokAuthorizerAddress"
            id="nokAuthorizerAddress"
          />
          <label htmlFor="nokAuthorizerAddress">
            Next of Kin/Authorizer Address
          </label>
          <input
            className="foraddclient"
            type="text"
            defaultValue={nokaddress ? nokaddress : ""}
            onChange={(e) => setNokAddress(e.target.value)}
          />
        </div>
        <div className="intake__input">
          <input type="checkbox" name="intakeemail" id="intakeemail" />
          <label htmlFor="intakeemail">Email</label>
          <input
            className="foraddclient"
            type="text"
            defaultValue={intakeemail ? intakeemail : ""}
            onChange={(e) => setIntakeEmail(e.target.value)}
          />
        </div>
        <div className="intake__input">
          <input type="checkbox" name="intaketelephone" id="intaketelephone" />
          <label htmlFor="intaketelephone">Telephone</label>
          <input
            className="foraddclient"
            type="text"
            defaultValue={intaketelephone ? intaketelephone : ""}
            onChange={(e) => setIntakeTelephone(e.target.value)}
          />
        </div>
        <div className="intake__input">
          <input
            type="checkbox"
            name="additionalcontact"
            id="additionalcontact"
          />
          <label htmlFor="additionalcontact" className="long">
            Additional contact (names of chiildren etc.)
          </label>
          <input
            className="foraddclient"
            type="text"
            defaultValue={additionalcontact ? additionalcontact : ""}
            onChange={(e) => setAdditionalContact(e.target.value)}
          />
        </div>
        <div className="intake__input ">
          <input type="checkbox" name="facialhair" id="facialhair" />
          <label htmlFor="facialhair" className="long">
            Facial hair/ Hair intructions
          </label>
          <input
            className="foraddclient"
            type="text"
            defaultValue={facialhair ? facialhair : ""}
            onChange={(e) => setFacialHair(e.target.value)}
          />
        </div>
        {donthidebutton && (
          <div className="intake__btn">
            {update ? (
              <button type="button">Update</button>
            ) : (
              <button type="submit">Submit</button>
            )}

            <button
              type="button"
              onClick={() => {
                setShowForms(false);
              }}
            >
              Exit Window
            </button>
          </div>
        )}
      </form>
    </PrimaryIntakeContainer>
  );
};

export default PrimaryIntake;
