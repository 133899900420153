import styled from "styled-components";
export const ContractStyle = styled.div`
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;

  * {
    box-sizing: border-box;
    padding: 0;
    outline: none;
    margin: 0;
  }

  .input {
    width: 80px;
  }

  .container {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    flex-direction: column;
    background-color: #fff;
    padding: 10px 30px;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    font-size: 13px;
    color: red;
  }
  .content {
    display: flex;
    gap: 6px;
    justify-content: start;
  }
  .left {
    flex: 1;
    text-align: center;
    font-size: 13px;
    margin-bottom: 20px;
  }
  .name {
    width: 100%;
  }
  .namee {
    float: left;
  }
  .date {
    float: right;
  }
  .nameee {
    float: left;
    margin-top: 20px;
  }
  .dateee {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .one {
    margin-top: 10px;
    float: left;
  }
  .text {
    text-align: left;
  }
  .textt {
    margin-top: 10px;
  }
  .price {
    display: flex;
    align-items: center;
  }
  .esign {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  .esign .datee {
    margin-left: 10px;
  }
  .esign .datee input {
    width: 80px;
  }
  .box {
    padding: 10px;
    margin: 10px 0px;
    border: 1px solid black;
  }
  .esig {
    margin-top: 10px;
  }
  .esig input {
    width: 60%;
  }

  .right {
    flex: 1;
    height: 100%;

    font-size: 13px;
  }
  @media only screen and (max-width: 768px) {
    * {
      font-size: x-small;
    }
    .container {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      flex-direction: column;
      background-color: #fff;
      padding: 10px 5px;
    }
    .content {
      display: flex;
      flex-direction: column;
    }
  }
`;
