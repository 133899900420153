import { useEffect, useState } from "react";
import { CgCloseO } from "react-icons/cg";
import { ProfilePageContainer } from "../styles/ProfilePage.styled";
import { useParams } from "react-router-dom";
import axios from "axios";
import ProfileBanner from "../components/ProfilePage/ProfileBanner";
import PrimaryIntake from "../components/ProfilePage/PrimaryIntake";
import ProfileNav from "../components/ProfilePage/ProfileNav";
import DailyTracker from "components/UserPage/DailyTracker";

import ProfileFuneralDetails from "../components/ProfilePage/ProfileFuneralDetails";
import Email from "../components/Email/EmailFormat";
import Forms from "../components/Forms/Forms";
import Task from "../components/Tasks/Task";
import toast, { Toaster } from "react-hot-toast";
import Billing from "../components/Billing/Billing";
import UpdateClient from "../components/ProfilePage/UpdateClient";
import Notes from "../components/HomePage/Notes";

import LifeInsurance from "components/ProfilePage/LifeInsurance";
import MemorialCard from "components/ProfilePage/MemorialCard";
import MemorialInfo from "components/ProfilePage/MemorialInfo";
import CasketMerch from "components/ProfilePage/CasketMerch";
import FlowerInstructions from "components/ProfilePage/FlowerInstructions";
import ToDoList from "components/ProfilePage/ToDoList";

const ProfilePage = () => {
  const { clientId, totaskid } = useParams();
  const [client, setClient] = useState();
  const [showForms, setShowForms] = useState(false);
  const [clientDetails, setClientDetails] = useState(true);
  const [primaryIntake, setPrimaryIntake] = useState(false);
  const [funeralDetails, setFuneralDetails] = useState(false);
  const [dailyTracker, setDailyTracker] = useState(false);

  const [showForms2, setShowForms2] = useState(false);
  const [lifeInsurance, setLifeInsurance] = useState(false);
  const [memorialCard, setMemorialCard] = useState(false);
  const [memorialInfo, setMemorialInfo] = useState(false);
  const [casketMerch, setCasketMerch] = useState(false);
  const [flowerInstructions, setFlowerInstructions] = useState(false);
  const [toDoList, setToDoList] = useState(false);

  const [email, setEmail] = useState(true);
  const [task, setTask] = useState(false);
  const [forms, setForms] = useState(false);
  const [billing, setBilling] = useState(false);
  const [serviceoptions, setServiceOptions] = useState();

  const [newClient, setNewClient] = useState();
  const [deceased, setDeceased] = useState();

  const getClient = async () => {
    const detailspromise = new Promise(async (resolve, reject) => {
      await axios
        .get(`https://api5.teamfdjfs.com/client/${clientId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
    const intakepromise = new Promise(async (resolve, reject) => {
      axios
        .get(`https://api5.teamfdjfs.com/intake/${clientId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
    const funeralpromise = new Promise(async (resolve, reject) => {
      axios
        .get(`https://api5.teamfdjfs.com/funeral/${clientId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
    Promise.all([detailspromise, intakepromise, funeralpromise]).then((res) => {
      console.log(res);
      setNewClient((prev) => ({
        client: res[0],
        primaryintake: res[1][0],
        funeraldetails: res[2][0],
      }));
      setDeceased({
        client: res[0],
        primaryintake: res[1][0],
        funeraldetails: res[2][0],
      });
    });

    const { data } = await axios.get(
      `https://api5.teamfdjfs.com/client/${clientId}`
    );
    setClient(data);
  };

  const getprice = async () => {
    await axios
      .get("https://api5.teamfdjfs.com/prices/getPrices")
      .then((res) => {
        // let obj = [];
        // res.data.map((serv) => {
        //   let perserv = {};
        //   perserv.label = `${serv.service + "---" + "$" + serv.price}`;
        //   perserv.value = serv.service;
        //   perserv.price = serv.price;
        //   obj.push(perserv);
        // });
        // setServiceOptions(obj);
        console.log(res);
        let obj = [];
        res.data.map((serv) => {
          let perserv = {};
          perserv.label = `${
            serv.name + "---" + "$" + serv.TOTALFUNERALCHARGES.toFixed(2)
          }`;
          perserv.value = serv.service;
          perserv.price = serv.TOTALFUNERALCHARGES;
          obj.push(perserv);
        });
        console.log(obj);
        setServiceOptions(obj);
      });
  };

  useEffect(() => {
    if (newClient) console.log(newClient);
  }, [newClient]);

  useEffect(() => {
    if (Number(totaskid)) {
      setEmail(false);
      setTask(true);
    }
    getClient();
    getprice();
  }, []);

  const updateClient = async () => {
    const { data } = await axios.put(
      `https://api5.teamfdjfs.com/updatedetails/updateClient/${clientId}`,
      newClient
    );

    if (data?.status === 200) {
      console.log(data);
      toast.success("Client Updated");
      getClient();
      getprice();
      setShowForms(false);
      setClientDetails(false);
      setPrimaryIntake(false);
      setFuneralDetails(false);
    } else {
      console.log(data);
      toast.error("Error Updating Client");
    }
  };

  return (
    <ProfilePageContainer>
      <div className="wrapper">
        <ProfileBanner
          client={client}
          setShowForms2={setShowForms2}
          setLifeInsurance={setLifeInsurance}
          setMemorialCard={setMemorialCard}
          setMemorialInfo={setMemorialInfo}
          setCasketMerch={setCasketMerch}
          setFlowerInstructions={setFlowerInstructions}
          setToDoList={setToDoList}
        />
        <div className="main-content">
          <ProfileNav
            setShowForms={setShowForms}
            setClientDetails={setClientDetails}
            setEmail={setEmail}
            email={email}
            setTask={setTask}
            task={task}
            setForms={setForms}
            forms={forms}
            setBilling={setBilling}
            billing={billing}
            dailyTracker={dailyTracker}
            setDailyTracker={setDailyTracker}
          />
          <div className="content">
            {email && (
              <Email
                setEmail={setEmail}
                clientEmail={client?.email}
                deceased={deceased ? deceased : null}
                allservice={serviceoptions ? serviceoptions : null}
              />
            )}
            {dailyTracker && <DailyTracker />}
            {forms && <Forms setForms={setForms} />}
            {task && (
              <Task
                totaskid={Number(totaskid) ? Number(totaskid) : null}
                clientId={clientId}
              />
            )}
            {billing && <Billing client={newClient} />}
          </div>
        </div>
      </div>
      {showForms && (
        <div className="bottom">
          <div className="form-wrapper">
            <div className="switch">
              <CgCloseO
                size={30}
                className="closee"
                type="button"
                onClick={() => {
                  setShowForms(false);
                  setPrimaryIntake(false);
                  setFuneralDetails(false);
                }}
              />
              <div
                className={clientDetails ? "item active" : "item"}
                onClick={(e) => {
                  if (e.target?.classList.contains("active")) return;

                  setClientDetails(true);
                  setPrimaryIntake(false);
                  setFuneralDetails(false);

                  e.target?.classList.add("active");
                  document
                    .querySelector(".switch .item:nth-child(2)")
                    ?.classList.remove("active");
                  document
                    .querySelector(".switch .item:nth-child(3)")
                    ?.classList.remove("active");
                }}
              >
                Client Information
              </div>
              <div
                className={primaryIntake ? "item active" : "item"}
                onClick={(e) => {
                  if (e.target?.classList.contains("active")) return;
                  e.target?.classList.add("active");

                  setClientDetails(false);
                  setPrimaryIntake(true);
                  setFuneralDetails(false);
                  document
                    .querySelector(".switch .item:nth-child(1)")
                    ?.classList.remove("active");
                  document
                    .querySelector(".switch .item:nth-child(3)")
                    ?.classList.remove("active");
                }}
              >
                Primary Intake
              </div>
              <div
                className={funeralDetails ? "item active" : "item"}
                onClick={(e) => {
                  if (e.target?.classList.contains("active")) return;
                  e.target?.classList?.add("active");

                  setClientDetails(false);
                  setPrimaryIntake(false);
                  setFuneralDetails(true);
                  document
                    .querySelector(".switch .item:nth-child(1)")
                    ?.classList?.remove("active");
                  document
                    .querySelector(".switch .item:nth-child(2)")
                    ?.classList?.remove("active");
                }}
              >
                Service Details
              </div>
            </div>
            <div className="forms">
              <div style={{ display: clientDetails ? "flex" : "none" }}>
                <UpdateClient
                  // clientId={clientId}
                  setClient={setClient}
                  client={client ? client : null}
                  setShowForms={setShowForms}
                  setPrimaryIntake={setPrimaryIntake}
                  serviceoption={serviceoptions}
                  toast={toast}
                  //donthidebutton={true}
                  setNewClient={setNewClient}
                />
              </div>
              <div style={{ display: primaryIntake ? "flex" : "none" }}>
                <PrimaryIntake
                  clientId={clientId}
                  setShowForms={setShowForms}
                  setPrimaryIntake={setPrimaryIntake}
                  toast={toast}
                  //donthidebutton={true}
                  newClient={newClient ? newClient : null}
                  setNewClient={setNewClient}
                />
              </div>
              <div style={{ display: funeralDetails ? "flex" : "none" }}>
                <ProfileFuneralDetails
                  clientId={clientId}
                  setShowForms={setShowForms}
                  setFuneralDetails={setFuneralDetails}
                  toast={toast}
                  //donthidebutton={true}
                  newClient={newClient ? newClient : null}
                  setNewClient={setNewClient}
                />
              </div>
              <div style={{}}>
                <button className="upd" onClick={updateClient}>
                  Update Details
                </button>
                <button
                  className="upd"
                  onClick={() => {
                    setShowForms(false);
                    setPrimaryIntake(false);
                    setFuneralDetails(false);
                  }}
                >
                  Exit Window
                </button>
              </div>
            </div>
          </div>
          <div>
            <Notes notes={client?.notes} setNotes={setNewClient} />
          </div>
        </div>
      )}
      {showForms2 && (
        <div className="bottom">
          <div className="form-wrapper">
            <div className="forms">
              {lifeInsurance && (
                <div
                  className="divv"
                  style={{ display: lifeInsurance ? "flex" : "none" }}
                >
                  <LifeInsurance
                    setShowForms2={setShowForms2}
                    setLifeInsurance={setLifeInsurance}
                  />
                  <Notes className={"insurance"} />
                </div>
              )}

              {memorialCard && (
                <div
                  className="divv"
                  style={{ display: memorialCard ? "flex" : "none" }}
                >
                  <MemorialCard
                    setShowForms2={setShowForms2}
                    setMemorialCard={setMemorialCard}
                  />
                  <Notes className={"memorialcard"} />
                </div>
              )}

              {memorialInfo && (
                <div
                  className="divv"
                  style={{ display: memorialInfo ? "flex" : "none" }}
                >
                  <MemorialInfo
                    setShowForms2={setShowForms2}
                    setMemorialInfo={setMemorialInfo}
                  />
                  <Notes className={"memorialinfo"} />
                </div>
              )}
              {casketMerch && (
                <div
                  className="divv"
                  style={{ display: casketMerch ? "flex" : "none" }}
                >
                  <CasketMerch
                    setShowForms2={setShowForms2}
                    setCasketMerch={setCasketMerch}
                  />
                  <Notes className={"casketmerch"} />
                </div>
              )}
              {flowerInstructions && (
                <div
                  className="divv"
                  style={{ display: flowerInstructions ? "flex" : "none" }}
                >
                  <FlowerInstructions
                    setShowForms2={setShowForms2}
                    setFlowerInstructions={setFlowerInstructions}
                  />
                  <Notes className={"flowerinstruction"} />
                </div>
              )}
              {toDoList && (
                <div
                  className="divv"
                  style={{ display: toDoList ? "flex" : "none" }}
                >
                  <ToDoList
                    setShowForms2={setShowForms2}
                    setToDoList={setToDoList}
                  />
                  <Notes className={"todolist"} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Toaster />
    </ProfilePageContainer>
  );
};

export default ProfilePage;
