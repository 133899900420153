import styled from "styled-components";

export const Newbanner = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  margin-bottom: 10px;

  .banner {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .message {
    padding: 0px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;

    .urgent {
      background-color: var(--theme-color);
      color: #fff;
      margin: 0px 15px;
      padding: 8px 6px;
      border-radius: 4px;
      cursor: pointer;
    }
    div {
      margin: 0px 15px;
      padding: 8px 6px;
      background-color: #a13333;
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
      .num {
        font-size: 16px;
        font-weight: 600;
      }
      span {
        margin: 0px 4px;
      }
    }
  }
  .buttons {
    padding: 0px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: 120px;
      height: 40px;
      margin: 0px 6px;
      border: none;
      border-radius: 5px;
      background-color: var(--theme-color);
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #fff;
        color: var(--theme-color);
        border: 1px solid var(--theme-color);
      }
    }
  }
`;
