import styled from "styled-components";

export const RightBarContainer = styled.div`
  padding: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: scroll;

  .recent__header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-size: 16px;
    color: #000;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .recent__body {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    .recent__body__item {
      width: 100%;
      flex-direction: column;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      padding: 10px 10px;
      position: relative;
      background: #fff;
      border-radius: 5px;
      margin: 10px 0;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
      font-size: 15px;
      background-color: #ff6961;
      transition: 1 ease;
      :hover {
        transform: translateY(-5px);
        box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
          rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
          rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
      }
      .asd {
        display: flex;

        font-size: 13px;
      }

      .notes {
        width: 100%;
        height: 100%;
        padding: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 13px;
        //color: rgba(6, 24, 44, 0.4);
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 1.5;
      }

      .date {
        padding: 0 10px;
        font-size: 13px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #000;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 1.5;
        color: #000;
      }

      .notesby {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 10px;
        font-size: 13px;
        width: 220px;
        overflow: hidden;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 1.5;
        color: #000;
      }
    }

    .blocked {
      background: #ff6961;
    }
    .pending {
      background: #fcf5c7;
    }
    .completed {
      background: #bffcc6;
    }
    .inprogress {
      background: #a0ced9;
    }
  }
`;
