import styled from "styled-components";

export const EditContractStyle = styled.div`
  padding: 20px;
  .contt {
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
  }
  .contracttt {
    height: 90vh;
    overflow-y: scroll;

    button {
      width: 80px;
      height: 30px;
      border: none;
      border-radius: 5px;
      background-color: var(--theme-color);
      color: #fff;
      font-size: 13px;
      font-weight: 600;

      margin: 10px 10px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #fff;
        color: var(--theme-color);
        border: 1px solid var(--theme-color);
      }
    }
  }
  details {
    width: 200px;
    font-size: 13px;
    padding: 10px;
    height: 90vh;
    background-color: #fff;

    overflow-y: scroll;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    .option {
      background-color: red;
    }
    .label {
      background-color: transparent;
      margin: 10px 5px;
      border: 2px solid #ccc;
      padding: 5px;
      border-radius: 5px;
    }
    .label:hover {
      border-color: var(--theme-color);
    }
  }
`;
