import styled from "styled-components";

export const UserPageSection = styled.section`
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .wrapper {
    width: 98vw;
    height: 98vh;
    background-color: #fff;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .main-content {
      width: 100%;
      height: 100%;
    }
  }
`;
