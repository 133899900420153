import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoExit } from "react-icons/io5";
import { BsFillChatRightDotsFill } from "react-icons/bs";
const MainHeader = ({ setMessageShow }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  useEffect(() => {
    axios
      .get(`https://api5.teamfdjfs.com/auth/`)
      .then((res) => {
        res.data?.find((i) => {
          if (i.id == window.localStorage.getItem("id")) {
            setUser(i);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="mainContainer__right__header__content">
      <span className="dashboard">Dashboard</span>
      <span className="welcome">
        Welcome back,{" "}
        <span className="name">
          {user?.firstname} {user?.lastname}
        </span>
        <span
          className="message"
          onMouseEnter={() => {
            if (
              document.getElementById("rightside").classList.contains("show")
            ) {
              document.getElementById("rightside").classList.toggle("show");
            }
            setMessageShow(true);
            document.getElementById("messageside").classList.toggle("show");
          }}
        >
          <BsFillChatRightDotsFill id="icon" />
        </span>
        <span
          className="profile"
          onMouseEnter={() => {
            if (
              document.getElementById("messageside").classList.contains("show")
            ) {
              document.getElementById("messageside").classList.toggle("show");
            }
            document.getElementById("rightside").classList.toggle("show");
          }}
          // onClick={() => {
          //   navigate(`/user/${user?.id}`);
          //   // window.location.href = `/user/${window.localStorage.getItem("id")}`;
          // }}
        >
          <IoExit id="icon" />
        </span>
      </span>
    </div>
  );
};

export default MainHeader;
