import { useState, useEffect } from "react";
import axios from "axios";
import { CurrentCaseContainer } from "../../styles/CurrentCase.styled";
import { useNavigate } from "react-router-dom";
const CurrentCase = () => {
  const [cases, setCases] = useState();
  const navigate = useNavigate();

  const handleChecked = (e) => {
    console.log(e.target.checked);

    if (e.target.checked) {
      const parent = e.target.parentElement;

      parent.classList.add("completed");
    } else {
      const parent = e.target.parentElement;

      parent.classList.remove("completed");
    }
  };
  const groupData = (data) => {
    const today = new Date();
    const startofWeek = new Date();
    startofWeek.setDate(today.getDate() - today.getDay());
    const endofWeek = new Date();
    endofWeek.setDate(today.getDate() + (6 - today.getDay()));
    const startofNextWeek = new Date();
    startofNextWeek.setDate(today.getDate() + (7 - today.getDay()));
    const endofNextWeek = new Date();
    endofNextWeek.setDate(today.getDate() + (13 - today.getDay()));
    const startofUpcoming = new Date();
    startofUpcoming.setDate(today.getDate() + (14 - today.getDay()));
    const endofUpcoming = new Date();
    endofUpcoming.setDate(today.getDate() + (20 - today.getDay()));
    const startofPending = new Date();
    startofPending.setDate(today.getDate() + (21 - today.getDay()));

    let groupedData = data?.reduce((result, order) => {
      const clientName = order.client_id;
      const dueDate = formatDate(order?.due_date);
      if (!result["overdue"]) {
        result["overdue"] = [];
      }
      if (!result["today"]) {
        result["today"] = [];
      }
      if (!result["thisweek"]) {
        result["thisweek"] = [];
      }
      if (!result["nextweek"]) {
        result["nextweek"] = [];
      }
      if (!result["upcoming"]) {
        result["upcoming"] = [];
      }
      if (!result["pending"]) {
        result["pending"] = [];
      }

      if (!result["today"][clientName]) {
        result["today"][clientName] = [];
      }
      if (!result["thisweek"][clientName]) {
        result["thisweek"][clientName] = [];
      }
      if (!result["nextweek"][clientName]) {
        result["nextweek"][clientName] = [];
      }
      if (!result["upcoming"][clientName]) {
        result["upcoming"][clientName] = [];
      }
      if (!result["pending"][clientName]) {
        result["pending"][clientName] = [];
      }

      if (dueDate === formatDate(today)) {
        result["today"][clientName].push(order);
      } else if (
        dueDate >= formatDate(startofWeek) &&
        dueDate <= formatDate(endofWeek) &&
        dueDate >= formatDate(today)
      ) {
        result["thisweek"][clientName].push(order);
      } else if (
        dueDate >= formatDate(startofNextWeek) &&
        dueDate <= formatDate(endofNextWeek)
      ) {
        result["nextweek"][clientName].push(order);
      } else if (
        dueDate >= formatDate(startofUpcoming) &&
        dueDate <= formatDate(endofUpcoming)
      ) {
        result["upcoming"][clientName].push(order);
      } else if (dueDate >= formatDate(startofPending) && dueDate !== null) {
        result["pending"][clientName].push(order);
      } else {
        result["overdue"].push(order);
      }

      return result;
    }, {});

    return groupedData;
  };

  useEffect(() => {
    axios
      .get("https://api5.teamfdjfs.com/task")
      .then((res) => {
        console.log(res.data);

        setCases(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const formatDate = (date) => {
    let d = new Date(date);
    let month =
      d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
    let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    let year = d.getFullYear();

    return `${year}-${month}-${day}`;
  };

  return (
    <CurrentCaseContainer>
      <h2>Current Case</h2>
      <div className="tablee">
        <table>
          <thead>
            <tr>
              <th>Today</th>
              <th>This Week</th>
              <th>Next Week</th>
              <th>Upcoming</th>
              <th>Pending</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {/* Today */}
                <div className="cases">
                  {cases &&
                    groupData(cases)
                      ?.today?.filter((item) => item !== [] && item.length > 0)
                      .map((item) => {
                        console.log(item);
                        return (
                          <div key={item[0].decname}>
                            <b>{item[0].decname}</b>
                            {item.map((i) => {
                              console.log(i);
                              return (
                                <div
                                  className={
                                    i?.status === "completed"
                                      ? "case completed"
                                      : "case"
                                  }
                                  key={i?.id}
                                  id={i?.id}
                                  onMouseEnter={() => {
                                    document.getElementById(
                                      i?.id
                                    ).style.outline =
                                      "2px solid var(--theme-color)";
                                  }}
                                  onMouseLeave={() => {
                                    document.getElementById(
                                      i?.id
                                    ).style.outline = "none";
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    className="case__checkbox"
                                    name={"case" + i?.id}
                                    onChange={handleChecked}
                                    defaultChecked={i?.status === "completed"}
                                  />

                                  <span
                                    className="case__description"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      navigate(
                                        `/profile/redirect/${i?.task_id}/${i?.client_id}`
                                      );
                                    }}
                                  >
                                    {i?.task}
                                    <div>{formatDate(i?.due_date)}</div>
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                </div>
              </td>
              {/* This Week */}
              <td>
                <div className="cases">
                  {cases &&
                    groupData(cases)
                      ?.thisweek?.filter(
                        (item) => item !== [] && item.length > 0
                      )
                      .map((item) => {
                        return (
                          <div key={item[0].decname}>
                            <b>{item[0].decname}</b>
                            {item
                              .sort((a, b) => {
                                return (
                                  new Date(a.due_date) - new Date(b.due_date)
                                );
                              })
                              .map((i) => {
                                return (
                                  <div
                                    className={
                                      i?.status === "completed"
                                        ? "case completed"
                                        : "case"
                                    }
                                    key={i?.id}
                                    id={i?.id}
                                    onMouseEnter={() => {
                                      document.getElementById(
                                        i?.id
                                      ).style.outline =
                                        "2px solid var(--theme-color)";
                                    }}
                                    onMouseLeave={() => {
                                      document.getElementById(
                                        i?.id
                                      ).style.outline = "none";
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      className="case__checkbox"
                                      name={"case" + i?.id}
                                      onChange={handleChecked}
                                      defaultChecked={i?.status === "completed"}
                                    />

                                    <span
                                      className="case__description"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        navigate(
                                          `/profile/redirect/${i?.task_id}/${i?.client_id}`
                                        );
                                      }}
                                    >
                                      {i?.task}
                                      <div>{formatDate(i?.due_date)}</div>
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                </div>
              </td>
              {/* Next Week */}
              <td>
                <div className="cases">
                  {cases &&
                    groupData(cases)
                      ?.nextweek?.filter(
                        (item) => item !== [] && item.length > 0
                      )
                      .map((item) => {
                        return (
                          <div key={item[0].decname}>
                            <b>{item[0].decname}</b>
                            {item
                              .sort((a, b) => {
                                return (
                                  new Date(a.due_date) - new Date(b.due_date)
                                );
                              })
                              .map((i) => {
                                return (
                                  <div
                                    className={
                                      i?.status === "completed"
                                        ? "case completed"
                                        : "case"
                                    }
                                    key={i?.id}
                                    id={i?.id}
                                    onMouseEnter={() => {
                                      document.getElementById(
                                        i?.id
                                      ).style.outline =
                                        "2px solid var(--theme-color)";
                                    }}
                                    onMouseLeave={() => {
                                      document.getElementById(
                                        i?.id
                                      ).style.outline = "none";
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      className="case__checkbox"
                                      name={"case" + i?.id}
                                      onChange={handleChecked}
                                      defaultChecked={i?.status === "completed"}
                                    />

                                    <span
                                      className="case__description"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        navigate(
                                          `/profile/redirect/${i?.task_id}/${i?.client_id}`
                                        );
                                        // window.location.href = `/profile/redirect/${item?.task_id}/${item?.client_id}`;
                                      }}
                                    >
                                      {i?.task}
                                      <div>{formatDate(i?.due_date)}</div>
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                </div>
              </td>
              {/* Upcoming */}
              <td>
                <div className="cases">
                  {cases &&
                    groupData(cases)
                      ?.upcoming?.filter(
                        (item) => item !== [] && item.length > 0
                      )
                      .map((item) => {
                        return (
                          <div key={item[0].decname}>
                            <b>{item[0].decname}</b>
                            {item
                              .sort((a, b) => {
                                return (
                                  new Date(a.due_date) - new Date(b.due_date)
                                );
                              })
                              .map((i) => {
                                return (
                                  <div
                                    className={
                                      i?.status === "completed"
                                        ? "case completed"
                                        : "case"
                                    }
                                    key={i?.id}
                                    id={i?.id}
                                    onMouseEnter={() => {
                                      document.getElementById(
                                        i?.id
                                      ).style.outline =
                                        "2px solid var(--theme-color)";
                                    }}
                                    onMouseLeave={() => {
                                      document.getElementById(
                                        i?.id
                                      ).style.outline = "none";
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      className="case__checkbox"
                                      name={"case" + i?.id}
                                      onChange={handleChecked}
                                      defaultChecked={i?.status === "completed"}
                                    />

                                    <span
                                      className="case__description"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        navigate(
                                          `/profile/redirect/${i?.task_id}/${i?.client_id}`
                                        );
                                      }}
                                    >
                                      {i?.task}
                                      <div>{formatDate(i?.due_date)}</div>
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                </div>
              </td>
              <td>
                <div className="cases">
                  {cases &&
                    groupData(cases)
                      ?.pending?.filter(
                        (item) => item !== [] && item.length > 0
                      )
                      .map((item) => {
                        return (
                          <div key={item[0].decname}>
                            <b>{item[0].decname}</b>
                            {item
                              .sort((a, b) => {
                                return (
                                  new Date(a.due_date) - new Date(b.due_date)
                                );
                              })
                              .map((i) => {
                                return (
                                  <div
                                    className={
                                      i?.status === "completed"
                                        ? "case completed"
                                        : "case"
                                    }
                                    key={i?.id}
                                    id={i?.id}
                                    onMouseEnter={() => {
                                      document.getElementById(
                                        i?.id
                                      ).style.outline =
                                        "2px solid var(--theme-color)";
                                    }}
                                    onMouseLeave={() => {
                                      document.getElementById(
                                        i?.id
                                      ).style.outline = "none";
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      className="case__checkbox"
                                      name={"case" + i?.id}
                                      onChange={handleChecked}
                                      defaultChecked={i?.status === "completed"}
                                    />

                                    <span
                                      className="case__description"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        navigate(
                                          `/profile/redirect/${i?.task_id}/${i?.client_id}`
                                        );
                                      }}
                                    >
                                      {i?.task}
                                      <div>{formatDate(i?.due_date)}</div>
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CurrentCaseContainer>
  );
};

export default CurrentCase;
