import styled from "styled-components";

export const MessageBoardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .message-board {
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow-y: scroll;
    .item {
      margin: 10px 0px;
      border-radius: 6px;
      padding: 6px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
        rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
        rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
      position: relative;
      .btn {
        position: absolute;
        right: 0;
        button {
          width: 30px;
          height: 80%;
          margin-right: 6px;
          border: none;
          border-radius: 5px;
          background-color: var(--theme-color);
          color: #fff;
          font-size: 13px;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: #fff;
            color: var(--theme-color);
            border: 1px solid var(--theme-color);
          }
        }
      }
      .item__header {
        font-size: 12px;
      }
      .item__body {
        font-size: 12px;
        margin: 4px 0px;
        padding: 4px;
        border: 1px solid grey;
        width: 100%;
        height: 50px;
        border-radius: 6px;
      }
      .item__footer {
        font-size: 12px;
        margin: 4px 0px;
      }
    }
  }
  .input {
    width: 100%;
    height: 6%;
    display: flex;

    align-items: center;
    input {
      padding-left: 4px;
      width: 80%;
      height: 100%;
      border: none;
      border-radius: 8px;
      outline: none;
      border: 1px solid grey;
    }
    button {
      width: 60px;
      height: 80%;
      margin: 0px 2px;
      border: none;
      border-radius: 5px;
      background-color: var(--theme-color);
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #fff;
        color: var(--theme-color);
        border: 1px solid var(--theme-color);
      }
    }
  }

  #history {
    margin-top: -23px;
    background-color: #fff;
    padding: 10px;
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 40px);
    background-color: #fff;
    right: -510px;
    z-index: 3;
    overflow-y: scroll;
    .item {
      margin: 10px 0px;
      border-radius: 6px;
      padding: 6px;

      .item__body {
        margin: 10px 0px;
      }
    }
  }

  #history.show {
    left: 10px;
  }
`;
