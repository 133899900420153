import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "react-auth-kit";
import { BrowserRouter as Router } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <AuthProvider
      authType="cookie"
      authName="token"
      cookieDomain={window.location.hostname}
      cookieSecure={false}
      cookieMaxAge={60 * 60 * 24 * 7}
    >
      <Router>
        <App />
      </Router>
    </AuthProvider>
  </>
);
