import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import "../../styles/index.css";

import receiptfile from "../Forms/Files/Receipt Template.pdf";
import { toBlob, fetchPdf } from "components/Forms/Forms";
import { PDFDocument, StandardFonts } from "pdf-lib";

const Billing = ({ client }) => {
  console.log(client);
  const { clientId } = useParams();
  const [billing, setBilling] = useState([]);
  const [totalBill, setTotalBill] = useState(0);
  const [clientbill, setClientBill] = useState();
  const [todisplay, setToDisplay] = useState([]);
  const [newPDF, setNewPDF] = useState();
  const [newkey, setNewKey] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      (async () => {
        await axios
          .get(`https://api5.teamfdjfs.com/bill`)
          .then((res) => {
            console.log(res.data);

            setBilling(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })();
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (billing.length > 0) {
      setClientBill(() => {
        let rev = billing.filter((i) => {
          if (i.client_id === parseInt(clientId)) {
            return i;
          }
        });
        return rev;
      });
    }
  }, [billing]);

  useEffect(() => {
    console.log(clientbill);
  }, [clientbill]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (e.target.paystatus.value === "" || e.target.paydate.value === "") {
      alert("Please fill all the fields");
      return;
    }
    const form = {
      date: e.target.paydate.value,
      payment_status: e.target.paystatus.value,
      via: e.target.paymode.value,
      amount: e.target.payamount.value,
      notes: e.target.paynotes.value,
      receipt: e.target.payreceipt.value,
      client_id: parseInt(clientId),
    };
    const request = axios.post("https://api5.teamfdjfs.com/bill", form);
    setBilling([...billing, form]);
    setTotalBill(totalBill + parseInt(form.amount));
    e.target.reset();
    console.log(form);
  };

  async function ViewReceipt(val, key) {
    console.log(key);
    console.log(clientbill[key]);
    const response = await fetch(receiptfile);
    console.log(response);
    const blob = await response.blob();
    console.log(blob);
    const toFile = new File([blob], "Receipt Template", {
      type: "application/pdf",
    });

    let existingPdfBytes = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(toFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const form = pdfDoc.getForm();
    const fields = form.getFields();
    fields.forEach((field) => {
      const type = field.constructor.name;
      const name = field.getName();
      if (name == "date") {
        field.setText(format(new Date(clientbill[key].date), "MMMM dd, yyyy"));
      }
      if (name == "decname") {
        field.setText(client.client.decname);
      }
      if (name == "informant") {
        field.setText(client.client.firstname);
      }
      if (name == "phone") {
        field.setText(client.client.telephone);
      }
      if (name == "infaddress") {
        field.setText(client.primaryintake.nok_address);
      }
      if (name == "email") {
        field.setText(client.client.email);
      }
      if (name == clientbill[key].via) {
        form.getCheckBox(name).check(true);
      }
      if (name == "amount1") {
        field.setText(
          clientbill[key]["amount"]
            ? Number(
                parseFloat(clientbill[key]["amount"]).toFixed(2)
              )?.toLocaleString("en-US", { minimumFractionDigits: 2 })
            : "0.00"
        );
      }
    });
    const pdfBytes = await pdfDoc.save();
    let newtodisplay = [...todisplay];
    newtodisplay[key] = new Blob([pdfBytes], { type: "application/pdf" });
    setToDisplay(newtodisplay);
    document.getElementById("iframediv").style.display = "flex";
    setNewKey(key);
  }

  async function newPDFFunc(key) {
    console.log(key);

    await fetch(document.getElementById("modform-iframe").src).then(
      async function (response) {
        const blob = await response.blob();
        const arraybuffer = await blob.arrayBuffer();
        const toFile = new File([blob], "Receipt Template", {
          type: "application/pdf",
        });
        let existingPdfBytes = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsArrayBuffer(toFile);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
        });

        const pdfDoc = await PDFDocument.load(arraybuffer);
        const pdfBytes = await pdfDoc.save();
        let newtodisplay = [...todisplay];
        newtodisplay[key] = new Blob([pdfBytes], { type: "application/pdf" });
        var downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(newtodisplay[key]);
        downloadLink.download = "downloaded.pdf";

        // Trigger the download
        downloadLink.click();
      }
    );
    // Create a temporary download link
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
        padding: "1rem",
      }}
    >
      <h3 className="text-2xl bold">Billing</h3>
      <div className="bill_heading w-full h-fit">
        <div className="w-full flex align-center justify-between">
          <div className="status_container conttx">
            <label className="labellx">Overall Payment Status:</label>
            <select className="inputssx">
              <option value="">Select</option>
              <option value="Paid In Full">Paid In Full</option>
              <option value="Pending Balance">Pending Balance</option>
              <option value="Insurance to Pay">Insurance to Pay</option>
            </select>
          </div>
          <div className="amount_due conttx">
            <label className="labellx">Total Funeral Home Charges:</label>
            {/* <span className="inputssx">
              ${totalBill}</span> */}

            <input
              className="inputssx"
              type="text"
              id="payamount"
              value={totalBill}
              onChange={(e) => {
                setTotalBill(e.target.value);
              }}
            />
          </div>
        </div>
        <form className="bill_inputs w-full" onSubmit={onSubmit}>
          <div className="input_container conttx">
            <label className="labellx">Date of Payment :</label>
            <input className="inputssx" type="date" id="paydate" />
          </div>
          <div className="input_container conttx w-screen">
            <label className="labellx">Payment Status:</label>
            <select className="inputssx" id="paystatus">
              <option value="">Select</option>
              <option value="Not yet paid">Not yet paid</option>
              <option value="Paid">Paid</option>
              <option value="Paid with balance">Paid with balance</option>
            </select>
          </div>
          <div className="input_container conttx">
            <label className="labellx">Via:</label>
            <select className="inputssx" id="paymode">
              <option value="">Select</option>s
              <option value="Zelle">Zelle</option>
              <option value="Cash">Cash</option>
              <option value="Check">Check</option>
              <option value="Credit Card">Credit Card</option>
              <option value="Bank Transfer">Bank Transfer</option>
            </select>
          </div>
          <div className="input_container  conttx">
            <label className="w-40">Amount:</label>
            <input className="inputssx" type="text" id="payamount" />
          </div>
          <div className="input_container flex gap-8 my-4">
            <label className="w-40">Notes:</label>
            <textarea className="inputssx" id="paynotes"></textarea>
          </div>
          <div className="input_container flex gap-8 my-4">
            <label className="w-40">Reciept:</label>
            <input className="inputssx" type="text" id="payreceipt" />
          </div>
          <div className="input_btn my-2">
            <button
              className="bg-red-800 rounded-lg py-2 px-4 text-white"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </div>
      <div className="bill_table w-full h-fit">
        <table className="border-2 border-red-800 w-full">
          <thead>
            <tr className="border-2 border-red-900">
              <th className="border-2 border-red-800 p-1">Date of Payment</th>
              <th className="border-2 border-red-800 p-1">Payment Status</th>
              <th className="border-2 border-red-800 p-1">Via</th>
              <th className="border-2 border-red-800 p-1">Amount</th>
              <th className="border-2 border-red-800 p-1">Notes</th>
              <th className="border-2 border-red-800 p-1">Receipt</th>
            </tr>
          </thead>
          <tbody>
            {billing
              ?.filter(
                (item) => parseInt(item?.client_id) === parseInt(clientId)
              )
              .map((item, key) => {
                return (
                  <tr className="border-1 border-red-900" key={item?.id}>
                    <td className=" border-2 border-red-800 p-1">
                      {format(new Date(item?.date), "yyyy-MM-dd")}
                    </td>
                    <td className="p-1 border-2 border-red-800">
                      {item?.payment_status}
                    </td>
                    <td className="p-1 border-2 border-red-800">{item?.via}</td>
                    <td className="p-1 border-2 border-red-800">
                      {"$ " +
                        (parseFloat(item?.amount)
                          ? Number(
                              parseFloat(item?.amount).toFixed(2)
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                            })
                          : "0.00")}
                    </td>
                    <td className="p-1 border-2 border-red-800">
                      {item?.notes}
                    </td>
                    <td className="p-1 border-2 border-red-800">
                      {/* {item?.receipt} */}
                      <button
                        onClick={() => {
                          ViewReceipt(item, key);
                        }}
                      >
                        View receipt
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div
        id="iframediv"
        className="w-full h-full absolute top-0 left-0 flex justify-center hidden items-center bg-black bg-opacity-50"
      >
        {newPDF && (
          <iframe
            id="modform-iframe"
            style={{ zoom: 1 }}
            src={URL.createObjectURL(newPDF)}
            className="w-9/12 h-4/5"
          />
        )}
        <div
          id="divformgen"
          className="w-full h-full flex flex-col justify-center items-center relative"
        >
          <iframe
            key={newkey ?? ""}
            id="modform-iframe"
            style={{ zoom: 1 }}
            src={
              todisplay[newkey] ? URL.createObjectURL(todisplay[newkey]) : ""
            }
            className="w-9/12 h-4/5"
          />
          <button
            className="bg-red-900 text-white p-1 w-20"
            onClick={async () => {
              document.getElementById("iframediv").style.display = "none";
              // console.log(document.getElementById("modform-iframe").src);
              // newPDFFunc(newkey);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
  console.log(
    document.getElementById("modform-iframe").contentDocument.onmousever
  );
};

export default Billing;
