import { useState, useEffect } from "react";
import { useParams, Outlet } from "react-router-dom";
import DailyTracker from "../components/UserPage/DailyTracker";
import UserCalendar from "../components/UserPage/UserCalendar";
import UserSide from "../components/UserPage/UserSide";
import { UserPageSection } from "../styles/UserPage.styled";
import toast, { Toaster } from "react-hot-toast";
import CurrentCase from "../components/UserPage/CurrentCase";
import TaskCalendar from "../components/UserPage/TaskCalendar";
import Schedule from "../components/UserPage/Schedule";
import UserProfile from "../components/UserPage/UserProfile";
import EditContract from "./EditContract";
import Itenerary from "../components/UserPage/Itenerary";
import ClientDisplay from "../components/HomePage/ClientDisplay";
import axios from "axios";
import HomePage from "./HomePage";

const UserPage = () => {
  const { userId } = useParams();
  const [currentCase, setCurrentCase] = useState(false);
  const [iteneraries, setIteneraries] = useState(false);
  const [dailyTracker, setDailyTracker] = useState(false);
  const [eventCalendar, setEventCalendar] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const [taskCalendar, setTaskCalendar] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const [contract, setContract] = useState();
  const [client, setClient] = useState(false);
  const [home, setHome] = useState(false);
  const [user, setUser] = useState({});
  const [image, setImage] = useState(null);

  const profile = {
    id: userId,
  };
  useEffect(() => {
    axios
      .get(`https://api5.teamfdjfs.com/auth/`)
      .then((res) => {
        res.data?.find((i) => {
          if (parseInt(i.id) === parseInt(profile?.id)) {
            setUser(i);
            setImage(i?.image ? i?.image : null);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <UserPageSection>
      <div className="wrapper">
        <UserSide
          setCurrentCase={setCurrentCase}
          setIteneraries={setIteneraries}
          setDailyTracker={setDailyTracker}
          setEventCalendar={setEventCalendar}
          setUserProfile={setUserProfile}
          setTaskCalendar={setTaskCalendar}
          setSchedule={setSchedule}
          setClient={setClient}
          setHome={setHome}
          setContract={setContract}
          user={user}
          image={image}
        />
        <div className="main-content">
          <Outlet />
          {home && <HomePage />}
          {client && <ClientDisplay />}
          {currentCase && <CurrentCase />}
          {iteneraries && <Itenerary />}
          {dailyTracker && <DailyTracker />}
          {eventCalendar && <UserCalendar />}
          {userProfile && (
            <UserProfile
              profile={profile}
              toast={toast}
              user={user}
              setUser={setUser}
              image={image}
              setImage={setImage}
            />
          )}
          {taskCalendar && <TaskCalendar />}
          {schedule && (
            // schedule
            <div className="schedule">
              <Schedule />
            </div>
          )}
          {contract && <EditContract />}
        </div>
      </div>
      <Toaster />
    </UserPageSection>
  );
};

export default UserPage;
