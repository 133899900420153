import { ProfileNavContainer } from "../../styles/ProfileNav.styled";

const ProfileNav = ({
  setShowForms,
  setClientDetails,
  setEmail,
  setForms,
  forms,
  email,
  task,
  setTask,
  billing,
  setBilling,
  dailyTracker,
  setDailyTracker,
}) => {
  return (
    <ProfileNavContainer className="flex-row">
      <button
        type="button"
        className="btn"
        onClick={() => {
          setShowForms(true);
          setClientDetails(true);
        }}
      >
        Funeral Details
      </button>
      {dailyTracker ? (
        <button
          type="button"
          className="btn active"
          onClick={() => {
            setEmail(false);
            setForms(false);
            setTask(false);
            setBilling(false);
            setDailyTracker(true);
          }}
        >
          Daily Tracker
        </button>
      ) : (
        <button
          type="button"
          className="btn"
          onClick={() => {
            setEmail(false);
            setForms(false);
            setTask(false);
            setBilling(false);
            setDailyTracker(true);
          }}
        >
          Daily Tracker
        </button>
      )}
      {email ? (
        <button
          type="button"
          className="btn active"
          onClick={() => {
            setEmail(true);
            setForms(false);
            setTask(false);
            setBilling(false);
            setDailyTracker(false);
          }}
        >
          Email
        </button>
      ) : (
        <button
          type="button"
          className="btn"
          onClick={() => {
            setEmail(true);
            setForms(false);
            setTask(false);
            setBilling(false);
            setDailyTracker(false);
          }}
        >
          Email
        </button>
      )}
      {/* task */}
      {task ? (
        <button
          type="button"
          className="btn active"
          onClick={() => {
            setTask(true);
            setEmail(false);
            setForms(false);
            setBilling(false);
            setDailyTracker(false);
          }}
        >
          Tasks
        </button>
      ) : (
        <button
          type="button"
          className="btn"
          onClick={() => {
            setTask(true);
            setEmail(false);
            setForms(false);
            setBilling(false);
            setDailyTracker(false);
          }}
        >
          Tasks
        </button>
      )}

      {/* forms */}
      {forms ? (
        <button
          onClick={() => {
            setEmail(false);
            setForms(true);
            setTask(false);
            setBilling(false);
            setDailyTracker(false);
          }}
          type="button"
          className="btn active"
        >
          Forms
        </button>
      ) : (
        <button
          onClick={() => {
            setEmail(false);
            setForms(true);
            setTask(false);
            setBilling(false);
            setDailyTracker(false);
          }}
          type="button"
          className="btn"
        >
          Forms
        </button>
      )}
      {/* billing */}
      {billing ? (
        <button
          type="button"
          className="btn active"
          onClick={() => {
            setBilling(true);
            setEmail(false);
            setForms(false);
            setTask(false);
            setDailyTracker(false);
          }}
        >
          Billing
        </button>
      ) : (
        <button
          type="button"
          className="btn"
          onClick={() => {
            setBilling(true);
            setEmail(false);
            setForms(false);
            setTask(false);
            setDailyTracker(false);
          }}
        >
          Billing
        </button>
      )}
    </ProfileNavContainer>
  );
};

export default ProfileNav;
