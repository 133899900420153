// import { NewBanner } from "../../styles/NewBanner.styled";
import { Newbanner } from "styles/NewBanner.styled";
import "styles/index.css";
const NewBanner = ({ setShowForm, setAddAccount, setAddClient }) => {
  return (
    <Newbanner>
      <div className="banner">
        <div className="message">
          <div className="urgent">
            <span className="num">4</span>
            <span>Urgent Message</span>
          </div>
          <div>
            <span className="num">8</span>
            <span>Email Approval Request</span>
          </div>
          <div>
            <span className="num"> 24</span>
            <span>Expiring Task In 24 Hours</span>
          </div>
        </div>
      </div>
      <div className="buttons">
        <button
          type="button"
          onClick={() => {
            setShowForm(true);
            setAddAccount(true);
            setAddClient(false);
          }}
        >
          Add Account
        </button>
        <button
          type="button"
          onClick={() => {
            setShowForm(true);
            setAddAccount(false);
            setAddClient(true);
          }}
        >
          Add Client
        </button>
      </div>
    </Newbanner>
  );
};

export default NewBanner;
