import { PrimaryIntakeContainer } from "../../styles/ProfilePrimaryIntake.styled";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { submitFunc, getInfo } from "./MemorialCard";

const CasketMerch = ({ setShowForms2, setCasketMerch }) => {
  let { clientId } = useParams();
  clientId = Number(clientId);

  useEffect(() => {
    getInfo(
      "https://api5.teamfdjfs.com/standby/saveData",
      ".casketmerch",
      clientId,
      "casketmerch"
    );
  }, []);

  return (
    <PrimaryIntakeContainer id="addclient">
      <form>
        <h3>Casket Merchandise Selection</h3>
        <div className="intake__input">
          <label>Casket Selected : </label>
          <input className="casketmerch" type="text" />
        </div>

        <div className="intake__input">
          <label> Updated Casket Orders : </label>
          <input className="casketmerch" type="text" />
        </div>

        <div className="intake__input">
          <label>Casket order placed : </label>
          <input className="casketmerch" type="text" />
        </div>

        <div className="intake__input">
          <label>Special instructions : </label>
          <textarea
            style={{ resize: "vertival", height: "50px", rows: "4" }}
            className="casketmerch"
          />
        </div>

        <div className="intake__input">
          <label>Urn Information : </label>
          <input className="casketmerch" type="text" />
        </div>

        <div className="intake__input">
          <label>Urn Model Name : </label>
          <input className="casketmerch" type="text" />
        </div>

        <div className="intake__input">
          <label>Company : </label>
          <input className="casketmerch" type="text" />
        </div>
      </form>
      <div className="intake__btnn">
        <button
          onClick={() => {
            submitFunc(
              "https://api5.teamfdjfs.com/standby/saveData",
              ".casketmerch",
              clientId,
              "casketmerch"
            );
          }}
        >
          Save
        </button>

        <button
          type="button"
          onClick={() => {
            setShowForms2(false);
            setCasketMerch(false);
          }}
        >
          Exit Window
        </button>
      </div>
    </PrimaryIntakeContainer>
  );
};

export default CasketMerch;
