import styled from "styled-components";

export const TaskContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 5px;

  .banner {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 8px 0;
    .assign {
      width: 450px;
      display: flex;
      align-items: center;
      margin-left: 50px;
      justify-content: space-between;
    }
    .title {
      font-size: 1.2rem;
      font-weight: 500;
    }
    .btn {
      background-color: var(--theme-color);
      border: none;
      color: #fff;
      font-size: 12px;
      padding: 6px;
      margin-left: 10px;
      cursor: pointer;
      border-radius: 4px;
      transition: 0.5s ease;
      :hover {
        background-color: #fff;
        color: #000;
        border: 1px solid var(--theme-color);
      }
    }
  }

  .task-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 5px;
    text-align: center;
    overflow-y: scroll;

    .heading {
      width: calc(100% - 10px);
      height: 30px;
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;

      padding: 0 10px;
      margin-bottom: 10px;

      background-color: #f2f2f2;
      border-radius: 5px;
      th {
        :nth-child(1) {
          width: 30%;
          padding: 10px;
        }

        :nth-child(2) {
          width: 20%;
          padding: 10px;
        }

        :nth-child(3) {
          width: 10%;
          padding: 10px;
        }

        :nth-child(4) {
          width: 20%;
          padding: 10px;
        }

        :nth-child(5) {
          width: 20%;
          padding: 10px;
        }
      }
      td {
        width: 20%;
        font-size: 0.9rem;
        font-weight: 600;
        letter-spacing: 1px;

        :nth-child(1) {
          width: 30%;
          padding: 10px;
        }

        :nth-child(2) {
          width: 20%;
          padding: 10px;
        }

        :nth-child(3) {
          width: 10%;
          padding: 10px;
        }

        :nth-child(4) {
          width: 20%;
          padding: 10px;
        }

        :nth-child(5) {
          width: 20%;
          padding: 10px;
        }
      }
    }
    .btn {
      background-color: var(--theme-color);
      color: #fff;
      border: none;
      margin-left: 4px;
      padding: 4px;
      border-radius: 6px;
      cursor: pointer;
    }

    .task {
      width: calc(100% - 10px);
      min-height: 150px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      background-color: #fff;
      border: 1px solid grey;
      .notes {
        border: 1px solid grey;
        height: 100px;
        text-align: left;
        outline: none;
        padding: 5px;
        border-radius: 10px;
      }

      td {
        width: 20%;
        font-size: 0.9rem;
        font-weight: 500;
        select {
          width: 100px;
        }

        :nth-child(1) {
          width: 30%;
          padding: 10px;
        }

        :nth-child(2) {
          width: 20%;
          padding: 10px;
        }

        :nth-child(3) {
          width: 10%;
          padding: 10px;
        }

        :nth-child(4) {
          width: 20%;
          padding: 10px;
        }

        :nth-child(5) {
          width: 20%;
          padding: 10px;
        }
      }

      .name {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 5px;
        width: 30%;
        input {
          width: 20px;
          height: 20px;
          border-radius: 5px;
          border: 1px solid #ccc;
          outline: none;
        }

        p {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          text-align: justify;
          font-size: 0.9rem;
          font-weight: 500;
        }
      }
    }
    .task:focus {
      outline-color: #701018;
    }
  }
`;
