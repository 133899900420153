import { useState, useEffect, useRef } from "react";
import man from "../../images/man.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { ClientSection } from "../../styles/ClientDisplay.styled";
import "../../styles/index.css";
const ClientDisplay = () => {
  const [selectedClient, setSelectedClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [check, setCheck] = useState([]);
  const [defaultObject, setDefaultObject] = useState();
  const [mappingmethod, setMappingMethod] = useState();
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [year, setYear] = useState([]);
  const [searchClient, setSearchClient] = useState("");
  const [month, setMonth] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);

  const navigate = useNavigate();

  const inquiryStatObject = [
    { text: "At-need", value: "At-need" },
    { text: "Pre-need", value: "Pre-need" },
    { text: "Merchandise", value: "Merchandise" },
    { text: "Miscellaneous", value: "Miscellaneous" },
    { text: "Pending At-need", value: "Pending At-need" },
    { text: "Pending Pre-need", value: "Pending Pre-need" },
    { text: "Pre-need to At-need", value: "Pre-need to At-need" },
    { text: "Pricing for At-need", value: "Pricing for At-need" },
    { text: "Pricing for Pre-need", value: "Pricing for Pre-need" },
  ];
  // const checkStatus = (status) => {
  //   let statusText = inquiryStatObject.filter((item) => item.value === status);
  //   console.log(statusText);
  //   return statusText[0]?.text;
  // };
  useEffect(() => {
    const getClients = async () => {
      const { data } = await axios.get("https://api5.teamfdjfs.com/client");
      //console.log(data);
      setClients(data);
      setDefaultObject(data.reverse());
      setMappingMethod(() => clientMap);
    };
    getClients();
  }, []);

  useEffect(() => {
    if (defaultObject) {
      let yeararray = [...year];
      defaultObject?.filter((item) => {
        if (!yeararray.includes(format(new Date(item.datecreated), "yyyy"))) {
          yeararray.push(format(new Date(item.datecreated), "yyyy"));
        }
      });
      //console.log(yeararray);
      setYear(yeararray);
    }
  }, [defaultObject]);

  useEffect(() => {
    if (selectedClient) {
      navigate(`/profile/${selectedClient.id}`);
    }
  }, [selectedClient, navigate]);

  useEffect(() => {
    let checklength = check.length ? true : false;
    let yearlenght = selectedYears.length ? true : false;
    let monthlength = selectedMonths.length ? true : false;

    let filteredclients = [...clients];

    if (checklength) {
      filteredclients = filteredclients.filter((client) =>
        check.includes(
          client.updatedinquiry ? client.updatedinquiry : client.inquirystatus
        )
      );
    }
    if (yearlenght) {
      filteredclients = filteredclients.filter((client) =>
        selectedYears.includes(format(new Date(client.datecreated), "yyyy"))
      );
    }
    if (monthlength) {
      filteredclients = filteredclients.filter((client) =>
        selectedMonths.includes(format(new Date(client.datecreated), "MMMM"))
      );
    }
    //console.log(filteredclients);
    setDefaultObject(filteredclients);
  }, [selectedMonths, selectedYears, check]);

  //-----------------------------functions-------------------------

  //oncheck in checkboxes
  function onCheck(e) {
    let checkedarray = [...check];
    if (e.checked) {
      checkedarray.push(e.value);
      setCheck(checkedarray);
    } else {
      checkedarray.splice(checkedarray.indexOf(e.value), 1);

      setCheck(checkedarray);
    }
  }
  //onYearClick

  function clientMap(client) {
    return (
      <div
        className="client__container__body__item "
        key={client.id}
        onClick={() => setSelectedClient(client)}
      >
        <p style={{ color: "gray", fontSize: "0.8rem", margin: "6px 0" }}>
          {
            /* {client.dateupdated
            ? checkStatus(client.updatedinquiry)
            : client.datecreated
            ? checkStatus(client.inquirystatus)
            : "N/A"} */

            client.dateupdated ? client.updatedinquiry : client.inquirystatus
          }
        </p>
        <div className="client__image ">
          <img
            className=""
            src={client?.image_data ? client?.image_data : man}
            alt="client"
          />
        </div>
        <div className="client__name">
          <span>{client.decname}</span>
          <p style={{ color: "gray", fontSize: ".8" }}>
            {client.dateupdated
              ? format(new Date(client.dateupdated), "MMMM dd, yyyy") +
                " (updated)"
              : client?.datecreated
              ? format(new Date(client.datecreated), "MMMM dd, yyyy")
              : "N/A"}
          </p>
        </div>
      </div>
    );
  }

  function yeardropdown(monthoryear, index, currentselected, selectedsetter) {
    function setselectedyearmonth(e) {
      let current = [...currentselected];
      if (e.target.checked && !current.includes(e.target.value)) {
        current.push(e.target.value);
      } else if (!e.target.checked && current.includes(e.target.value)) {
        current.splice(current.indexOf(e.target.value), 1);
      }

      selectedsetter(current);
    }

    return (
      <div>
        {/* <details
          onMouseEnter={(e) => {
            e.target.parentElement.setAttribute("open", true);
          }}
          onMouseLeave={(e) => {
            e.target.parentElement.removeAttribute("open");
          }}
        > */}
        <span>{index ? "Month" : "Year"}</span>
        <div className="filterdrop">
          {monthoryear.map((item, key) => (
            <div key={key}>
              <label>
                <input
                  type="checkbox"
                  value={item}
                  //checked={currentpath.includes(item)}
                  onChange={(e) => {
                    setselectedyearmonth(e);
                  }}
                />
                {item}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
  const formatDate = (date) => {
    return format(new Date(date), "MMMM dd, yyyy");
  };
  return (
    <ClientSection>
      <div className="header">
        <span>Client List</span>{" "}
        <div className="search">
          <input
            type="text"
            placeholder="Search"
            value={searchClient}
            onChange={(e) => {
              setSearchClient(e.target.value);
            }}
          />
        </div>
      </div>
      <details style={{ width: "100%" }}>
        <summary className="textt">Filter</summary>

        <div
          className="conttt"
          style={{ display: "flex", flexDirection: "row", width: "500px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "25%",
            }}
          >
            {year.length > 0 &&
              yeardropdown(year, 0, selectedYears, setSelectedYears)}
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "30%" }}
          >
            {year.length > 0 &&
              yeardropdown(month, 1, selectedMonths, setSelectedMonths)}
          </div>

          <div style={{ width: "20%" }}>
            <div>
              {/* <details
                onMouseEnter={(e) => {
                  e.target.parentElement.setAttribute("open", true);
                }}
                onMouseLeave={(e) => {
                  e.target.parentElement.removeAttribute("open");
                }}
              > */}
              <span>Inquiry Status</span>
              <div className="filterdrop">
                {year.length > 0 &&
                  inquiryStatObject.map((item, key) => {
                    console.log(item.value);
                    return (
                      <div style={{ grid: key + 1 }} key={key}>
                        <label>
                          <input
                            type="checkbox"
                            value={item.value}
                            onChange={(e) => onCheck(e.target)}
                          />
                          {item.text}
                        </label>
                      </div>
                    );
                  })}
              </div>
              {/* </details> */}
            </div>
          </div>
        </div>
      </details>
      <div className="client_body ">
        {defaultObject &&
          defaultObject

            .filter((item) => {
              let cldate = format(new Date(item?.datecreated), "MMMM dd, yyyy");
              let update = format(new Date(item?.dateupdated), "MMMM dd, yyyy");

              for (let key in item) {
                if (
                  (item.hasOwnProperty(key) &&
                    String(item[key])
                      .toLowerCase()
                      .includes(searchClient.toLowerCase())) ||
                  String(cldate)
                    .toLowerCase()
                    .includes(searchClient.toLowerCase()) ||
                  String(update)
                    .toLowerCase()
                    .includes(searchClient.toLowerCase())
                ) {
                  //console.log(String(item[key]));
                  return true; // Return true if search value found
                }
              }
              return false;
            })
            .map((client) => mappingmethod(client))}
      </div>
    </ClientSection>
  );
};

export default ClientDisplay;
