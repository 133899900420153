import styled from "styled-components";

export const CurrentCaseContainer = styled.div`
  width: 100%;
  height: 100%;
  h2 {
    font-size: 16px;
    margin: 10px 0px;
  }

  .completed {
    .case__description {
      text-decoration: line-through;
    }
  }
  .tablee {
    min-width: 100%;
    height: 90%;
    border-collapse: collapse;
    overflow-y: scroll;

    table {
      width: 100%;
    }
  }

  tr {
    display: flex;
  }
  th {
    background-color: #f2f2f2;
    padding: 10px;
    border: 1px solid #ddd;
    :nth-child(1) {
      width: 20%;
      padding: 10px;
    }

    :nth-child(2) {
      width: 20%;
      padding: 10px;
    }

    :nth-child(3) {
      width: 20%;
      padding: 10px;
    }

    :nth-child(4) {
      width: 20%;
      padding: 10px;
    }

    :nth-child(5) {
      width: 20%;
      padding: 10px;
    }
  }
  td {
    padding: 10px;
    border: 1px solid #ddd;
    width: 20%;
    font-size: 0.9rem;
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    .case {
      margin: 20px 0px;
      input {
        margin-right: 8px;
      }
    }

    :nth-child(1) {
      width: 20%;
      padding: 10px;
    }

    :nth-child(2) {
      width: 20%;
      padding: 10px;
    }

    :nth-child(3) {
      width: 20%;
      padding: 10px;
    }

    :nth-child(4) {
      width: 20%;
      padding: 10px;
    }

    :nth-child(5) {
      width: 20%;
      padding: 10px;
    }
  }
`;
