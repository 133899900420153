import { PrimaryIntakeContainer } from "../../styles/ProfilePrimaryIntake.styled";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { format, isValid } from "date-fns";

export async function submitFunc(link, classname, clientId, type) {
  let temp = [];
  document.querySelectorAll(classname).forEach((e) => {
    //console.log(e.value);
    temp.push(e.value);
  });

  await axios
    .post(link, {
      temp,
      clientId,
      type,
    })
    .then((res) => {
      if (res.data.status == 200) {
        alert("Sucess");
      } else {
        alert("Error");
      }
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
      alert(err.message);
    });

  console.log(temp);
}

export async function getInfo(link, classname, clientId, type) {
  axios
    .post(link, {
      get: true,
      clientId,
      type,
    })
    .then((res) => {
      console.log(res);

      let curr = res.data.length > 0 ? Object?.values(res.data[0]) : [];
      console.log(curr);
      if (curr.length > 0) {
        document.querySelectorAll(classname).forEach((e, i) => {
          e.value =
            isValid(new Date(curr[i + 2])) && String(curr[i + 2])
              ? format(new Date(curr[i + 2]), "yyy-MM-dd")
              : curr[i + 2] ?? "";
          // e.value = res.data[0][i];
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

const MemorialCard = ({ setShowForms2, setMemorialCard }) => {
  let { clientId } = useParams();
  clientId = Number(clientId);

  useEffect(() => {
    getInfo(
      "https://api5.teamfdjfs.com/standby/saveData",
      ".memorialcard",
      clientId,
      "memorialcard"
    );
  }, []);

  return (
    <PrimaryIntakeContainer id="addclient">
      <form>
        <h3>Memorial Card</h3>
        <div className="intake__input">
          <label>No Memorial Card </label>
          <input className="memorialcard" type="checkbox" />
        </div>

        <div className="intake__input">
          <label>Photo: </label>

          <input className="memorialcard" type="text" />
        </div>

        <div className="intake__input">
          <label>Passage/Scripture: </label>
          <textarea
            className="memorialcard"
            placeholder="Insert link and label"
            rows={4}
            style={{ resize: "vertical" }}
          />
        </div>

        <div className="intake__input">
          <label>Designed: </label>
          <select className="memorialcard">
            <option value="">Not Started</option>
            <option value="In Progress">In Progress</option>
            <option value="Completed">Completed</option>
          </select>
        </div>

        <div className="intake__input">
          <label>Language: </label>
          <select className="memorialcard" name="" id="">
            <option value="">Select</option>
            <option value="English">English</option>
            <option value="French">French</option>
            <option value="French">English and French</option>
          </select>
        </div>

        <div className="intake__input">
          <label>Printed</label>
          <select className="memorialcard">
            <option value="">Not Started</option>
            <option value="In Progress">In Progress</option>
            <option value="Completed">Completed</option>
          </select>
        </div>

        <div className="intake__input">
          <label>Laminated</label>
          <select className="memorialcard">
            <option value="">Not Started</option>
            <option value="In Progress">In Progress</option>
            <option value="Completed">Completed</option>
          </select>
        </div>

        <div className="intake__input">
          <label>Quantity</label>
          <input className="memorialcard" type="text" />
        </div>
      </form>
      <div className="intake__btnn">
        <button
          onClick={() => {
            submitFunc(
              "https://api5.teamfdjfs.com/standby/saveData",
              ".memorialcard",
              clientId,
              "memorialcard"
            );
          }}
        >
          Save
        </button>

        <button
          type="button"
          onClick={() => {
            setShowForms2(false);
            setMemorialCard(false);
          }}
        >
          Exit Window
        </button>
      </div>
    </PrimaryIntakeContainer>
  );
};

export default MemorialCard;
