import styled from "styled-components";

export const AddAccountContainer = styled.div`
  width: 100%;
  height: 100%;

  .addAccount__header {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    h3 {
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 1px;
    }
  }

  .addAccount__body {
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #e0e0e0;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 20px;

      .addAccount__body__input {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 20px;

        label {
          width: 100px;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 1rem;
          font-weight: 500;
          letter-spacing: 1px;
          color: #f5f5f5;
        }

        input {
          width: calc(100% - 100px);
          height: 100%;
          padding: 0 10px;
          border: none;
          border-radius: 5px;
          background-color: #f5f5f5;
          color: #424242;
          font-size: 1rem;
          font-weight: 500;
          letter-spacing: 1px;
          outline: none;

          &:focus {
            background-color: #e0e0e0;
          }
        }
      }
      .addAccount__body__role {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        select {
          width: 100%;
          height: 100%;
          padding: 0 10px;
          border: none;
          border-radius: 5px;
          background-color: #f5f5f5;
          color: #424242;
          font-size: 1rem;
          font-weight: 500;
          letter-spacing: 1px;
          outline: none;
          text-align: center;
        }
      }

      .addAccount__body__buttons {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        button {
          width: 150px;
          height: 100%;
          padding: 0 10px;
          border: none;
          border-radius: 5px;
          background-color: #f5f5f5;
          color: #424242;
          font-size: 1rem;
          font-weight: 500;
          letter-spacing: 1px;
          outline: none;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
`;
