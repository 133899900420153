import styled from "styled-components";

export const FuneralDetailsContainer = styled.div`
  width: 500px;
  height: 500px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  .funeral-details {
    width: 100%;
    height: 100%;
  }
  .funeral-details__title {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    color: #131313;
    border-bottom: 1px solid var(--theme-color);
  }
  form {
    padding: 10px;
    width: 100%;
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
    overflow-y: scroll;
    .funeral-details__input {
      width: 100%;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      margin: 6px 0px;
    }
    label {
      width: 130px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      font-weight: 600;
      color: var(--theme-color);
    }
    input[type="checkbox"] {
      width: 20px;
    }
    input {
      width: 50%;
      height: 80%;
      border: 1px solid #131313;
      border-radius: 5px;
      padding: 0 8px;
      font-size: 14px;
      font-weight: 500;
      color: #131313;

      &:focus {
        outline: none;
      }
    }

    select {
      width: 50%;
      height: 80%;
      border: 1px solid #131313;
      border-radius: 5px;
      padding: 0 10px;
      font-size: 1rem;
      font-weight: 500;
      color: #131313;
      text-align: left;
    }
    .funeral-details__btn {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      margin-top: 10px;

      button {
        width: 80px;
        height: 80%;
        border: none;
        border-radius: 5px;
        background-color: var(--theme-color);
        color: #fff;
        font-size: 13px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: #fff;
          color: var(--theme-color);
          border: 1px solid var(--theme-color);
        }
      }
    }
  }
`;
