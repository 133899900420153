import styled from "styled-components";

export const HomePageSection = styled.section`
  * {
    font-family: "Poppins", sans-serif;
  }
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
  padding: 5px;

  .mainContainer {
    width: 100%;
    height: calc(98vh - 75px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px;
    gap: 10px;
    /* maincontainer left */
    .mainContainer__left {
      width: 350px;
      height: 100%;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    }
    /* maincontainer in the right */
    .mainContainer__right {
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);

      .mainContainer__right__header {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        border-bottom: 1px solid #ccc;
        /*  */
        .mainContainer__right__header__content {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;

          .dashboard {
            font-size: 20px;
            color: #000;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 1.5;
          }

          .welcome {
            font-size: 16px;
            color: #000;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 1.5;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .name {
              font-size: 16px;
              color: var(--theme-color);
              font-weight: 600;
              letter-spacing: 2px;
            }
          }
        }

        .profile {
          font-size: 20px;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            transform: scale(1.1);
            transform: rotate(-180deg);
          }
          margin: 0 10px;

          img {
            width: 95%;
            height: 95%;
            border-radius: 50%;

            object-fit: cover;
          }
        }

        .message {
          font-size: 15px;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            transform: scale(1.1);
            transform: rotate(-360deg);
          }
          margin: 0 10px;
        }
      }

      /* body of the main container */
      .mainContainer__right__body {
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 10px;
        position: relative;
        .mainContainer__right__leftside {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
        }

        .message {
          max-height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          padding: 0 10px;
          border-radius: 10px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
          position: absolute;
          top: 0;
          right: -600px;
          width: 30%;
          height: 100%;
          background-color: rgba(255, 255, 255);
          //color: #fff;
          transition: right 0.3s ease-in-out;
          z-index: 1;
          /* right: 0; */
        }
        #messageside.show {
          right: 0;
        }
        #rightside.show {
          right: 0;
        }

        .mainContainer__right__rightside {
          max-height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          padding: 0 10px;
          border-radius: 10px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
          position: absolute;
          top: 0;
          right: -600px;
          width: 30%;
          height: 100%;
          background-color: rgba(255, 255, 255);
          //color: #fff;
          transition: right 0.3s ease-in-out;
          z-index: 2;
        }
      }
    }
  }

  .formsContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 1;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;

    .formsContainer__form {
      width: 520px;
      height: 600px;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      backdrop-filter: blur(12px) saturate(138%);
      -webkit-backdrop-filter: blur(12px) saturate(138%);
      background-color: rgba(155, 155, 155, 0.64);
      border-radius: 12px;
      border: 1px solid rgba(209, 213, 219, 0.3);

      .formbtn {
        margin-top: 4px;
        width: 100px;
        height: 100%;
        padding: 6px;
        border: none;
        border-radius: 5px;
        background-color: var(--theme-color);
        color: #fff;
        font-size: 13px;
        font-weight: 600;

        margin-right: 6px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: #fff;
          color: var(--theme-color);
          border: 1px solid var(--theme-color);
        }
      }

      .close {
        /* background-color: var(--theme-color); */
        position: absolute;
        background-color: var(--theme-color);
        border-radius: 50%;
        color: #fff;
        top: 0;
        right: 0;
        margin-right: -18px;
        margin-top: -26px;
        &:hover {
          /* background-color: #fff; */
          color: #fff;
          border: 1px solid var(--theme-color);
          cursor: pointer;
        }
      }

      .switch {
        position: relative;
        width: 100%;
        height: 35px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: 0 10px;
        gap: 10px;
        margin-bottom: 10px;
      }

      .item {
        width: 100%;
        height: 100%;
        background: #fff;
        color: rgb(31 41 55);
        font-weight: 600;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }
      }

      .item.active {
        background: var(--theme-color);
        color: #fff;
        opacity: 1;
      }

      .addcont {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .active {
    left: 0;
  }

  @keyframes shake {
    0% {
      transform: translatex(1px, 1px) rotate(0deg);
    }
    50% {
      transform: translatex(-1px, -2px) rotate(-1deg);
    }
    100% {
      transform: translatex(1px, 1px) rotate(1deg);
    }
  }
`;
