import styled from "styled-components";

export const LoginPageSection = styled.section`
  height: 100vh;
  width: 100vw;
  background: linear-gradient(rgba(0, 0, 0, 0.199), #72152e);
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  form {
    width: 400px;
    background-color: #e6dbdb;
    padding: 30px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
    text-align: center;
  }

  img {
    margin-bottom: 30px;
    width: 80px;
    height: 80px;
  }

  h2 {
    color: #222121;
    font-size: 20px;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    color: #222121;
    margin-bottom: 40px;
  }

  .form-group {
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
  }

  label {
    display: flex;
    font-size: 14px;
    color: #222121;
    margin-bottom: 8px;
  }

  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 5px;
    font-size: 13px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #413f3f;
    outline: none;
    transition: all 0.3s ease-in-out;
  }

  input[type="text"]:focus,
  input[type="password"]:focus {
    border-bottom: 2px solid #521a1a;
    width: 100%;
  }

  input[type="text"]::placeholder,
  input[type="password"]::placeholder {
    color: #999;
    font-size: 13px;
  }

  button[type="submit"] {
    width: 100%;
    padding: 10px;
    background: #72152e;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    margin-top: 20px;
    transition: 0.5 ease;
  }
  button[type="submit"]:hover {
    transform: translateY(-2px);
  }
  form img {
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 50%;
    filter: hue-rotate(250deg);
  }
`;
