import styled from "styled-components";

export const ProfileBannerContainer = styled.div`
  * {
    list-style: none;
  }
  height: calc(100% - 20px);
  width: 300px;
  background-color: #fff;
  border-radius: 10px 0 0 10px;
  padding: 0 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  .banner {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .icon {
      width: 30px;
      padding: 5px;
      height: 30px;
      border-radius: 50%;
      background-color: var(--theme-color);
      font-size: 30px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #fff;
        color: var(--theme-color);
        border: 2px solid var(--theme-color);
      }
    }

    .text {
      font-size: 18px;
      font-weight: 600;
      color: var(--theme-color);
      letter-spacing: 1px;
    }
  }

  .profile {
    height: 90px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    .profile__img {
      width: 80px;
      height: 80px;
      min-width: 80px;
      min-height: 80px;
      border-radius: 50%;
      border: 1px solid var(--theme-color);
      margin: 10px 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        min-height: 80px;
        min-width: 80px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .profile__name {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      align-self: start;

      h3 {
        font-size: 17px;
        font-weight: 600;
        color: #131313;
        letter-spacing: 1px;
      }
    }
  }

  .profile-details {
    height: calc(100% - 120px - 30px - 50px);
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 10px;
    overflow-y: scroll;

    .notesbtn {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 6px;
      .referral {
        margin-top: 10px;
        width: 100%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        text-align: left;
        select {
          width: 50%;
        }
      }
      button {
        width: 90%;
        border: none;
        padding: 4px 2px;
        border: 1px solid grey;
        background-color: var(--theme-color);
        color: #fff;
        border-radius: 10px;
        font-weight: 400;

        cursor: pointer;
        transition: 0.5s;
        :hover {
          background-color: #fff;
          color: var(--theme-color);
          border: 2px solid var(--theme-color);
        }
      }
    }
    .profile-details-title {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;

      span {
        font-size: 16px;
        font-weight: 500;
        color: #131313;
        letter-spacing: 1px;
        margin-right: 10px;
      }

      .edit {
        width: 20px;
        height: 20px;
        font-size: 20px;
        color: var(--theme-color);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .btn button {
      background-color: var(--theme-color);
      border: none;
      padding: 6px;
      cursor: pointer;
      margin-right: 8px;
      color: #fff;
      border-radius: 6px;
      :hover {
        background-color: #fff;
        color: var(--theme-color);
        font-weight: 600;
        border: 1px solid var(--theme-color);
      }
    }
    .profile-details__content {
      width: 100%;
      height: calc(100% - 30px);
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-top: 10px;

      padding-right: 10px;
      gap: 8px;
      .profile-details__content__item {
        width: 100%;
        //height: 40px;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .profile-details__content__item__title {
          width: 92px;
          height: 100%;
          display: flex;
          padding: 6px 0px;
          align-items: center;
          justify-content: flex-start;
          font-size: 11px;
          font-weight: 500;
          color: var(--theme-color);
          letter-spacing: 1px;
        }

        .profile-details__content__item__value {
          width: calc(100% - 120px);
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 12px;
          font-weight: 400;
          color: #131313;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 6px 0px;

          select {
            width: 100%;
            height: 100%;
          }

          .rmsc {
            --rmsc-main: #4285f4;
            --rmsc-hover: #f1f3f5;
            --rmsc-selected: #e2e6ea;
            --rmsc-border: #ccc;
            --rmsc-gray: #131313;
            --rmsc-bg: #fff;
            --rmsc-p: 10px; /* Spacing */
            --rmsc-radius: 4px; /* Radius */
            --rmsc-h: 30px; /* Height */
          }
          .dropdown-container {
            height: 30px;
            max-width: 145px;
            min-width: 145px;

            .dropdown-content {
              width: 100%;
            }
          }
        }

        .edit {
          border: 1px solid var(--theme-color);
          border-radius: 4px;
        }
      }
    }
  }
`;
