import styled from "styled-components";

export const ClientSection = styled.section`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10 20px;
    details {
    }
    .search {
      width: 330px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      border-radius: 5px;
      margin: 0 20px;
      position: relative;

      input {
        width: 100%;
        border-radius: 5px;
        height: 100%;
        border: 1px solid grey;
        outline: none;
        background-color: #fff;
        padding: 0 10px;
        font-size: 16px;
        color: #000;
        font-weight: 500;
      }
    }
  }
  div {
    div {
      cursor: pointer;
    }
  }
  summary {
    cursor: pointer;
  }
  .conttt {
    background-color: rgba(255, 255, 255, 0.75);
    height: 430px;
    position: absolute;
    border: 1px solid grey;
    padding: 20px;
    border-radius: 10px;
  }

  .filterdrop {
    position: fixed;

    div {
      margin: 5px 0px;
      input {
        margin-right: 8px;
      }
    }
  }

  .client_body {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y: auto;
    .client__container__body__item {
      width: 160px;
      height: 230px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #fff;
      border-radius: 5px;
      margin: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
      cursor: pointer;
      :hover {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
          rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
          rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
      }

      .client__image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-size: cover;
        }
      }

      .client__name {
        font-size: 12px;
        color: #000;
        font-weight: 600;
        letter-spacing: 1px;
        text-align: center;
        justify-content: center;
        align-items: center;

        p span {
          text-overflow: clip;
          white-space: nowrap;
          max-width: 100px;
        }
      }
    }
  }
`;
