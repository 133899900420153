import { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";

const TaskCalendar = () => {
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    let unsubscribe = false;
    const getEvents = async () => {
      const res = await axios.get("https://api5.teamfdjfs.com/task");
      console.log(typeof res.data);

      if (!unsubscribe && res.data) {
        let tasks = [];

        res.data.forEach((task) => {
          tasks.push({
            title: task?.task + " - " + task?.firstname,
            start: new Date(task?.due_date),
            end: new Date(moment(task?.due_date).add(1, "days")),
          });
        });
        console.log(tasks);
        setEvents(tasks);
      }
    };
    getEvents();
    return () => {
      unsubscribe = true;
    };
  }, []);
  const calendarStyle = {
    height: "calc(97vh - 50px)",
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "10px",
  };
  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = "#701018";
    const titleTextColor = "white";

    const style = {
      backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: titleTextColor,
      height: "50px",
      border: "none",
      display: "block",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    };

    return {
      style: style,
    };
  };
  const eventTitle = (event) => {
    return (
      <div
        style={{
          display: "block",
          textAlign: "left",
          whiteSpace: "normal",
          textOverflow: "unset",
        }}
      >
        {event.title}
      </div>
    );
  };
  return (
    <>
      <div className="task-calendar">
        <h2>Task Calendar</h2>

        <div className="calendar">
          <Calendar
            localizer={localizer}
            events={events}
            // defaultView={["day"]}
            startAccessor="start"
            endAccessor="end"
            selectable
            style={calendarStyle}
            eventPropGetter={eventStyleGetter}
            components={{
              event: eventTitle,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TaskCalendar;
