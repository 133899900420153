import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/esm/entry.parcel2";
import { FormStyle } from "../styles/ClientPage.styled";
import { format, isValid, toDate, differenceInYears } from "date-fns";

import { PDFDocument, StandardFonts } from "pdf-lib";
import { simpleCrypto } from "../components/Email/EmailFormat";
import axios from "axios";
import agreementforms from "../components/Forms/Files/Funeral-Service-Arrangement-Forms.pdf";

async function insertSign(pdfDoc, pages, signdata) {
  const signimage = await pdfDoc.embedPng(signdata);
  const jpgDims = signimage.scale(0.5);
  let page2 = pages[1];
  let page3 = pages[2];
  let page4 = pages[3];
  let page5 = pages[4];
  page2.drawImage(signimage, {
    x: page2.getWidth() / 4,
    y: 310,
    width: 200,
    height: 36.5,
  });
  page3.drawImage(signimage, {
    x: page2.getWidth() / 4,
    y: 140,
    width: 200,
    height: 36.5,
  });
  page4.drawImage(signimage, {
    x: page2.getWidth() / 4,
    y: 90,
    width: 200,
    height: 36.5,
  });
  page5.drawImage(signimage, {
    x: page2.getWidth() / 4,
    y: 115,
    width: 200,
    height: 36.5,
  });
}

export async function fetchPdf(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  const toFile = new File([blob], "1.pdf", { type: "application/pdf" });
  console.log(toFile);
  return toFile;
}

export async function modifyPdfs(file, values, setter) {
  let existingPdfBytes = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  await pdfDoc.embedFont(StandardFonts.CourierBold);

  const pages = pdfDoc.getPages();
  const form = pdfDoc.getForm();
  const fields = form.getFields();

  //insertSign(pdfDoc, pages, signdata);

  let Objectkeys = Object?.keys(values);
  let Objectentries = values;
  let currdate = new Date();
  let formatdate = format(currdate, "MM-dd-yyyy");

  //Objectentries.currdate = currdate.toDateString();
  //console.log(Objectentries);
  fields.forEach(async (field) => {
    const type = field.constructor.name;
    const name = field.getName();

    if (type === "PDFTextField") {
      if (Objectentries[name]) {
        if (Objectkeys.includes(name)) {
          if (Objectentries[name]) {
            form.getTextField(name).setText(Objectentries[name].toString());
          }
        }
      } else {
        if (
          (name === "wakedate" || name === "funeraldate") &&
          Objectentries[name]
        ) {
          const formdate = format(toDate(Objectentries[name]), "MM-dd-yyyy");
          form.getTextField(name).setText(formdate);
        }
        if (name == "waketime" && Objectentries["wakedate"]) {
          const date = new Date(Objectentries["wakedate"]);
          const formtime = format(date, "hh:mm aa");
          form.getTextField(name).setText(formtime);
        }
        if (name == "funeraltime" && Objectentries["funeraldate"]) {
          const date = new Date(Objectentries["funeraldate"]);
          const formtime = format(date, "hh:mm aa");
          form.getTextField(name).setText(formtime);
        }
        if (name === "date") {
          form.getTextField(name).setText(formatdate);
        }

        if (name === "informant") {
          let fullname = `${
            Objectentries.inffirstname +
            " " +
            Objectentries.infmiddlename +
            " " +
            Objectentries.inflastname
          }`;

          form.getTextField(name).setText(fullname.toString());
        }
      }
    }
  });

  const pdfBytes = await pdfDoc.save();
  setter(new Blob([pdfBytes], { type: "application/pdf" }));
}

const ClientPage = () => {
  const [formValues, setFormValues] = useState({});
  const [inpVal, setInpVal] = useState();
  const [changingVal, setChangingVal] = useState();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");

  const [fetchedDetails, setFetchedDetails] = useState();
  const [modifiedPdf, setModifiedPdfs] = useState();
  const [numpages, setNumPages] = useState();

  //enabling and disabling inputfields
  const [forVeteran, setForVeteran] = useState(true);
  const [forMarried, setForMarried] = useState(true);

  const { clientId } = useParams();

  formValues.id = simpleCrypto.decrypt(clientId);
  console.log(formValues);
  const navigate = useNavigate();

  //forfileautomation

  useEffect(() => {
    async function call() {
      await axios
        .get("https://countriesnow.space/api/v0.1/countries")
        .then((response) => setCountries(response.data.data))
        .catch((error) => console.error(error));
      let decryptedId = null;
      try {
        decryptedId = simpleCrypto
          ?.decrypt(decodeURIComponent(clientId))
          ?.toString();
      } catch (err) {
        alert("Invalid link");
        return navigate(-1, { replace: true });
      }

      await axios
        .get("https://api5.teamfdjfs.com/forms/getDetails", {
          params: { decryptedId },
        })
        .then((res) => {
          console.log(res);
          if (res.data.filled) {
            alert("You already filled this form");
            return navigate(-1, { replace: true });
          }
          res.data.id ? setFetchedDetails(res.data) : alert("No data found");
        })
        .catch((err) => {
          console.log(err);
        });
    }
    call();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      axios
        .get(`https://countriesnow.space/api/v0.1/countries/states`)
        .then((response) => {
          const data = response.data.data;
          const state = data.find(
            (country) => country.name === selectedCountry
          );
          //console.log(state.states);
          setStates(state.states);
        })
        .catch((error) => console.error(error));
    } else {
      setStates([]);
      // setCities([]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    console.log(formValues);
    formValues?.veteran && formValues?.veteran == "yes"
      ? setForVeteran(false)
      : setForVeteran(true);
    formValues?.civ_status && formValues?.civ_status == "married"
      ? setForMarried(false)
      : setForMarried(true);
  }, [formValues]);

  const handleChange = (e) => {
    setInpVal(e.target);
    setChangingVal(e.target.value);
  };

  //for debounce
  useEffect(() => {
    if (inpVal) {
      const timer = setTimeout(() => {
        if (changingVal) {
          setFormValues({ ...formValues, [inpVal.name]: inpVal.value });
        } else {
          //setFormValues({ ...formValues, [inpVal.name]: null });
          delete formValues[inpVal.name];

          console.log(formValues);
          let newformval = { ...formValues };
          setFormValues(newformval);
          console.log(Object.values(formValues).length);
        }
      }, 200);
      return () => {
        clearTimeout(timer);
        //setInpVal();
      };
    }
  }, [changingVal]);

  useEffect(() => {
    const selected = countries.find(
      (country) => country.country === formValues.deathcountry
    );

    console.log(selected);
    setSelectedCountry(selected?.country);
    //setCities(selected?.cities);
  }, [formValues.deathcountry]);

  useEffect(() => {
    if (formValues.deathstate && formValues.deathcountry) {
      axios
        .post(`https://countriesnow.space/api/v0.1/countries/state/cities`, {
          country: selectedCountry,
          state: formValues.deathstate,
        })
        .then((response) => {
          console.log(response.data.data);
          setCities(response.data.data);
        })
        .catch((error) => console.error(error));
    }
  }, [formValues.deathstate]);

  const proceedSubmit = async () => {
    console.log(formValues);
    axios
      .post("https://api5.teamfdjfs.com/forms/submitDetails", formValues)
      .then((res) => {
        console.log(res);
        if (res.data.message === "success") {
          document
            .querySelectorAll(".decInfo")
            .forEach((el) => (el.value = ""));

          document.getElementById("warning").style.display = "none";
          alert("Add successful!");
          // let combinedData = { ...fetchedDetails, ...formValues };
          // console.log(combinedData);
          let encrypted = encodeURIComponent(simpleCrypto.encrypt(formValues));
          localStorage.setItem("dataset", encrypted);

          window.location.replace(`/client/filled/submit?dataset=${encrypted}`);
        } else if (res.data.message === "error") {
          alert("Error! Please try again.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //submit to the database

  const showSignaturePad = () => {
    document.getElementById("signaturediv").style.display = "flex";
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    //document.getElementById("signaturediv").style.display = "flex";
    let arrNoVal = [];
    let copy = { ...formValues };
    document.querySelectorAll(".decInfo").forEach((el) => {
      console.log(el.value);

      copy[el.name] = el.value;

      if (!el.value && !el.disabled) {
        arrNoVal.push(el.previousSibling.innerText);
      }
    });
    setFormValues(copy);
    if (arrNoVal.length > 0) {
      document.getElementById("warning").style.display = "flex";
      document.getElementById("warningtext").innerText = arrNoVal.join(", ");
    } else {
      proceedSubmit();
    }
  };

  function goToPage(e, page) {
    e.parentElement.style.display = "none";
    document.getElementById(page).style.display = "flex";
  }
  console.log(fetchedDetails);

  return fetchedDetails ? (
    <FormStyle>
      <div id="mainform">
        <div id="genform">
          <div>
            <button
              onClick={() => {
                document.getElementById("mainform").style.display = "none";
              }}
            >
              Close
            </button>
          </div>
          <div id="modform">
            {modifiedPdf && (
              <div id="modform-div">
                <Document
                  file={modifiedPdf}
                  className="doc"
                  onLoadSuccess={({ numPages }) => {
                    console.log(numPages);
                    setNumPages(numPages);
                  }}
                >
                  {Array.from(new Array(numpages), (el, index) => (
                    <Page
                      key={index}
                      className="pdf-page"
                      pageNumber={index + 1}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  ))}
                </Document>
              </div>
            )}
          </div>
        </div>
      </div>

      <div style={{ display: "none" }} id="warning">
        <div id="alertdiv">
          <p> Fields for </p>
          <h5 id="warningtext"></h5>
          <p> are empty. Do you want to proceed?</p>
          <div>
            <button onClick={proceedSubmit}>Yes</button>
            <button
              onClick={() => {
                document.getElementById("warning").style.display = "none";
              }}
            >
              Exit Window
            </button>
          </div>
        </div>
      </div>

      <div className="mainform-div" id="page1">
        <h2>Client Form</h2>
        <div id="form-div">
          <div className="form">
            <div>
              <label htmlFor="arrnum">Number of Death Certificate </label>
              <input
                defaultValue={1}
                //onChange={handleChange}
                name="dcnum"
                className="decInfo"
                type="number"
                min={1}
              />
              <div>
                <label htmlFor="arrnum">Arrangement Number </label>
                <input
                  //onChange={handleChange}
                  name="arrnum"
                  className="decInfo"
                  type="text"
                />
              </div>
              <div>
                <label htmlFor="arrdate">Arrangement Date </label>
                <input
                  //onChange={handleChange}
                  name="arrdate"
                  className="decInfo"
                  type="date"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="decname">Name of Deceased</label>
                <input
                  defaultValue={fetchedDetails?.decname}
                  //onChange={handleChange}
                  name="decname"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>

              {/* <label htmlFor="decfirstname">
                First Name
                <input
                  //onChange={handleChange}
                  name="decfirstname"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </label>

              <label htmlFor="decmiddlename">
                Middle Name
                <input
                  //onChange={handleChange}
                  className="decInfo"
                  type="text"
                  name="decmiddlename"
                  placeholder=" middlename"
                />
              </label>

              <label htmlFor="declastname">
                Last Name
                <input
                  //onChange={handleChange}
                  name="declastname" 
                   className="decInfo"
                  type="text"
                  placeholder=" lastname"
                />
              </label> */}
              <div>
                <label htmlFor="deathdate">Date of death</label>
                <input
                  defaultValue={
                    fetchedDetails?.date_of_death !== null &&
                    fetchedDetails?.date_of_death !== undefined &&
                    fetchedDetails?.date_of_death !== "0000-00-00"
                      ? format(
                          new Date(fetchedDetails?.date_of_death),
                          "yyyy-MM-dd"
                        )
                      : ""
                  }
                  onChange={handleChange}
                  name="deathdate"
                  className="decInfo"
                  type="date"
                />
              </div>

              <h5>If death ocurred in hospital state the name</h5>
              <div>
                <label htmlFor="deathplace">Death Place: </label>
                <input
                  defaultValue={fetchedDetails?.death_place}
                  //onChange={handleChange}
                  name="deathplace"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>

              <div>
                <label htmlFor="deathcity">City</label>
                <input
                  type="text"
                  defaultValue={fetchedDetails?.death_city}
                  name="deathcity"
                  className="decInfo"
                  //onChange={handleChange}
                ></input>
                {/* <select
                  defaultValue={fetchedDetails?.death_city}
                  //onChange={handleChange}
                  name="deathcity"
                  className="decInfo"
                  disabled={!cities}
                  // disabled={!selectedState}
                >
                  <option value="">--Select a city--</option>
                  {cities?.map((city, key) => (
                    <option key={key} value={city}>
                      {city}
                    </option>
                  ))}
                </select> */}
                {/* <input
                  //onChange={handleChange}
                  name="deathcity"
                  className="decInfo"
                  type="text"
                  placeholder=""
                /> */}
              </div>
              <div>
                <label htmlFor="deathstate">State</label>
                <input
                  type="text"
                  defaultValue={fetchedDetails?.death_state}
                  name="deathstate"
                  className="decInfo"
                  //onChange={handleChange}
                ></input>
                {/* <select
                  defaultValue={fetchedDetails?.state}
                  onChange={handleChange}
                  name="deathstate"
                  className="decInfo"
                  disabled={!selectedCountry}
                >
                  <option value="">--Select a state--</option>
                  {states?.map((state, key) => (
                    <option key={key} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </select> */}
                {/* <input
                  //onChange={handleChange}
                  name="deathstate"
                  className="decInfo"
                  type="text"
                  placeholder=""
                /> */}
              </div>
              <div>
                <label htmlFor="deathcountry">Country</label>
                <input
                  type="text"
                  defaultValue={fetchedDetails?.death_country}
                  id="deathcountry"
                  onChange={handleChange}
                  name="deathcountry"
                  className="decInfo"
                />
                {/* <select
                  defaultValue={fetchedDetails?.death_country}
                  id="deathcountry"
                  onChange={handleChange}
                  name="deathcountry"
                  className="decInfo"
                >
                  <option value="">--Select a country--</option>
                  {countries?.map((country, index) => (
                    <option key={index} value={country.country}>
                      {country.country}
                    </option>
                  ))}
                </select> */}
              </div>
            </div>
          </div>

          <div className="form">
            <div>
              <div>
                <h5>Address of deceased</h5>
                <label htmlFor="decresidencenum">Residence Number</label>
                <input
                  //onChange={handleChange}
                  name="decresidencenum"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="deccity">City</label>
                <input
                  //onChange={handleChange}
                  name="deccity"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="decstate">State</label>
                <input
                  //onChange={handleChange}
                  name="decstate"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="deccountry">Country</label>
                <input type="text" name="deccountry" className="decInfo" />
                {/* <select
                  //onChange={handleChange}
                  name="deccountry"
                  className="decInfo"
                >
                  <option value="">--Select a country--</option>
                  {countries.map((country, key) => (
                    <option key={key} value={country.country}>
                      {country.country}
                    </option>
                  ))}
                </select> */}
              </div>

              <div>
                <label htmlFor="birthdate">Birthday of deceased</label>
                <input
                  min="1850-01-01"
                  max={
                    fetchedDetails?.date_of_death !== null &&
                    fetchedDetails?.date_of_death !== undefined &&
                    fetchedDetails?.date_of_death !== "0000-00-00"
                      ? format(
                          new Date(fetchedDetails?.date_of_death),
                          "yyyy-MM-dd"
                        )
                      : formValues?.deathdate
                      ? formValues?.deathdate
                      : ""
                  }
                  onChange={handleChange}
                  name="birthdate"
                  className="decInfo"
                  type="date"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="age">Age</label>
                <input
                  defaultValue={
                    (formValues?.birthdate && fetchedDetails?.date_of_death) ||
                    (formValues.dec_birthdate && formValues.deathdate)
                      ? differenceInYears(
                          new Date(
                            formValues.deathdate ?? fetchedDetails.date_of_death
                          ),
                          new Date(formValues.birthdate)
                        )
                      : ""
                  }
                  readOnly={true}
                  name="age"
                  //onChange={handleChange}
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="sex">Sex</label>
                <select
                  // defaultValue={fetchedDetails.}
                  //onChange={handleChange}
                  readOnly={true}
                  name="sex"
                  className="decInfo"
                >
                  <option value=""></option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div>
                <label htmlFor="fathername">Decedent's Father's Name</label>
                <input
                  //onChange={handleChange}
                  name="fathername"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="mothermaidenname">
                  Decedent's Mother's Maiden Name
                </label>
                <input
                  //onChange={handleChange}
                  name="mothermaidenname"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            className="button"
            onClick={(e) => {
              goToPage(e.target.parentElement, "page2");
            }}
          >
            Next
          </button>
        </div>
      </div>
      <div className="mainform-div" id="page2">
        <h2>Client Form</h2>
        <div id="form-div">
          <div className="form">
            <div>
              <h5>Place of Birth</h5>
              <div>
                <label htmlFor="birthcity">City</label>
                <input
                  //onChange={handleChange}
                  name="birthcity"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="birthstate">State</label>
                <input
                  //onChange={handleChange}
                  name="birthstate"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="birthcountry">Country</label>
                <input
                  //onChange={handleChange}
                  name="birthcountry"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="civ_status">Marital Status</label>
                <select
                  defaultValue={fetchedDetails?.death_marital}
                  onChange={(e) => {
                    e.target.value == "Married"
                      ? setForMarried(false)
                      : setForMarried(true);
                  }}
                  name="maritalstat"
                  className="decInfo"
                >
                  <option value=""></option>
                  <option value="Single">Single (never Married)</option>
                  <option value="Married">Married</option>
                  <option value="Married but separated">
                    Maried but separated
                  </option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widowed">Widowed</option>
                </select>
              </div>

              <h5>If married fill in the name of husband/wife (maiden name)</h5>
              <div>
                <label htmlFor="fnamehw">First Name</label>
                <input
                  //onChange={handleChange}
                  disabled={forMarried}
                  name="fnamehw"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="mnamehw">Middle Name</label>
                <input
                  //onChange={handleChange}
                  disabled={forMarried}
                  name="mnamehw"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="lnamehw">Last Name</label>
                <input
                  //onChange={handleChange}
                  disabled={forMarried}
                  name="lnamehw"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <h5>Deceased Occupation</h5>
              <div>
                <label htmlFor="occupation">Occupation</label>
                <input
                  //onChange={handleChange}
                  name="occupation"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="form">
            <div>
              <div>
                <label htmlFor="physician">Physician</label>
                <input
                  //onChange={handleChange}
                  name="physician"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="employer">Employer</label>
                <input
                  //onChange={handleChange}
                  name="employer"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="empaddress">Address</label>
                <input
                  //onChange={handleChange}
                  name="empaddress"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="veteran">Veteran</label>
                <select
                  name="veteran"
                  className="decInfo"
                  onChange={(e) => {
                    e.target.value == "Yes"
                      ? setForVeteran(false)
                      : setForVeteran(true);
                  }}
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div>
                <label htmlFor="ifveteran">If Veteran</label>
                <select
                  disabled={forVeteran}
                  name="ifveteran"
                  className="decInfo"
                  //onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="Army">Army</option>
                  <option value="Marine">Marine</option>
                  <option value="Navy">Navy</option>
                  <option value="Airforce">Airforce</option>
                </select>
              </div>
              <div>
                <label htmlFor="serialnum">Veteran's Serial Number</label>
                <input
                  //onChange={handleChange}
                  disabled={forVeteran}
                  name="serialnum"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="ssnum">Social Security/Account Number</label>
                <input
                  //onChange={handleChange}
                  name="ssnum"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="gradelevel">Grade level completed</label>
                <select
                  onChange={handleChange}
                  name="gradelevel"
                  className="decInfo"
                  type="text"
                >
                  {/* 8th Grade or less; none 9th-12th grade, no diploma High school
                  graduate or GED completed Some college credit, but no degree
                  Associate degree (e.g. AA, SS) Bachelor's degree (e.g. BA, AB,
                  bS) Master's degree (e.g. MA, MS, MEng, MEd, MSW, MBA)
                  Doctorate (e.g. PhD, EdD) or Professional degree (e.g. MD,
                  DDS, DBM, LLB, JD) Unknown */}
                  <option value="">Select</option>
                  <option value="8th Grade or less/ none">
                    8th Grade or less/ none
                  </option>
                  <option value="9th-12th grade">9th-12th grade</option>
                  <option value="No Diploma">No Diploma</option>
                  <option value="High school graduate or GED">
                    High school graduate or GED
                  </option>
                  <option
                    value="completed Some college credit, but no degree
                  Associate degree (e.g. AA, SS)"
                  >
                    completed Some college credit, but no degree Associate
                    degree (e.g. AA, SS)
                  </option>
                  <option value="Bachelor's degree (e.g. BA, AB, bS)">
                    Bachelor's degree (e.g. BA, AB, bS)
                  </option>
                  <option value="Master's degree (e.g. MA, MS, MEng, MEd, MSW, MBA)">
                    Master's degree (e.g. MA, MS, MEng, MEd, MSW, MBA)
                  </option>
                  <option value="Doctorate (e.g. PhD, EdD)">
                    Doctorate (e.g. PhD, EdD)
                  </option>
                  <option value="Professional degree (e.g. MD, DDS, DBM, LLB, JD)">
                    Professional degree (e.g. MD, DDS, DBM, LLB, JD)
                  </option>
                  <option value=" Unknown"> Unknown</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="page2buttonprev">
          <button
            className="button"
            onClick={(e) => {
              goToPage(e.target.parentElement, "page1");
            }}
            id="prev2"
          >
            Previous
          </button>
          <button
            className="button"
            onClick={(e) => {
              goToPage(e.target.parentElement, "page3");
            }}
            id="next2"
          >
            Next
          </button>
        </div>
      </div>
      <div className="mainform-div" id="page3">
        <h2>Client Form</h2>
        <div id="form-div">
          <div className="form">
            <div>
              <h5>About Informant</h5>
              <div>
                <label htmlFor="inffirstname">First name</label>
                <input
                  defaultValue={fetchedDetails?.firstname}
                  //onChange={handleChange}
                  name="inffirstname"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              {/* <div>
                <label htmlFor="infmiddlename">Middle name</label>
                <input
                  defaultValue={fetchedDetails?.middlename}
                  //onChange={handleChange}
                  name="infmiddlename"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="inflastname">Last name</label>
                <input
                  defaultValue={fetchedDetails?.lastname}
                  //onChange={handleChange}
                  name="inflastname"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div> */}
              <div>
                <label htmlFor="relationship">Relationship</label>
                <input
                  defaultValue={fetchedDetails?.intake_relationship}
                  //onChange={handleChange}
                  name="relationship"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="address">Address</label>
                <input
                  //onChange={handleChange}
                  defaultValue={fetchedDetails?.nok_address}
                  name="infaddress"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="Phone">Phone</label>
                <input
                  defaultValue={fetchedDetails?.intake_telephone}
                  //onChange={handleChange}
                  name="phone"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="Phone">Email</label>
                <input
                  defaultValue={fetchedDetails?.intake_email}
                  //onChange={handleChange}
                  name="email"
                  className="decInfo"
                  type="email"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="wakedate">Date of Wake/Viewing</label>
                <input
                  //onChange={handleChange}
                  defaultValue={
                    fetchedDetails?.viewing_date &&
                    isValid(new Date(fetchedDetails.viewing_date))
                      ? format(
                          new Date(
                            fetchedDetails.viewing_date.substring(0, 10)
                          ),
                          "yyyy-MM-dd"
                        )
                      : ""
                  }
                  name="wakedate"
                  className="decInfo"
                  type="date"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="wakeplace">Starting time of Wake/Viewing</label>
                <input
                  //onChange={handleChange}
                  defaultValue={fetchedDetails?.viewing_time ?? ""}
                  name="waketime"
                  className="decInfo"
                  type="time"
                  placeholder=""
                />
                <label htmlFor="wakeplace">End time of Wake/Viewing</label>
                <input
                  //onChange={handleChange}
                  defaultValue={fetchedDetails?.viewing_endtime ?? ""}
                  name="waketimeend"
                  className="decInfo"
                  type="time"
                  placeholder=""
                />
              </div>

              <div>
                <label htmlFor="wakeplace">Place of Wake/Viewing</label>
                <input
                  //onChange={handleChange}
                  defaultValue={fetchedDetails?.viewing_location ?? ""}
                  name="wakeplace"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="funeraldate">Date of Funeral</label>
                <input
                  //onChange={handleChange}
                  defaultValue={
                    fetchedDetails.service_date &&
                    isValid(new Date(fetchedDetails.service_date))
                      ? format(
                          new Date(
                            fetchedDetails.service_date.substring(0, 10)
                          ),
                          "yyyy-MM-dd"
                        )
                      : ""
                  }
                  name="funeraldate"
                  className="decInfo"
                  type="date"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="form">
            <div>
              <div>
                <label htmlFor="wakeplace">Starting time of Funeral</label>
                <input
                  //onChange={handleChange}
                  defaultValue={fetchedDetails?.service_time ?? ""}
                  name="funeraltime"
                  className="decInfo"
                  type="time"
                  placeholder=""
                />
                <label htmlFor="wakeplace">End time of Funeral</label>
                <input
                  //onChange={handleChange}
                  defaultValue={fetchedDetails?.service_endtime ?? ""}
                  name="funeraltimeend"
                  className="decInfo"
                  type="time"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="funeralplace">Place of Funeral</label>
                <input
                  //onChange={handleChange}
                  defaultValue={fetchedDetails?.service_location ?? ""}
                  name="funeralplace"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="cemetery">Cemetery</label>
                <input
                  //onChange={handleChange}
                  defaultValue={fetchedDetails?.cemetary_name ?? ""}
                  name="cemetery"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="gravenum">Grave number</label>
                <input
                  //onChange={handleChange}
                  name="gravenum"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="rownum">Row number</label>
                <input
                  //onChange={handleChange}
                  name="rownum"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="rangenum">Range number</label>
                <input
                  //onChange={handleChange}
                  name="rangenum"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="sectionnum">Section number</label>
                <input
                  //onChange={handleChange}
                  name="sectionnum"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="lotowner">Lot Owner</label>
                <input
                  //onChange={handleChange}
                  name="lotowner"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="permitnum">Permit number</label>
                <input
                  //onChange={handleChange}
                  name="permitnum"
                  className="decInfo"
                  type="text"
                  placeholder=""
                />
              </div>
              <div>
                <label htmlFor="disposition">Type of Disposition</label>
                <select
                  //onChange={handleChange}
                  defaultValue={fetchedDetails?.type_of_disposition}
                  name="disposition"
                  className="decInfo"
                  type="text"
                  placeholder=""
                >
                  <option value="">Select</option>
                  <option value="Cremate">Cremate</option>
                  <option value="Burial">Burial</option>
                  <option value="Entombment">Entombment</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div id="page3buttonprev">
          <button
            className="button"
            onClick={(e) => {
              goToPage(e.target.parentElement, "page2");
            }}
            id="prev3"
          >
            Previous
          </button>
          <button className="submitt" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
      {/* <div id="buttonclose-div" className="close">
        <button
          onClick={(e) => {
            // e.target.parentElement("newcase-div").style.display = "none";
            e.target.parentElement.parentElement.parentElement.style.display =
              "none";
          }}
        >
          Close
        </button>
      </div> */}
      {/* <div>
        <input type="file" accept="image/*" onChange={handleImageChange} />
      </div> */}
    </FormStyle>
  ) : (
    <FormStyle>
      <div id="wait-modal">
        <div id="loading">
          <img
            id="gif"
            src="https://media.tenor.com/5o2p0tH5LFQAAAAi/hug.gif"
          />
        </div>
        <h5 id="sending">Getting info. Please wait</h5>
      </div>
    </FormStyle>
  );
};

export default ClientPage;
