import { useParams } from "react-router-dom";
import { ContractStyle } from "../styles/Contract.styled";
//import contractdata from "./contractprices.json";
import { useEffect, useState } from "react";
import axios from "axios";

export default function Contract(props) {
  console.log(props.contractservice);
  let { contractservicecode } = useParams();
  let contractservice = contractservicecode
    ? contractservicecode
    : props.contractservice;

  const [contractdata, setContractData] = useState([]);
  const [newPrices, setNewPrices] = useState({});
  const [changingVal, setChangingVal] = useState();
  const [inpVal, setInpVal] = useState();
  const [totalofhomefuneralcharges, setTotalOfFuneralHomeCharges] =
    useState(null);
  const [estimatedtotalofcashadvances, setEstimatedTotalOfCashAdvaces] =
    useState(null);
  const [totalfuneralcharges, setTotalFuneralCharges] = useState(null);
  const [matched, setMatched] = useState();
  //console.log(contractdata);

  useEffect(() => {
    async function getPrice() {
      await axios
        .get("https://api5.teamfdjfs.com/prices/getAllPrices")
        .then((res) => {
          console.log(res.data);
          setContractData(res.data);
          //props.setCurrentPrices(res.data);
        });
    }
    getPrice();
    if (!props.fromProps) {
      getPrice();
    } else {
      setContractData(props.contractdata);
    }
  }, [contractservice]);

  useEffect(() => {
    if (contractdata.length > 0) {
      let matchedcontract = contractdata?.filter((contract) => {
        if (contract.service === contractservice) {
          return contract;
        }
      });
      if (matchedcontract[0]?.service === String(contractservice)) {
        // document.querySelectorAll("input").forEach((element) => {
        //   if (element.id && matched[element.id]) {
        //     element.value = matched[element.id];
        //   }
        // });

        let tofhc = 0;
        let etoca = 0;
        delete matchedcontract[0].id;
        setNewPrices(matchedcontract[0]);
        delete matchedcontract[0].service;

        Object.values(matchedcontract[0]).forEach((price, key) => {
          if (key < 35 && key != 0) {
            if (price) tofhc += price;
          }
          if (key >= 36 && key <= 47) {
            if (price) etoca += price;
          }
        });

        setMatched(matchedcontract[0]);
        setTotalOfFuneralHomeCharges(tofhc);
        setEstimatedTotalOfCashAdvaces(etoca);
        setTotalFuneralCharges(etoca + tofhc);
      }
    }
  }, [contractdata]);

  const newPriceFunc = (e) => {
    // setInpVal(e.target);
    // setChangingVal(e.target.value);
    setNewPrices({ ...newPrices, [e.target.id]: Number(e.target.value) });
  };

  async function saveNewPrice() {
    let tofhc = 0;
    let etoca = 0;

    let keys = Object.keys(newPrices);

    Object.values(newPrices).forEach((price, key) => {
      if (
        keys[key] !== "TOTALFUNERALCHARGES" &&
        keys[key] !== "TOTALOFFUNERALHOMECHARGES" &&
        keys[key] !== "ESTIMATEDTOTALOFCASHADVANCES" &&
        keys[key] !== "service" &&
        keys[key] !== "id"
      ) {
        if (key < 35 && key != 0) {
          if (price) tofhc += price;
        }
        if (key >= 36 && key <= 47) {
          if (price) etoca += price;
        }
      }
    });

    console.log(tofhc, etoca);

    let newPriceWithTotal = {
      ...newPrices,
      TOTALFUNERALCHARGES: etoca + tofhc,
      TOTALOFFUNERALHOMECHARGES: tofhc,
      ESTIMATEDTOTALOFCASHADVANCES: etoca,
    };
    console.log(newPriceWithTotal);
    await axios
      .post("https://api5.teamfdjfs.com/prices/setNewPrice", {
        newPriceWithTotal,
        contractservice,
      })
      .then((res) => {
        alert("Success");
        //window.location.reload();
      })
      .catch((err) => {
        alert("Error");
      });
  }

  //for debounce
  useEffect(() => {
    if (inpVal) {
      const timer = setTimeout(() => {
        setNewPrices({ ...newPrices, [inpVal.id]: Number(inpVal.value) });
      }, 200);
      return () => {
        clearTimeout(timer);
        //setInpVal();
      };
    }
  }, [changingVal]);

  return (
    <ContractStyle>
      <div className="btn-container">
        {props.contractservice && (
          <>
            <button className="btn" onClick={saveNewPrice}>
              Save
            </button>
          </>
        )}
      </div>

      <div className="container">
        <div className="top">
          <h2>{matched?.name}</h2>
        </div>
        <div className="content">
          <div className="left">
            <div className="title">
              <h4>FRANTZ DANIEL JEAN</h4>
              <h4>FRANTZ DANIEL JEAN Funeral Services, Inc.</h4>
              <h4>5020 Foster Avenue,</h4>
              <h4>Brooklyn, New York 11203</h4>
              <h4>(718) 613-0228</h4>
            </div>
            <div className="name">
              <div className="date">
                <div className="dateee">
                  <p>Date:</p>
                  <input type="text" />
                </div>
                <div className="dateee">
                  <p>number:</p>
                  <input type="text" />
                </div>
              </div>
            </div>
            <div className="namee">
              <div className="date">
                <div className="dateee">
                  <p>Name of Deceased:</p>
                  <input id="decname" type="text" />
                </div>
                <div className="dateee">
                  <p>Date Of Death:</p>
                  <input id="deathdate" type="text" />
                </div>
                <div className="dateee">
                  <p>Place Of Death:</p>
                  <input id="deathplace" type="text" />
                </div>
              </div>
            </div>
            <div className="nameee">
              <p>
                ITEMIZATION OF FUNERAL SERVICES AND MERCHANDISE SELECTED The
                following are the charges for the services, merchandise, and
                livery you have selected. You will not be charged for any item
                you do not choose unless it is necessary because of other
                selections you have made. Any such charges are explained below.
              </p>
            </div>
            <div className="one">
              <div className="text">
                <p>I. FUNERAL HOME CHARGES</p>
                <p>
                  (Indicate N/A for items ofservice and/or merchandise that are
                  not provided.)
                </p>
                <p>A. Alternative Services</p>
                <div className="price">
                  <p>
                    1. Direct Cremation ...................................$
                  </p>
                  <input
                    id="directcremation"
                    className="input"
                    type="number"
                    disabled={props.fromProps ? false : true}
                    onChange={(e) => newPriceFunc(e)}
                    defaultValue={matched?.direccremation}
                  />
                </div>
                <div className="price">
                  <p>
                    2. Direct Burial.......................................$
                  </p>
                  <input
                    id="directburial"
                    className="input"
                    type="number"
                    disabled={props.fromProps ? false : true}
                    onChange={(e) => newPriceFunc(e)}
                    defaultValue={matched?.directburial}
                  />
                </div>
                <div className="price">
                  <p>
                    B. Transfer of remains to the funeral establishment
                    including personnel, equipment and vehicle
                    ...........................$
                    <input
                      id="transferofremains"
                      className="input"
                      type="number"
                      disabled={props.fromProps ? false : true}
                      onChange={(e) => newPriceFunc(e)}
                      defaultValue={matched?.transferofremains}
                    />
                  </p>
                </div>
                <p>C. Preparation of Remains</p>
                <div className="price">
                  <p>
                    1. Embalming (including use of preparation room) $
                    <input
                      id="embalming"
                      className="input"
                      type="number"
                      disabled={props.fromProps ? false : true}
                      onChange={(e) => newPriceFunc(e)}
                      defaultValue={matched?.embalming}
                    />
                  </p>
                </div>
                <p className="textt">
                  If you select a funeral for which this firm requires embalming
                  such as a funeral with viewing, you may have to pay for
                  embalming. You do not have to pay for embalming you do not
                  approve if you select arrangements such as direct cremation or
                  direct burial. If we charge for embalming, we will explain why
                  below
                </p>
                <p className="textt">
                  2. Other Preparation (including use of preparation room but
                  excluding embalming)
                </p>
                <div className="price">
                  <p>
                    a. Topical Disinfection ..............$
                    <input
                      id="topicaldisinfection"
                      className="input"
                      type="number"
                      disabled={props.fromProps ? false : true}
                      onChange={(e) => newPriceFunc(e)}
                      defaultValue={matched?.topicaldisinfection}
                    />
                  </p>
                </div>
                <div className="price">
                  <p>
                    b. Custodial Care .....................$
                    <input
                      id="custodialcare"
                      className="input"
                      type="number"
                      disabled={props.fromProps ? false : true}
                      defaultValue={matched?.custodialcare}
                    />
                  </p>
                </div>
                <div className="price">
                  <p>
                    c. Dressing/Casketing..................$
                    <input
                      id="dressingcasketing"
                      className="input"
                      type="number"
                      disabled={props.fromProps ? false : true}
                      onChange={(e) => newPriceFunc(e)}
                      defaultValue={matched?.dressingcasketing}
                    />
                  </p>
                </div>
                <div className="price">
                  <p>
                    d. Cosmetology ........................$
                    <input
                      id="cosmetology"
                      className="input"
                      type="number"
                      disabled={props.fromProps ? false : true}
                      onChange={(e) => newPriceFunc(e)}
                      defaultValue={matched?.cosmetology}
                    />
                  </p>
                </div>
                <div className="price">
                  <p>
                    e. Restoration. ........................$
                    <input
                      id="restoration"
                      className="input"
                      type="number"
                      disabled={props.fromProps ? false : true}
                      onChange={(e) => newPriceFunc(e)}
                      defaultValue={matched?.restoration}
                    />
                  </p>
                </div>
                <div className="price">
                  <p>
                    f. Other (specify)......................$
                    <input
                      id="other"
                      className="input"
                      type="number"
                      disabled={props.fromProps ? false : true}
                      onChange={(e) => newPriceFunc(e)}
                      defaultValue={matched?.other}
                    />
                  </p>
                </div>

                <p className="textt">D. Arrangement</p>
                <p className="textt">
                  Basic arrangements: including funeral director, other staff,
                  equipment and facilities to respond to initial request for
                  service, the arrangement conference, securing of
                  necessaryauthorizations and coordination ofservice plans with
                  parties involved in the final disposition ofthe
                  deceased......$
                  <input
                    id="arrangement"
                    className="input"
                    type="number"
                    disabled={props.fromProps ? false : true}
                    onChange={(e) => newPriceFunc(e)}
                    defaultValue={matched?.arrangement}
                  />
                </p>
                <p className="textt">
                  E. Supervision (funeral director and staff).................$
                  <input
                    id="seupervision"
                    className="input"
                    type="number"
                    disabled={props.fromProps ? false : true}
                    onChange={(e) => newPriceFunc(e)}
                    defaultValue={matched?.seupervision}
                  />
                </p>
                <p>
                  1. Supervision for visitation .......................$
                  <input
                    id="supervisionvisitation"
                    className="input"
                    type="number"
                    disabled={props.fromProps ? false : true}
                    defaultValue={matched?.supervisionvisitation}
                    onChange={(e) => newPriceFunc(e)}
                  />
                </p>
                <p>
                  2. Supervision for funeral service....................$
                  <input
                    id="supervisionfuneralservice"
                    className="input"
                    type="number"
                    disabled={props.fromProps ? false : true}
                    defaultValue={matched?.supervisionfuneralservice}
                    onChange={(e) => newPriceFunc(e)}
                  />
                </p>
                <p>
                  3. Other supervision (specify) Memorial
                  Service.....................$
                  <input
                    id="othersupervision"
                    className="input"
                    type="number"
                    disabled={props.fromProps ? false : true}
                    defaultValue={matched?.othersupervision}
                    onChange={(e) => newPriceFunc(e)}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="right">
            <p className="textt"></p>
            <p>F. Use of the facilities</p>
            <p>
              1. Use of the facilities for visitation .......................$
              <input
                id="facilitiesforvisitation"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.facilitiesforvisitation}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              2. Use of facilities for funeral service....................$
              <input
                id="facilitiesforfuneralservice"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.facilitiesforfuneralservice}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              3. Other use of facilities (specify) ....................$
              <input
                id="otherfacilities"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.otherfacilities}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p className="textt"></p>
            <p>G. Livery</p>
            <p>
              1. a. Hearse or....................................$
              <input
                id="hearse"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.hearse}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              b. Alternative vehicle...........................$
              <input
                id="alternativevehicle"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.alternativevehicle}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              Specify type:
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p className="textt">
              2. Flower vehicle...................$
              <input
                id="flowervehicle"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.flowervehicle}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p className="textt">
              3. Limousine(s).....................................$
              <input
                id="limousine"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.limousine}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              (Specify number:
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />{" "}
              @ $
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />{" "}
              /limousine)
            </p>
            <p className="textt">
              4. Passenger car(s)....................$
              <input
                id="passengercar"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.passengercar}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              (Specify number:
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />{" "}
              @ $
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />{" "}
              /car)
            </p>
            <p className="textt">H. Merchandise</p>
            <p>
              2. 1. Casket or alternative container .........$
              <input
                id="casketoralternativecontainer"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.casketoralternativecontainer}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              a. Supplier{" "}
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p>
              b. Model name or number
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p>
              c. Material: Species of wood
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p>
              or kind of metal <input id="" className="input" type="text" />
              weight or gauge <input id="" className="input" type="text" /> or
              alternative container (describe)
              <input id="" className="input" type="text" />
            </p>
            <p>
              d. Interior
              <input id="" className="input" type="text" />
            </p>
            <p className="textt">
              2. Outer Interment Receptacle........................$
              <input
                id="outerintermentrecceptacle"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.outerintermentrecceptacle}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              a. Supplier
              <input id="" className="input" type="text" />
            </p>
            <p>
              b. Model name or number
              <input id="" className="input" type="text" />
            </p>
            <p>
              c. Material
              <input id="" className="input" type="text" />
            </p>
            <p className="textt">
              I. Additional Services and Merchandise Selected (Describe and show
              price)
            </p>
            <p>
              1. Memorial Cards..............100 cards................$
              <input
                id="memorialcards"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.memorialcards}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              2. Acknowledgement Cards...................................$
              <input
                id="acknowledgementcards"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.acknowledgementcards}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              3. Casket Plate....................................$
              <input
                id="casketplate"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.casketplate}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              4. Crucifix/Cross.............................$
              <input
                id="cricifixcross"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.cricifixcross}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              6. Flowers ..........1 casket piece.................$
              <input
                id="flowers"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.flowers}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              7. Clothing or Burial
              Garments.....................................$
              <input
                id="clothingorburialgarments"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.clothingorburialgarments}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              8. Register Book.....................................$
              <input
                id="registerbook"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.registerbook}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              9. Death Notices..................................$
              <input
                id="deathnotices"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.deathnotices}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              10........................................$
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p>
              11........................................$
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p>
              12........................................$
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p className="textt">J. Limited Services</p>
            <p>
              1. Forwarding remains to
              <input
                id="forwardingremainsto"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.forwardingremainsto}
                onChange={(e) => newPriceFunc(e)}
              />
              ${" "}
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p>
              2. Receiving remains from
              <input
                id="receivingremainsfrom"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.receivingremainsfrom}
                onChange={(e) => newPriceFunc(e)}
              />
              ${" "}
              <input
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>

            <h3 className="textt">
              TOTAL OF FUNERAL HOME CHARGES:
              <input
                id="TOTALOFFUNERALHOMECHARGES"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={totalofhomefuneralcharges}
                readOnly={true}
              />
            </h3>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="content">
          <div className="right">
            <p>II. CASH ADVANCES</p>
            <p className="textt">
              These are estimated charges for items to be paid to others. We
              STATEMENT OF GOODS AND SERVICES SELECTED INVOICE TO will charge
              you no more for these items than is actually paid the third
              parties. (Describe and show estimated charges.)
            </p>
            <p className="textt">
              1. Cemetery or Crematory ...............................$
              <input
                id="cemeteryorcrematory"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.cemeteryorcrematory}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              2. Clergy Honoraria.....................................$
              <input
                id="clergyhonoraria"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.clergyhonoraria}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              3. Death Certificate Transcripts.................2 DC....$
              <input
                id="deathcetificatetranscripts"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.deathcetificatetranscripts}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              4. Livery.................................$
              <input
                id="livery"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.livery}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              5. Pallbearers.............................$
              <input
                id="pallbearers"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.pallbearers}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              6. Public Transportation. ...........................$
              <input
                id="publictransportation"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.publictransportation}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              7. Gratuities................................$
              <input
                id="gratuities"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.gratuities}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              8. Bridge & Road Tolls .................................$
              <input
                id="bridgeroadtolls"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.bridgeroadtolls}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            /
            <p>
              9. Telephone & Telegraph Charges............................$
              <input
                id="telephoneteleraphcharges"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.telephoneteleraphcharges}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              10. Burial Permit................................$
              <input
                id="burialpermit"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.burialpermit}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p>
              11. Delivery Fee.................................$
              <input
                id="deliveryfee"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.deliveryfee}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p className="textt">
              ESTIMATED TOTAL OF CASH ADVANCES............$
              <input
                id="ESTIMATEDTOTALOFCASHADVANCES"
                readOnly={true}
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={estimatedtotalofcashadvances}
              />
            </p>
            <p>II. SUMMARY OF CHARGES</p>
            <p>
              1. Funeral Home Charges.............................$
              <input
                id="TOTALOFFUNERALHOMECHARGES"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                readOnly={true}
                defaultValue={totalofhomefuneralcharges}
              />
            </p>
            <p>
              2. Cash Advances..................................$
              <input
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                readOnly={true}
                defaultValue={estimatedtotalofcashadvances}
              />
            </p>
            <h4 className="textt">
              TOTAL FUNERAL CHARGES..............................$
              <input
                id="TOTALFUNERALCHARGES"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={totalfuneralcharges}
                readOnly={true}
              />
            </h4>
            <p className="textt">
              IV. EXPLANATION OF CHARGES Explain charges for embalming and for
              any items that are not required by law but may be necessary
              because of cemetery requirements, crematory requirements or other
              selections made
            </p>
            <p className="textt">
              Combined charge for Facilities and Staff for visitation
              is......................$
              <input
                id="combinedchargevisitation"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.combinedchargevisitation}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p className="textt">
              Combined charge for Facilities and Staff for funeral service
              is.....................$
              <input
                id="combinedchargefunerlservice"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.combinedchargefunerlservice}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <p className="textt">
              Combined charge for other Facilities and Staff
              (specify).............$
              <input
                id="combinedchargespecify"
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
                defaultValue={matched?.combinedchargespecify}
                onChange={(e) => newPriceFunc(e)}
              />
            </p>
            <div className="esign">
              <div className="sig">
                <input
                  id=""
                  type="number"
                  disabled={props.fromProps ? false : true}
                />
                <p>Signature of Licensed Funeral Director</p>
              </div>
              <div className="datee">
                <input
                  id=""
                  type="number"
                  disabled={props.fromProps ? false : true}
                />
                <p>Date</p>
              </div>
            </div>
            <div className="esign">
              <div className="sig">
                <input
                  id=""
                  type="number"
                  disabled={props.fromProps ? false : true}
                />
                <p>Printed or Typed Name of Funeral Director</p>
              </div>
            </div>
            <p>ACKNOWLEDGEMENT OF RECEIPT</p>
            <p>
              I have received this itemization of funeral services and
              merchandise selected.
            </p>
            <div className="esign">
              <div className="sig">
                <input
                  id=""
                  type="number"
                  disabled={props.fromProps ? false : true}
                />
                <p>Signature</p>
              </div>
              <div className="datee">
                <input
                  id=""
                  type="number"
                  disabled={props.fromProps ? false : true}
                />
                <p>Date</p>
              </div>
            </div>
            <p className="textt">
              PUBLIC NOTICE The New York State Department of Health is
              responsible for licensing and regulating New York State funeral
              directing under the Public Health Law
            </p>
            <p className="textt">You may contact the Department at:</p>
            <p>
              Bureau of Funeral Directing, New York State Department of Health
              875 Central Avenue, Albany, New York 12206
            </p>
            <div className="box">
              <p>
                EXCLUSION OF WARRANTY. The only warranties, express or implied,
                granted in connection with the goods sold with this funeral
                service are the express written warranties, if any, extended by
                the manufacturers thereof. No other warranties and no warranties
                of merchant ability or fitness for a particular purpose are
                extended by the funeral director
              </p>
            </div>
          </div>

          <div className="right">
            <h3>STATEMENT OF GOODS AND SERVICES SELECTED</h3>
            <p>INVOICE TO</p>

            <div className="box">
              <p>
                The undersigned hereby authorizes the above funeral
                establishment or its representatives to obtain custody of the
                remains of
              </p>
              <p className="textt">
                Initial and state your relation to deceased
                <input type="checkbox" />
              </p>
            </div>

            <div className="box">
              <p>
                The undersigned hereby authorizes the above funeral
                establishment or its representatives to obtain custody of the
                remains of
              </p>
              <p className="textt">
                Initial and state your relation to deceased
                <input type="checkbox" />
              </p>
              <p>
                Other Authorization by
                <input
                  id=""
                  className="input"
                  type="number"
                  disabled={props.fromProps ? false : true}
                />
              </p>
            </div>

            <p className="textt">
              "Charges are only for those items that are used. If we are
              required by law to use any items, we will explain the reasons in
              writing below."
            </p>

            <p className="textt">
              TOTAL FUNERAL CHARGES............................... $
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>

            <p>
              Date{" "}
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>

            <p className="textt">
              The foregoing statement has been read by (to) me and I hereby
              acknowledge receipt of a copy of same and agree to pay the above
              funeral account and for such additional services and materials as
              are ordered by me, on or before ....................In the event
              that this account is not paid in accordance with the terms of this
              agreement, the undersigned hereby agrees to pay any and all costs
              and attorney's fees incurred in connection with the collection of
              this account
            </p>

            <div className="box">
              <p>
                Prior to the discussion of these funeral arrangements, I was
                presented with a copy of this funeral firm's "General Price
                List" for which I hereby acknowledge receipt, and have had an
                opportunity to review the firm's Casket Price List and Outer
                Interment Receptacle Price List.
              </p>
            </div>
            <p className="textt">
              TERMS: This account becomes due
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />{" "}
              .If bill remains unpaid beyond{" "}
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />{" "}
              a late charge of{" "}
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />{" "}
              per month (annual rate{" "}
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
              %) may be added to the unpaid portion of the balance due.
            </p>

            <p className="textt">
              The liability hereby assumed is in addition to the liability
              imposed by law upon the estate and others, and shall not
              constitute a release thereof
            </p>
            <p className="textt">
              Signature{" "}
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p className="textt">
              Relation to Deceased
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p className="textt">
              Signature{" "}
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p className="textt">
              Relation to Deceased
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p className="textt">
              by:{" "}
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>

            <div className="esig">
              <input
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
              <p>Print Name of Licensed Funeral Director</p>
            </div>
            <p className="textt">
              ADDITIONS OR ALTERATIONS OF SERVICES AND MERCHANDISE SELECTED. The
              following changes represent items of service and/or merchandise
              ordered or altered subsequent to the original funeral agreement.
            </p>
            <p className="textt">AUTHORIZATION INITIAL</p>
            <p>
              <input id="" className="input" type="checkbox" />
              ........................$
              <input
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p>
              <input id="" className="input" type="checkbox" />
              ........................$
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p>
              Total Adjustments to Funeral Charges.................$
              <input
                readOnly={true}
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>

            <p className="textt">ADJUSTED TOTAL</p>
            <p className="textt">
              Credit........................ $
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
            <p className="textt">
              BALANCE DUE........................ $
              <input
                id=""
                className="input"
                type="number"
                disabled={props.fromProps ? false : true}
              />
            </p>
          </div>
        </div>
      </div>
    </ContractStyle>
  );
}
