import { useEffect, useState } from "react";
import { PrimaryIntakeContainer } from "../../styles/ProfilePrimaryIntake.styled";
import { useParams } from "react-router-dom";
import axios from "axios";
import { submitFunc, getInfo } from "./MemorialCard";

const LifeInsurance = ({ setShowForms2, setLifeInsurance }) => {
  let { clientId } = useParams();
  clientId = Number(clientId);
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState();

  useEffect(() => {
    getInfo(
      "https://api5.teamfdjfs.com/standby/saveData",
      ".insurance",
      clientId,
      "lifeinsurance"
    );
  }, []);

  return (
    <PrimaryIntakeContainer id="addclient">
      <form>
        <h3>Life Insurance</h3>
        <div className="intake__input">
          <label>Life Insurance Status</label>
          <select
            className="insurance"
            htmlFor="lifeinsurancestatus"
            defaultValue={status}
            //onChange={(e) => setAssignmentSelect(e.target.value)}
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          {/* <input
              className="lifeinsurancestatus"
              type="text"
              required
              defaultValue={status}
              //onChange={(e) => setStatus(e.target.value)}
            /> */}
        </div>

        <div className="intake__input">
          <select className="insurance">
            <option value="">Assignment Select</option>
            <option value="Assignment Form"> Assignment Form</option>
            <option value="Assignment Form Multiple Beneficiaries">
              Assignment Form Multiple Beneficiaries
            </option>
          </select>
          <input className="insurance" type="text" placeholder="Insert link" />
        </div>

        <div className="intake__input">
          <label>Claim Form</label>
          <input
            className="insurance"
            type="text"
            placeholder="Insert link and label"
          />
        </div>

        <div className="intake__input">
          <label>Decedent/Insured's Name</label>
          <input className="insurance" type="text" />
        </div>
        <div className="intake__input">
          <label>Insurance Name</label>
          <input className="insurance" type="text" />
        </div>

        <div className="intake__input">
          <label>Telephone</label>
          <input className="insurance" type="text" />
        </div>

        <div className="intake__input">
          <label>Policy Number</label>
          <input className="insurance" type="text" />
        </div>

        <div className="intake__input">
          <label>SSN</label>
          <input className="insurance" type="text" />
        </div>

        <div className="intake__input">
          <label>Claim Number</label>
          <input className="insurance" type="text" />
        </div>

        <div className="intake__input">
          <label>Beneficiary/Beneficiaries</label>
          <input className="insurance" type="text" />
        </div>

        <div className="intake__input">
          <label> Contacted Life Insurance</label>
          <select className="insurance">
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div className="intake__input">
          <label>Date of Contact</label>
          <input className="insurance" type="date" />
        </div>
        <h4>Document requested by Life Insurance</h4>
        <div className="intake__input">
          <label>Assignment Form: </label>
          <select className="insurance">
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <label>Type</label>
          <select className="insurance">
            <option value="">Select</option>
            <option value="Original">Original</option>
            <option value="Copies">Copies</option>
          </select>
        </div>

        <div className="intake__input">
          <label>Claim Form: </label>
          <select className="insurance">
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <label>Type</label>
          <select className="insurance">
            <option value="">Select</option>
            <option value="Original">Original</option>
            <option value="Copies">Copies</option>
          </select>
        </div>

        <div className="intake__input">
          <label>Death Certificate: </label>
          <select className="insurance">
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <label>Type</label>
          <select className="insurance">
            <option value="">Select</option>
            <option value="Original">Original</option>
            <option value="Copies">Copies</option>
          </select>
        </div>

        <div className="intake__input">
          <label>Email, Fax, and Mailing info</label>
          <input className="insurance" type="text" />
        </div>

        <div className="intake__input">
          <label>Date documents were sent</label>
          <input className="insurance" type="date" />
        </div>

        <div className="intake__input">
          <label>Date payment was received</label>
          <input className="insurance" type="date" />
        </div>

        <div className="intake__input">
          <label>How should be sent</label>
          <select className="insurance">
            <option value="">Select</option>
            <option value="Mail">Mail</option>
            <option value="Fax">Fax</option>
            <option value="Email">Email</option>
            <option value="Mail and Fax only">Mail and Fax only</option>
            <option value="Mail, Fax, Email">Mail, Fax, Email</option>
          </select>
        </div>
      </form>
      <div className="intake__btnn">
        <button
          onClick={() => {
            submitFunc(
              "https://api5.teamfdjfs.com/standby/saveData",
              ".insurance",
              clientId,
              "lifeinsurance"
            );
          }}
        >
          Save
        </button>

        <button
          type="button"
          onClick={() => {
            setShowForms2(false);
            setLifeInsurance(false);
          }}
        >
          Exit Window
        </button>
      </div>
    </PrimaryIntakeContainer>
  );
};

export default LifeInsurance;
