import { Routes, Route, Navigate } from "react-router-dom";
import { RequireAuth } from "react-auth-kit";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import ProfilePage from "./pages/ProfilePage";
import ClientPage from "./pages/ClientPage";
import ClientFilled from "./pages/ClientFilled";
import ClientDisplay from "./components/HomePage/ClientDisplay";
import Redirect from "./pages/Redirect";
import Verify from "./pages/Verify";
import UserPage from "./pages/UserPage";
import ContractPage from "./pages/Contract";
import NotFoundPage from "./pages/NotFoundPage";
import EditContract from "./pages/EditContract";
import Itenerary from "./components/UserPage/Itenerary";
import DailyTracker from "./components/UserPage/DailyTracker";
import UserCalendar from "./components/UserPage/UserCalendar";
import TaskCalendar from "./components/UserPage/TaskCalendar";
import UserProfile from "./components/UserPage/UserProfile";
import MobileDashboard from "mobile/pages/dashboard";
import { useEffect } from "react";
import MobileLayout from "mobile/layout";
const App = () => {
  useEffect(() => {
    console.log(window.innerWidth);
  }, [window.innerWidth]);

  return (
    <>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        {/* <Route path="/login" element={<LoginPage />} /> */}
        <Route path="/client/verify" element={<Verify />} />
        <Route path="/client/fillup/:clientId/*" element={<ClientPage />} />
        <Route path="/client/filled/submit/*" element={<ClientFilled />} />

        <Route
          path="/profile/redirect/:totaskid/:clientId"
          element={
            <RequireAuth loginPath="/">
              <ProfilePage />
            </RequireAuth>
          }
        />
        <Route
          path="/profile/:clientId"
          element={
            <RequireAuth loginPath="/">
              <ProfilePage />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/user/:userId"
          element={
            <RequireAuth loginPath="/">
              <UserPage />
            </RequireAuth>
          }
        >
          <Route path="" element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard" element={<HomePage />} />
          <Route path="client" element={<ClientDisplay />} />
          <Route path="contract" element={<EditContract />} />
          <Route path="itenerary" element={<Itenerary />} />
          <Route path="daily" element={<DailyTracker />} />
          <Route path="event" element={<UserCalendar />} />
          <Route path="task" element={<TaskCalendar />} />
          <Route path="profile" element={<UserProfile />} />
        </Route>
        <Route
          exact
          path="/contracts/:contractservicecode"
          element={<ContractPage />}
        />
        <Route path="/goto/:clientid/:taskid/:setTask" element={<Redirect />} />
        <Route path="m" element={<MobileLayout />}>
          <Route path="dashboard" element={<MobileDashboard />}></Route>
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default App;
