import styled from "styled-components";

export const ClientfilledStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  #signaturediv {
    display: none;
    position: absolute;
    top: 0;
    //transform: translate(-50%, -50%);
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .signCanvas {
      background-color: white;
    }
  }
  .signa {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 45%;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    p {
      position: absolute;
      top: 0;
      margin-top: 20px;
    }
    canvas {
      width: 100%;
      height: 100%;
      border: 1px solid grey;
    }
    button {
      position: absolute;
      padding: 5px;
      border: none;
      border-radius: 5px;
      background-color: var(--theme-color);
      color: #fff;
      font-size: 15px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #fff;
        color: var(--theme-color);
        border: 1px solid var(--theme-color);
      }
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      margin: 8px;
    }
    .clear {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-left: 10px;
      margin-bottom: 10px;
    }
    .save {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-left: 70px;
      margin-bottom: 10px;
    }
  }
  #genform {
    width: 100%;
    height: 100vh;
    display: flex;
    position: absolute;
    top: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      margin-top: 50px;
    }
  }
  .doc {
    border: 1px solid black;
  }
  #buttondiv {
    height: 80px;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 3;
    position: absolute;
    top: 0;
    width: 100%;
    .submitt {
      button {
        font-size: 12px;
        border: none;
        color: #fff;
        background-color: var(--theme-color);
        padding: 6px;
        border-radius: 8px;
        margin: 6px 0px;
        :hover {
          background-color: #fff;
          color: var(--theme-color);
          border: 1px solid var(--theme-color);
          cursor: pointer;
        }
      }
    }

    button {
      width: 100px;
      height: 30px;
    }
  }
  @media only screen and (max-width: 767px) {
    #signaturediv {
      position: absolute;
      top: 300px;
      height: 100vh;

      .signCanvas {
        background-color: white;
      }
    }
    .signa {
      width: 90%;
      height: 250px;
      canvas {
        height: 55%;
      }
    }
    .genform {
      width: 100vw;
      .react-pdf__Page__canvas {
        max-width: 100%;
      }
      div {
        width: 100vw;
        canvas {
        }
      }
    }
  }
`;
