import { AddTaskContainer } from "../../styles/AddTask.styled";
import { useState, useEffect } from "react";
import axios from "axios";
const AddTask = ({
  setAddTask,
  setTask,
  task,
  clientId,
  setAssigned,
  assigned,
  toast,
}) => {
  const [users, setUsers] = useState();
  useEffect(() => {
    let mounted = true;

    const getUsers = async () => {
      const value = await axios.get(`https://api5.teamfdjfs.com/auth`);
      setUsers(value.data);
    };

    if (mounted) {
      getUsers();
    }

    return () => {
      mounted = false;
    };
  }, []);

  const handleAddTask = async (e) => {
    e.preventDefault();
    let description = e.target.parentElement.children[0].value;
    let due_date = e.target.parentElement.children[1].value;
    let status = e.target.parentElement.children[2].value;
    let assign = e.target.parentElement.children[3].value;
    let assignby = window.localStorage.getItem("id");
    let newTask = {
      description: description,
      due_date: due_date,
      status: status,
      assigned_to: parseInt(assign),
      assigned_by: parseInt(assignby),
      client_id: parseInt(clientId),
    };

    const response = await axios.post(
      `https://api5.teamfdjfs.com/task`,
      newTask
    );
    let newAssigned = [...assigned];
    let newAssignedTask = {
      task_id: response.data.insertId,
      assigned_to: parseInt(assign),
      assigned_by: parseInt(assignby),
      assigned_date: new Date(),
      client_id: parseInt(clientId),
    };
    newAssigned.push(newAssignedTask);
    setAssigned(newAssigned);
    let newTaskList = [...task];
    newTaskList.push(newTask);
    setTask(newTaskList);
    toast.success("Task Added");
    setAddTask(false);
  };
  return (
    <AddTaskContainer>
      <input
        type="text"
        placeholder="Task Description"
        name="task_description"
      />
      <input type="date" name="task_due_date" />
      <select name="status">
        <option value="blocked">Blocked</option>
        <option value="pending">Pending</option>
        <option value="inprogress">On Going</option>
        <option value="completed">Completed</option>
      </select>
      <select name="assign">
        <option>Select to assign</option>
        {users?.map((user) => (
          <option value={user.id} key={user.id}>
            {user.firstname}
          </option>
        ))}
      </select>
      <button type="button" onClick={handleAddTask}>
        Add Task
      </button>
      <button
        type="button"
        onClick={() => {
          setAddTask(false);
        }}
      >
        Exit Window
      </button>
    </AddTaskContainer>
  );
};

export default AddTask;
