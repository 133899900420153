import { useState, useEffect } from "react";
import { ScheduleContainer } from "../../styles/Schedule.styled";
import data from "../../data/empSchedule.json";
// const { google } = require("googleapis");
// const { OAuth2 } = google.auth;
// const auth = new OAuth2(
//   "464289221848-phdbaaafe788poso502rblgvoh0ji1ed.apps.googleusercontent.com",
//   "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",
//   "https://developers.google.com/oauthplayground"
// );

// auth.setCredentials({
//   access_token: ACCESS_TOKEN,
// });
// const sheets = google.sheets({ version: "v4", auth });
// const response = await sheets.spreadsheets.values.get({
//   spreadsheetId: 'YOUR_SPREADSHEET_ID',
//   range: 'Sheet1!A1:D5',
// });

const Schedule = () => {
  const [week, setWeek] = useState([]);

  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfWeek = new Date(
      currentDate.setDate(currentDate.getDate() - currentDate.getDay())
    );
    const days = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(date.getDate() + i);
      days.push({
        name: date.toLocaleDateString("en-US", { weekday: "short" }),
        number: date.getDate(),
      });
    }
    setWeek(days);
  }, []);

  return (
    <ScheduleContainer>
      <table>
        <thead>
          <tr>
            <th colSpan="8">Weekly Schedule</th>
          </tr>
          <tr>
            <th>Staff</th>
            {week.map((day) => (
              <th key={day.name}>
                <div>{day.name}</div>
                <div>{day.number}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* <tr name="staff1">
            <td>Staff 1</td>
            <td className="nowork"></td>
            <td className="full"> 09:30 AM - 06:30 PM</td>
            <td className="morning"> 08:00 AM - 01:00 PM </td>
            <td className="afternoon"> 11:00 AM - 04:00 PM</td>
            <td className="off"></td>
            <td className="absent"></td>
            <td className="off"></td>
          </tr> */}
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              {item?.weeklysched.map((day) => (
                <td key={day.day} className={day.status}>
                  {day.status === "full" ||
                  day.status === "morning" ||
                  day.status === "afternoon"
                    ? day.time
                    : ""}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </ScheduleContainer>
  );
};

export default Schedule;
