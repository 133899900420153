import { useState, useEffect } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { DailyTrackerContainer } from "styles/DailyTracker.styled";
import io from "socket.io-client";
import toast from "react-hot-toast";
// import { set } from "date-fns";
// import { is } from "date-fns/locale";
const socket = io.connect("https://api5.teamfdjfs.com/dailys");
// const socket = io.connect("https://api5.teamfdjfs.com/dailys");
const DailyTracker = () => {
  const location = useLocation();
  const isUser = location.pathname.toString().includes("user");
  const text = isUser ? "office" : "daily";
  const [clients, setClients] = useState();
  const [staffs, setStaffs] = useState();
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState({});
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  let [form, setForm] = useState();
  let [data, setData] = useState([]);
  const id = window.localStorage.getItem("id");
  const client = location.pathname.toString().split("/")[2];
  useEffect(() => {
    const getClients = async () => {
      const client = await axios.get("https://api5.teamfdjfs.com/client");
      console.log(client.data);
      setClients(client.data);

      const value = await axios.get("https://api5.teamfdjfs.com/auth");
      setStaffs(value.data);

      const daily = isUser
        ? await axios.get("https://api5.teamfdjfs.com/daily/office")
        : await axios.get("https://api5.teamfdjfs.com/daily/get");
      if (daily.data) {
        setLoading(false);
      }

      setData(daily.data);
    };
    getClients();
  }, []);

  useEffect(() => {
    socket.on(text, (data) => {
      console.log(`${text} created`, data);
      setData(data);

      toast.success(`${text} Tracker created`);
    });

    socket.on(`delete_${text}`, (data) => {
      if (data.error) {
        toast.error(data.error);
        return;
      } else {
        setData(data);
        toast.success(`${text} Tracker deleted`);
      }
    });

    socket.on(`update_${text}`, (data) => {
      if (data.error) {
        toast.error(data.error);
        return;
      } else {
        setData(data);
        toast.success(`${text} Tracker updated`);
      }
    });
  }, [socket]);

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(clients);
    if (form.call_from === "") {
      toast.error("Please select type of interaction");
      return;
    }
    if (!isUser) {
      console.log(client);
      let newForm = {
        date: new Date().toLocaleDateString(),
        time: new Date().toLocaleTimeString("en-US", { hour12: false }),
        call_from: form.call_from,
        interaction: form.clientName,
        client_id: parseInt(client),
        purpose_of_call: form.purpose_of_call,
        phone_number: form.phone_number,
        staff_Id: parseInt(id),
        notes: form.notes,
      };
      socket.emit("daily", newForm);
      setForm({
        call_from: "",
        clientName: "",
        purpose_of_call: "",
        phone_number: "",
        notes: "",
      });
      setIsAdding(false);
      return;
    } else if (isUser) {
      let newForm = {
        date: new Date().toLocaleDateString(),
        time: new Date().toLocaleTimeString("en-US", { hour12: false }),
        call_from: form.call_from,
        client: form.clientName,
        purpose_of_call: form.purpose_of_call,
        phone_number: form.phone_number,
        staff_Id: parseInt(id),
        notes: form.notes,
      };

      socket.emit("office", newForm);
      setForm({
        call_from: "",
        clientName: "",
        purpose_of_call: "",
        phone_number: "",
        notes: "",
      });
      setIsAdding(false);
      return;
    }
  };
  const onEdit = (e) => {
    e.preventDefault();
    if (selected?.call_from === "") {
      toast.error("Please select type of interaction");
      return;
    } else if (!isUser) {
      let newForm = {
        id: selected.id,
        date: selected.date,
        time: selected.time,
        call_from: selected.call_from,
        interaction: selected.interaction,
        client_id: parseInt(client),
        purpose_of_call: selected.purpose_of_call,
        phone_number: selected.phone_number,
        staff_Id: parseInt(id),
        notes: selected.notes,
      };
      socket.emit("update_daily", newForm);
      setForm({});
      setIsEditing(false);
      return;
    } else if (isUser) {
      let newForm = {
        id: selected.id,
        date: selected.date,
        time: selected.time,
        call_from: selected.call_from,
        client: selected.client,
        purpose_of_call: selected.purpose_of_call,
        phone_number: selected.phone_number,
        staff_Id: parseInt(id),
        notes: selected.notes,
      };
      socket.emit("update_office", newForm);
      setForm({});
      setIsEditing(false);
      return;
    }
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString();
  };
  const formatTime = (date, time) => {
    const newTime = new Date(date.substring(0, 10) + "T" + time);
    const formattedTime = newTime.toLocaleTimeString("en-US", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      // second: "2-digit",
    });
    // console.log(formattedTime);
    return formattedTime;
  };

  return (
    <DailyTrackerContainer>
      <div className="header">
        {isUser ? <h1>Office Tracker</h1> : <h1>Daily Tracker</h1>}
        <div className="add_btn">
          <button
            type="button"
            onClick={() => {
              setIsAdding(true);
              setIsEditing(false);
            }}
          >
            Add Record
          </button>
        </div>
      </div>
      <div
        style={isAdding ? { display: "flex" } : { display: "none" }}
        className="add_tracker"
      >
        <h2>Add {isUser ? "Office Tracker" : "Daily Tracker"}</h2>

        <div className="add_tracker_form">
          <div className="edit_inputs">
            <label>Type of Interaction</label>
            <select
              value={form?.call_from}
              onChange={(e) => setForm({ ...form, call_from: e.target.value })}
            >
              <option value="">Select Type of Interaction</option>
              <option value="Call To">Call To</option>
              <option value="Call From">Call From</option>
              <option value="Email Exchange with">Email Exchange with</option>
              <option value="Meeting with">Meeting with</option>
            </select>
          </div>
          <div className="edit_inputs">
            <label>Interaction with</label>
            <input
              type="text"
              placeholder="Interaction with"
              value={form?.clientName}
              onChange={(e) => setForm({ ...form, clientName: e.target.value })}
            />
          </div>

          <div className="edit_inputs">
            <label>Purpose of call</label>

            <input
              type="text"
              placeholder="Purpose of call"
              value={form?.purpose_of_call}
              onChange={(e) =>
                setForm({ ...form, purpose_of_call: e.target.value })
              }
            />
          </div>

          <div className="edit_inputs">
            <label>Phone Number</label>
            <input
              type="text"
              placeholder="Phone Number"
              value={form?.phone_number}
              onChange={(e) =>
                setForm({ ...form, phone_number: e.target.value })
              }
            />
          </div>

          <div className="edit_inputs">
            <label>Notes</label>
            <input
              type="text"
              placeholder="Notes"
              value={form?.notes}
              onChange={(e) => setForm({ ...form, notes: e.target.value })}
            />
          </div>

          <div className="edit_btn">
            <button type="button" onClick={onSubmit}>
              Submit
            </button>
            <button
              type="button"
              onClick={() => {
                setIsAdding(false);
              }}
            >
              Exit Window
            </button>
          </div>
        </div>
      </div>
      <div
        style={isEditing ? { display: "flex" } : { display: "none" }}
        className="edit_tracker"
      >
        <h2>Edit {isUser ? "Office Tracker" : "Daily Tracker"}</h2>
        <div className="edit_tracker_form">
          <div className="edit_inputs">
            <label htmlFor="interaction_type">Interaction Type</label>
            <select
              value={selected?.call_from}
              onChange={(e) =>
                setSelected({ ...selected, call_from: e.target.value })
              }
            >
              <option value="">Select Type of Interaction</option>
              <option value="Call To">Call To</option>
              <option value="Call From">Call From</option>
              <option value="Email Exchange with">Email Exchange with</option>
              <option value="Meeting with">Meeting with</option>
            </select>
          </div>
          <div className="edit_inputs">
            <label htmlFor="interaction_with">Interaction with</label>
            <input
              type="text"
              placeholder="Interaction with"
              value={isUser ? selected?.client : selected?.interaction}
              onChange={(e) =>
                isUser
                  ? setSelected({ ...selected, client: e.target.value })
                  : setSelected({ ...selected, interaction: e.target.value })
              }
            />
          </div>
          <div className="edit_inputs">
            <label htmlFor="purpose_of_call">Purpose of call</label>
            <input
              type="text"
              placeholder="Purpose of call"
              value={selected?.purpose_of_call}
              onChange={(e) =>
                setSelected({ ...selected, purpose_of_call: e.target.value })
              }
            />
          </div>
          <div className="edit_inputs">
            <label htmlFor="phone_number">Phone Number</label>
            <input
              type="text"
              placeholder="Phone Number"
              value={selected?.phone_number}
              onChange={(e) =>
                setSelected({ ...selected, phone_number: e.target.value })
              }
            />
          </div>
          <div className="edit_inputs">
            <label htmlFor="notes">Notes</label>
            <input
              type="text"
              placeholder="Notes"
              value={selected?.notes}
              onChange={(e) =>
                setSelected({ ...selected, notes: e.target.value })
              }
            />
          </div>
          <div className="edit_btn">
            <button type="button" onClick={onEdit}>
              Save
            </button>
            <button
              type="button"
              onClick={(e) => {
                setIsEditing(false);
                setSelected({});
              }}
            >
              Exit Window
            </button>
          </div>
        </div>
      </div>
      <div className="tablee">
        <table className="daily-tracker">
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Staff Name</th>
              <th>Interaction type</th>
              <th>Interaction with</th>
              <th>Purpose of call</th>
              <th>Phone Number</th>
              <th>Notes</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="9">loading ...</td>
              </tr>
            ) : null}
            {data
              ?.filter((item) => {
                if (!isUser) {
                  return parseInt(client) === item?.client_id;
                } else {
                  return item;
                }
              })
              ?.map((item) => {
                let staff = staffs.find((i) => i.id === item?.staff_id);
                let client = clients.find((i) => i.id === item?.client_id);

                return (
                  <tr key={item?.id}>
                    <td>{formatDate(item?.date)}</td>
                    <td>{formatTime(item?.date, item?.time)}</td>
                    <td>{staff?.firstname}</td>
                    <td>{item?.call_from}</td>
                    <td>{isUser ? item?.client : item.interaction}</td>
                    <td>{item?.purpose_of_call}</td>
                    <td>{item?.phone_number}</td>
                    <td>
                      <div>{item?.notes}</div>
                    </td>
                    <td>
                      <div className="btn">
                        <button
                          type="button"
                          onClick={() => {
                            setIsEditing(true);
                            setIsAdding(false);
                            setSelected(item);
                          }}
                        >
                          <BiEdit />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            const confirm = window.confirm(
                              "Are you sure you want to delete?"
                            );
                            if (!confirm) return;

                            isUser
                              ? socket.emit("delete_office", item?.id)
                              : socket.emit("delete_daily", item?.id);
                          }}
                        >
                          <BiTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </DailyTrackerContainer>
  );
};

export default DailyTracker;
