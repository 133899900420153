import { useState, useEffect } from "react";
import { IteneraryContainer } from "../../styles/Itenerary.styled";
import axios from "axios";
import { format, isValid } from "date-fns";

const Itenerary = () => {
  const [date, setDate] = useState();
  const [clients, setClients] = useState();
  const [selected, setSelected] = useState();
  const [sprinter, setSprinter] = useState(false);

  useEffect(() => {
    axios
      .get("https://api5.teamfdjfs.com/client")
      .then((res) => {
        console.log(res.data);
        setClients(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get("https://api5.teamfdjfs.com/funeral/dates/1")
      .then((res) => {
        console.log(res.data);
        setDate(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const location =
    "Frantz Daniel Jean Funeral Services Inc., 5020 Foster Ave, Brooklyn, NY 11203, United States";

  const formatDate = (date) => {
    const newDate = new Date(date);
    const day =
      newDate.getDate() > 9 ? newDate.getDate() : `0${newDate.getDate()}`;
    const month =
      newDate.getMonth() + 1 > 9
        ? newDate.getMonth() + 1
        : `0${newDate.getMonth() + 1}`;
    const year = newDate.getFullYear();
    return `${month}/${day}/${year}`;
  };
  function formatD(d) {
    d = new Date(d);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return d.toLocaleDateString("en-US", options);
  }

  const arrive = (time, travel) => {
    const [hours, minutes, seconds] = time.split(":");
    const d = new Date();
    d.setHours(hours);
    d.setMinutes(parseInt(minutes) - travel);
    d.setSeconds(seconds);

    const newTime = d.toLocaleTimeString(["en-US"], { hour12: false });
    return newTime;
  };

  const getTravelTime = (time, travel) => {
    const [hours, minutes, seconds] = time.split(":");
    const d = new Date();
    d.setHours(hours);
    d.setMinutes(parseInt(minutes) + travel);
    d.setSeconds(seconds);

    const newTime = d.toLocaleTimeString(["en-US"], { hour12: false });
    return newTime;
  };
  const getLimoTime = (time, travel) => {
    const [hours, minutes, seconds] = time.split(":");
    const d = new Date();
    d.setHours(hours);
    d.setMinutes(parseInt(minutes) - travel);
    d.setSeconds(seconds);

    const newTime = d.toLocaleTimeString(["en-US"], { hour12: false });
    return newTime;
  };

  function formatTime(time) {
    const [hours, minutes] = time.split(":");
    const date = new Date();
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes));

    const formattedTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const amPm = formattedTime.slice(-2);
    const timeString = formattedTime.slice(0, -3);

    return `${timeString} ${amPm}`;
  }
  const onSubmit = (e) => {
    e.preventDefault();
    const data = e.target.date.value;
    const travel = e.target.hearsetravel.value;
    const driver = e.target.driver.value;
    const limotravel = e.target.limotravel.value;
    const limodropoff = sprinter
      ? [e.target.dropoff1.value, e.target.dropoff2.value]
      : [e.target.dropoff1.value];
    const limotime =
      parseInt(limotravel) >= 15
        ? parseInt(limotravel) + 15
        : parseInt(limotravel) + 10;

    const hearsetime =
      travel >= 15 ? parseInt(travel) + 45 : parseInt(travel) + 35;
    let information = date.find((date) => date.id == data.split(" ")[1]);
    console.log(hearsetime);

    information = {
      ...information,
      driver: driver,
      travel: travel,
      arrive: arrive(information?.service_time, hearsetime),
      arrive2: getTravelTime(information?.service_time, hearsetime),
      limotravel: limotravel,
      limodropoff: limodropoff,
      limotime: limotime,
      limoarrive: getLimoTime(information?.service_time, limotime),
      limopickup: e.target.pickup.value,
      staffFD: e.target.FD.value,
      staffAssist: e.target.assistant.value,
      staffHearse: e.target.h.value,
      staffLimo: e.target.l.value,
    };

    setSelected(information);

    console.log(information);
  };
  return (
    <IteneraryContainer>
      <h2>
        Funeral
        <br />
        Itinerary
      </h2>
      <div className="daily-wrapper">
        <form onSubmit={onSubmit}>
          <div className="form-input">
            <label htmlFor="date">Date: </label>
            <select name="date" id="date">
              <option value="">Select Date</option>
              {date
                ?.filter((date) => {
                  if (
                    isValid(new Date(date?.service_date)) &&
                    isValid(new Date(date?.viewing_date)) &&
                    isValid(new Date(date?.arrival_date)) &&
                    (date?.service_date !== null ||
                      date?.viewing_date !== null ||
                      date?.arrival_date !== null) &&
                    (formatDate(date?.service_date) >= formatDate(new Date()) ||
                      formatDate(date?.viewing_date) >=
                        formatDate(new Date()) ||
                      formatDate(date?.arrival_date) >= formatDate(new Date()))
                  ) {
                    return date;
                  }
                })
                .map((date) => (
                  <option
                    value={"service " + date?.id}
                    key={"service " + date?.id}
                  >
                    {date?.decname} Service Date:{" "}
                    {formatDate(date?.service_date)}
                  </option>
                ))}
            </select>
            {/* <input id="date" type="text" placeholder="Date" list="dates" />
          <datalist id="dates">
            {date?.map((date) => (
              <>
                <option value={formatDate(date?.service_date)} />
                <option value={formatDate(date?.viewing_date)} />
                <option value={formatDate(date?.arrival_date)} />
              </>
            ))}
          </datalist> */}
          </div>
          <div className="hearse">
            <h3>Hearse</h3>
            <div className="form-input">
              <label htmlFor="driver">Hearse Driver: </label>
              <input type="text" id="driver" />
            </div>
            <div className="form-input">
              <label htmlFor="travel">FDJ to Funeral Home Travel Time: </label>
              <input
                type="text"
                id="hearsetravel"
                placeholder="Enter minutes"
              />
            </div>
          </div>

          <div className="limo">
            <h3>Limo</h3>
            <div className="form-input">
              <label htmlFor="limotravel">
                Pick up address to FDJ Travel Time:{" "}
              </label>
              <input type="text" id="limotravel" placeholder="Enter minutes" />
            </div>
            <div className="form-input">
              <label htmlFor="pickup">Pick up address (if applicable):</label>
              <input
                type="text"
                id="pickup"
                placeholder="Enter pick up address"
              />
            </div>
            <input
              type="checkbox"
              id="sprinter"
              value={sprinter}
              onChange={(e) => setSprinter(e.target.checked)}
            />
            <label htmlFor="sprinter">Sprinter</label>

            {sprinter ? (
              <>
                <div className="form-input">
                  <label htmlFor="dropoff1">
                    Drop off address 1 (if applicable):
                  </label>
                  <input
                    type="text"
                    id="dropoff1"
                    placeholder="Enter drop off address"
                  />
                </div>

                <div className="form-input">
                  <label htmlFor="dropoff2">
                    Drop off address 2 (if applicable):
                  </label>
                  <input
                    type="text"
                    id="dropoff2"
                    placeholder="Enter drop off address"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="form-input">
                  <label htmlFor="dropoff1">
                    Drop off address 1 (if applicable):
                  </label>
                  <input
                    type="text"
                    id="dropoff1"
                    placeholder="Enter drop off address"
                  />
                </div>
              </>
            )}
          </div>
          <div className="staff">
            <h3>Staff</h3>
            <div className="form-input">
              <label htmlFor="FD">Funeral Director:</label>
              <input
                type="text"
                id="FD"
                placeholder="Enter Funeral Director's Name"
              />
            </div>
            <div className="form-input">
              <label htmlFor="assistant">Assitant/s: </label>
              <input
                type="text"
                id="assistant"
                placeholder="Enter Assistant's Name"
              />
            </div>
            <div className="form-input">
              <label htmlFor="h">Hearse: </label>
              <input type="text" id="h" placeholder="Enter Hearse" />
            </div>
            <div className="form-input">
              <label htmlFor="l">Limo: </label>
              <input type="text" id="l" placeholder="Enter Limo" />
            </div>
          </div>

          <div className="btn-container">
            <button type="submit">Submit</button>
          </div>
        </form>
        {selected && (
          <div className="map">
            <div className="hearse-result">
              <h3>Hearse</h3>
              <div className="map-container">
                <label htmlFor="map-date">Date: </label>
                <span id="map-date">{formatD(selected?.service_date)}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-arrival">Arrive at FH FD: </label>
                <span id="map-arrival">{formatTime(selected?.arrive)}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-driver">Name of Driver: </label>
                <span id="map-driver">{selected?.driver}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-decedent">Name of Decedent: </label>
                <span id="map-decedent">{selected?.decname}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-contact">Name of Contact: </label>
                <span id="map-contact">{selected?.firstname}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-tel">Telephone Number: </label>
                <span id="map-tel">{selected?.telephone}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-to">To: </label>
                <span id="map-to">{selected?.service_location}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-time">Service Time: </label>
                <span id="map-time">{formatTime(selected?.service_time)}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-cemetery">Cemetery Time: </label>
                <span id="map-cemetery">{formatTime(selected?.arrive2)}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-cemname">Cemetery Name: </label>
                <span id="map-cemname">{selected?.cemetary_name}</span>
              </div>
              <p>Please confirm that you've received this message.</p>
            </div>
            <div className="limo-result">
              <h3>Limo</h3>
              <div className="map-container">
                <label htmlFor="map-date">Date: </label>
                <span id="map-date">{formatD(selected?.service_date)}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-decedent">Name of Decedent: </label>
                <span id="map-decedent">{selected?.decname}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-contact">Name of Contact: </label>
                <span id="map-contact">{selected?.firstname}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-tel">Telephone Number: </label>
                <span id="map-tel">{selected?.telephone}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-to">Pickup Address: </label>
                <span id="map-to">{selected?.limopickup}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-arrival">Service time: </label>
                <span id="map-arrival">
                  {formatTime(selected?.service_time)}
                </span>
                <div className="map-container">
                  <label htmlFor="map-cemname">Cemetery Name: </label>
                  <span id="map-cemname">{selected?.cemetary_name}</span>
                </div>
              </div>

              <div className="map-container">
                <label htmlFor="map-arrival">Pick up time: </label>
                <span id="map-arrival">{formatTime(selected?.limoarrive)}</span>
              </div>
              {/* <div className="map-container">
                <label htmlFor="map-driver">Name of Driver: </label>
                <span id="map-driver">{selected?.driver}</span>
              </div> */}
              {sprinter ? (
                <>
                  <div className="map-container">
                    <label htmlFor="map-dropoff1">
                      1st Drop off Location:{" "}
                    </label>
                    <span id="map-dropoff1">{selected?.limodropoff[0]}</span>
                  </div>
                  <div className="map-container">
                    <label htmlFor="map-dropoff1">
                      2nd Drop off Location:{" "}
                    </label>
                    <span id="map-dropoff1">{selected?.limodropoff[1]}</span>
                  </div>
                </>
              ) : (
                <div className="map-container">
                  <label htmlFor="map-dropoff1">Drop off Location: </label>
                  <span id="map-dropoff1">{selected?.limodropoff[0]}</span>
                </div>
              )}
              <p>Please confirm that you've received this message.</p>
            </div>
            <div className="staff-result">
              <h3>Staff</h3>
              <div className="map-container">
                <label htmlFor="map-date">Date: </label>
                <span id="map-date">{formatD(selected?.service_date)}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-arrival">Arrive at FH: </label>
                <span id="map-arrival">{formatTime(selected?.arrive)}</span>
              </div>

              <div className="map-container">
                <label htmlFor="map-driver">Name of assistant/s: </label>
                <span id="map-driver">{selected?.staffAssist}</span>
              </div>
              <div className="map-container">
                <label>Hearse:</label>
                <span>{selected?.staffHearse}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-driver">Hearse Driver: </label>
                <span id="map-driver">{selected?.driver}</span>
              </div>
              <div className="map-container">
                <label>Limo: </label>
                <span>{selected?.staffLimo}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-decedent">Name of Decedent: </label>
                <span id="map-decedent">{selected?.decname}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-contact">Name of Contact: </label>
                <span id="map-contact">{selected?.firstname}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-to">To: </label>
                <span id="map-to">{selected?.service_location}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-time">Service Time: </label>
                <span id="map-time">{formatTime(selected?.service_time)}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-cemetery">Cemetery Time: </label>
                <span id="map-cemetery">{formatTime(selected?.arrive2)}</span>
              </div>
              <div className="map-container">
                <label htmlFor="map-cemname">Cemetery Name: </label>
                <span id="map-cemname">{selected?.cemetary_name}</span>
              </div>
              <p>Please confirm that you've received this message.</p>
            </div>
          </div>
        )}
      </div>
    </IteneraryContainer>
  );
};

export default Itenerary;
