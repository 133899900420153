import { useState, useEffect } from "react";
import { CgCloseO } from "react-icons/cg";
import { AddAccount } from "../components/HomePage/AddAccount";
import AddClient from "../components/HomePage/AddClient";

// components
// import Banner from "../components/HomePage/Banner";
import MainHeader from "../components/HomePage/MainHeader";
import Rightbar from "../components/HomePage/Rightbar";
// import Sidebar from "../components/HomePage/Sidebar";
import PrimaryIntake from "../components/ProfilePage/PrimaryIntake";
import ProfileFuneralDetails from "../components/ProfilePage/ProfileFuneralDetails";
import Notes from "../components/HomePage/Notes";
// styles
import { HomePageSection } from "../styles/HomePage.styled";

import CurrentCase from "../components/UserPage/CurrentCase";
import MessageBoard from "../components/HomePage/MessageBoard";

import axios from "axios";
import { toast } from "react-hot-toast";
import NewBanner from "../components/HomePage/NewBanner";

const HomePage = () => {
  const [showForm, setShowForm] = useState(false);
  const [addAccount, setAddAccount] = useState(false);
  const [addClient, setAddClient] = useState(false);
  const [client, setClient] = useState(true);
  const [primary, setPrimary] = useState(false);
  const [funeral, setFuneral] = useState(false);
  const [searchClient, setSearchClient] = useState("");
  // message components
  const [messageShow, setMessageShow] = useState(false);
  const [message, setMessage] = useState([]);
  const [history, setHistory] = useState([]);
  const [messageData, setMessageData] = useState("");

  const [newClient, setNewClient] = useState({});

  // useEffect(() => {
  //   console.log("message", message);
  //   if (messageShow === true) {
  //     axios.get("https://api5.teamfdjfs.com/message").then((res) => {
  //       setMessage(res.data.message);
  //       setHistory(res.data.history);
  //     });
  //   }

  //   // socket.reconnect();
  // }, [messageShow]);

  useEffect(() => {
    console.log(newClient);
  }, [newClient]);

  async function submitNewClient() {
    // let complete = [];
    // document.querySelectorAll(".required").forEach((element) => {
    //   // console.log(element);
    //   // console.log(element.value);
    //   if (!element.value.trim()) {
    //     element.style.border = "2px solid #701018";
    //     complete.push(element);
    //   } else {
    //     element.style.border = "1px solid #ccc";
    //   }
    // });
    // if (!newClient.serArr) {
    //   document.getElementsByClassName("multi")[0].style.border =
    //     "2px solid #701018";
    // } else {
    //   document.getElementsByClassName("multi")[0].style.border =
    //     "1px solid #ccc";
    // }

    // if (complete.length === 0) {
    await axios
      .post("https://api5.teamfdjfs.com/client/newAddClient", {
        newClient,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          toast.success("Successfully added");
          setShowForm(false);
          setTimeout(() => {
            window.location = `https://teamfdjfs.com/profile/${res.data.clientid}`;
          }, 1000);
        } else {
          toast.error("Request error. Try again");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //   toast.error("Fill in required inputs");
    // }
  }

  return (
    <HomePageSection>
      <NewBanner
        setShowForm={setShowForm}
        setAddAccount={setAddAccount}
        setAddClient={setAddClient}
      />
      <div className="mainContainer">
        {/* <div className="mainContainer__left">
          <Sidebar />
        </div> */}
        <div className="mainContainer__right">
          <div className="mainContainer__right__header">
            {/*  */}
            <MainHeader setMessageShow={setMessageShow} />
            {/*  */}
          </div>
          <div className="mainContainer__right__body">
            {/*  */}
            <div className="mainContainer__right__leftside">
              <CurrentCase />
              {/* <ClientDisplay searchClient={searchClient} /> */}
            </div>
            {/* <IoExit
              id="icon"
              onMouseEnter={() => {
                document.getElementById("rightside").classList.toggle("show");
              }}
              // onClick={() => {
              //   document.getElementById("rightside").classList.toggle("show");
              // }}
              style={{
                height: "40px",
                width: "60px",
                transform: "rotate(0.5turn)",
              }}
            /> */}
            <div
              className="message"
              id="messageside"
              onMouseLeave={() => {
                setMessageShow(false);
                document.getElementById("messageside").classList.toggle("show");
              }}
            >
              <MessageBoard
                message={message}
                setMessage={setMessage}
                history={history}
                setHistory={setHistory}
                messageData={messageData}
                setMessageData={setMessageData}
              />
              {/* <MessageBoard /> */}
            </div>
            <div
              id="rightside"
              className="mainContainer__right__rightside"
              onMouseLeave={() => {
                document.getElementById("rightside").classList.toggle("show");
              }}
            >
              <Rightbar />
            </div>

            {/*  */}
          </div>
        </div>
      </div>
      <div className={showForm ? "formsContainer active" : "formsContainer"}>
        <div className="formsContainer__form">
          <div
            style={
              addAccount
                ? { display: "flex", width: "100%" }
                : { display: "none" }
            }
          >
            <AddAccount
              setShowForm={setShowForm}
              setAddAccount={setAddAccount}
            />
          </div>

          <div
            style={
              addClient
                ? { display: "flex", flexDirection: "column" }
                : { display: "none" }
            }
          >
            <div className="switch">
              <div
                className="item active"
                onClick={(e) => {
                  if (e.target.classList.contains("active")) return;

                  e.target.classList.add("active");
                  document
                    .querySelector(".switch .item:nth-child(2)")
                    .classList.remove("active");
                  document
                    .querySelector(".switch .item:nth-child(3)")
                    .classList.remove("active");

                  setClient(true);
                  setPrimary(false);
                  setFuneral(false);
                }}
              >
                Add Client
              </div>
              <div
                className="item"
                onClick={(e) => {
                  if (e.target.classList.contains("active")) return;
                  e.target.classList.add("active");
                  document
                    .querySelector(".switch .item:nth-child(1)")
                    .classList.remove("active");
                  document
                    .querySelector(".switch .item:nth-child(3)")
                    .classList.remove("active");
                  setClient(false);
                  setPrimary(true);
                  setFuneral(false);
                }}
              >
                Primary Intake
              </div>
              <div
                className="item"
                onClick={(e) => {
                  if (e.target.classList.contains("active")) return;
                  e.target.classList.add("active");
                  document
                    .querySelector(".switch .item:nth-child(1)")
                    .classList.remove("active");
                  document
                    .querySelector(".switch .item:nth-child(2)")
                    .classList.remove("active");
                  setClient(false);
                  setPrimary(false);
                  setFuneral(true);
                }}
              >
                Service Details
              </div>
              <CgCloseO
                size={30}
                className="close"
                type="button"
                onClick={() => {
                  setShowForm(false);
                  setAddClient(false);
                }}
              />
            </div>

            <div
              className="addcont"
              style={{ display: client ? "flex" : "none" }}
            >
              <AddClient
                setShowForm={setShowForm}
                setAddClient={setAddClient}
                setNewClient={setNewClient}
              />
            </div>

            <div
              className="addcont"
              style={{ display: primary ? "flex" : "none" }}
            >
              <PrimaryIntake setNewClient={setNewClient} />
            </div>

            <div
              className="addcont"
              style={{ display: funeral ? "flex" : "none" }}
            >
              <ProfileFuneralDetails setNewClient={setNewClient} />
            </div>

            <div>
              <button
                className="formbtn"
                onClick={() => {
                  // console.log(
                  //   document.getElementsByClassName("foraddclient")
                  // );
                  submitNewClient();
                }}
              >
                Add Client
              </button>
              <button
                className="formbtn"
                onClick={() => {
                  setShowForm(false);
                  setAddClient(false);
                }}
              >
                Exit Window
              </button>
            </div>
          </div>
        </div>
        <Notes setNotes={setNewClient} />
      </div>
    </HomePageSection>
  );
};

export default HomePage;
