import styled from "styled-components";

export const UserProfileContainer = styled.div`
  h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .profile-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;

    .profile-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background-color: transparent;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 90%;
        height: 90%;
        border-radius: 50%;
        object-fit: cover;
      }
      input {
        display: none;
      }
    }

    .profile-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 2px;

      h4 {
        font-size: 1.5rem;
        font-weight: 600;
      }

      p {
        font-size: 1.2rem;
        font-weight: 400;
      }
      form {
        /* display: none; */
      }
    }
  }
  /* button {
    width: 100px;
    height: 30px;
    background-color: var(--theme-color);
    color: #fff;
    border: none;
    border-radius: 20px;
    :hover {
      background-color: #fff;
      color: var(--theme-color);
      font-weight: 600;
      border: 1px solid var(--theme-color);
      cursor: pointer;
    }
  } */

  .profile-edit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .inputs {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      gap: 8px;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 50%;
        gap: 1rem;

        .input {
          width: 100%;

          input {
            margin-top: 8px;
            font-size: 13px;
            padding: 5px;
            width: 100%;

            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
          }

          label {
            font-size: 1rem;
            font-weight: 600;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 50%;
        gap: 1rem;

        .input {
          width: 100%;

          input {
            margin-top: 8px;
            font-size: 13px;
            padding: 5px;
            width: 100%;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
          }

          label {
            font-size: 1rem;
            font-weight: 600;
          }
        }
      }
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      margin: 10px 0px;
      letter-spacing: 1px;
      line-height: 1.5;
    }

    .btn {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-top: 2rem;
      width: 100%;
      gap: 8px;

      .primary {
        width: 15%;
        height: 30px;
        border: none;
        background-color: var(--theme-color);
        color: #fff;
        outline: none;
        border-radius: 8px;
        padding: 5px;
        letter-spacing: 1px;
        font-size: 1rem;
        transition: ease-in-out 0.5s;
        cursor: pointer;

        &:hover {
          background-color: #fff;
          color: var(--theme-color);
          border: 1px solid var(--theme-color);
          font-weight: 600;
        }
      }
    }
  }
`;
