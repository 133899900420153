import React from "react";

export default function Notes(props) {
  console.log(props);
  return (
    <div
      className="inputs"
      style={{
        width: "300px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        verticalAlign: "center",
        justifyContent: "center",
        borderRadius: "15px",
      }}
    >
      <div
        style={{
          backgroundColor: "rgba(155,155,155,0.64)",
          height: "500px",
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          verticalAlign: "center",
          justifyContent: "center",
          borderRadius: "15px",
        }}
      >
        <label htmlFor="Notes">Notes</label>
        <textarea
          style={{
            height: "75%",
            borderRadius: "15px",
            padding: "10px",
            maxHeight: "75%",
            maxWidth: "100%",
            minHeight: "75%",
            minWidth: "100%",
          }}
          className={`${"foraddclient" + " " + props.className}`}
          type="textfield"
          defaultValue={props.notes ?? ""}
          onChange={(e) =>
            props.setNotes
              ? props.setNotes((prev) => {
                  if (props.notes === undefined)
                    return { ...prev, notes: e.target.value };
                  else
                    return {
                      ...prev,
                      client: { ...prev.client, notes: e.target.value },
                    };
                })
              : null
          }
        />
      </div>
    </div>
  );
}
