import { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import io from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { RightBarContainer } from "../../styles/RightBar.styled";
const socket = io.connect("https://api5.teamfdjfs.com/tasks");
const Rightbar = () => {
  const [assignments, setAssignment] = useState();
  const [users, setUsers] = useState();
  const user_id = localStorage.getItem("id");
  const navigate = useNavigate();

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);

    const [month, day, year] = formattedDate.split(" ");

    return `${day.replace(/,/g, "")} ${month} ${year}`;
  };

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get(
        `https://api5.teamfdjfs.com/task/ass/${user_id}`
      );

      const value = await axios.get(`https://api5.teamfdjfs.com/auth`);
      console.log(data);
      const filterData =
        data.tasks ??
        data.filter((item) => {
          if (
            (item?.status === "pending" || item?.status === "inprogress") &&
            format(item?.due_date) >= format(new Date())
          ) {
            return item;
          }
        });
      setUsers(value.data);
      setAssignment(filterData);
    };
    getData();
  }, []);

  useEffect(() => {
    socket.on("get-task", (data) => {
      if (data?.newAssign === user_id) {
        toast.success(`New task assigned to you!`);
        let filterData = data?.newRecord?.filter((item) => {
          if (
            (item?.status === "pending" || item?.status === "inprogress") &&
            format(item?.due_date) >= format(new Date())
          ) {
            return item;
          }
        });
        setAssignment(filterData);
      } else if (data?.message) {
        toast.success(data?.message);
      }
    });
  }, [socket]);
  const format = (date) => {
    const d = new Date(date);

    const year = d.getFullYear();
    const month =
      d.getMonth() + 1 >= 10 ? d.getMonth() + 1 : "0" + d.getMonth() + 1;
    const day = d.getDate() >= 10 ? d.getDate() : "0" + d.getDate();

    return `${year}-${month}-${day}`;
  };
  return (
    <RightBarContainer>
      <div className="recent__header">
        <h3>Assigned Task</h3>
      </div>
      <div className="recent__body">
        {/* note item */}

        {assignments?.length > 0 ? (
          assignments?.map((item) => {
            let u = users.find((i) => i.id === item.assigned_by);
            return (
              <div
                className={item?.status + " recent__body__item"}
                key={item?.id}
                onClick={() => {
                  navigate(
                    `/profile/redirect/${item?.task_id}/${item?.client_id}`
                  );
                  // window.location.href = `/profile/redirect/${item?.task_id}/${item?.client_id}`;
                }}
              >
                <div className="notes">{item?.task}</div>
                <div className="asd">
                  <div className="date">
                    <div>Due: </div>
                    {formatDate(item?.due_date)}
                  </div>
                  <div className="notesby">
                    <div>Assigned by:</div>
                    <span className="editor">{u?.firstname}</span>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="blocked recent__body__item">
            <div className="notes">No task assigned</div>
            <div className="asd">
              <div className="date">
                <div></div>
              </div>
              <div className="notesby">
                <div></div>
                <span className="editor"></span>
              </div>
            </div>
          </div>
        )}
      </div>
      <Toaster />
    </RightBarContainer>
  );
};

export default Rightbar;
