import { useState } from "react";
import { AddAccountContainer } from "styles/NewAddAccount.styled";
import axios from "axios";

export const AddAccount = ({ setShowForm, setAddAccount }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("admin");

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      // toast.error("Passwords do not match");
      alert("Passwords do not match");
      return;
    }
    const { data } = await axios.post(
      "https://api5.teamfdjfs.com/auth/register",
      {
        firstName,
        lastName,
        username,
        email,
        password,
        role,
      }
    );

    if (data.message === "User registered") {
      alert("User registered");
      setShowForm(false);
      setAddAccount(false);
    }

    if (data.message === "Username or email already exists") {
      setFirstName("");
      setLastName("");
      setUsername("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");

      alert("Username or email already exists");
      // toast.error("Username or email already exists");
    }
  };
  return (
    <AddAccountContainer>
      <div className="addAccount__header">
        <h3>Add Account</h3>
      </div>
      <div className="addAccount__body">
        <form onSubmit={onSubmit}>
          {/* inputs */}
          <div className="addAccount__body__input">
            <label htmlFor="FirstName">First Name</label>
            <input
              type="text"
              id="FirstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="addAccount__body__input">
            <label htmlFor="LastName">Last Name</label>
            <input
              type="text"
              id="LastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="addAccount__body__input">
            <label htmlFor="Username">Username</label>
            <input
              type="text"
              id="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="addAccount__body__input">
            <label htmlFor="Email">Email</label>
            <input
              type="email"
              id="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="addAccount__body__input">
            <label htmlFor="Password">Password</label>
            <input
              type="password"
              id="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="addAccount__body__input">
            <label htmlFor="ConfirmPassword">Confirm Password</label>
            <input
              type="password"
              id="ConfirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="addAccount__body__role">
            <select
              name="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="admin">Admin</option>
              <option value="executive">Executive</option>
              <option value="operation">Operations</option>
            </select>
          </div>
          {/* buttons */}
          <div className="addAccount__body__buttons">
            <button type="submit">Add Account</button>
            <button
              type="button"
              onClick={() => {
                setShowForm(false);
                setAddAccount(false);
              }}
            >
              Exit Window
            </button>
          </div>
        </form>
      </div>
    </AddAccountContainer>
  );
};
