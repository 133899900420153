import { MobileLayoutContainer } from "styles/MobileLayout.styled";
import React from "react";
import { Outlet } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import logo from "images/fdjfslogo.jpg";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaFileContract } from "react-icons/fa";
import { GiPathDistance } from "react-icons/gi";
import { CgNotes } from "react-icons/cg";
import { AiOutlineMenuFold } from "react-icons/ai";

const MobileLayout = () => {
  return (
    <MobileLayoutContainer>
      <div className="nav">
        <div className="nav__menu">
          <BiMenu />
        </div>
        <div className="nav__logo">
          <img
            src={
              logo
                ? logo
                : "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
            }
            alt="logo"
          />
        </div>
      </div>
      <div className="sidebar">
        <div className="profile">
          <div className="image">
            <img
              src="https://media.istockphoto.com/id/1309328823/photo/headshot-portrait-of-smiling-male-employee-in-office.jpg?b=1&s=612x612&w=0&k=20&c=eU56mZTN4ZXYDJ2SR2DFcQahxEnIl3CiqpP3SOQVbbI="
              alt="profile"
            />
          </div>
          <div className="detail">
            <div className="name">Lawrence Neil</div>
            <div className="role">Admin</div>
          </div>
          <AiOutlineMenuFold className="close" />
        </div>
        <ul className="list">
          <li>
            <span>Dashboard</span> <MdKeyboardArrowDown />
          </li>

          <li>
            <span>Funeral</span>
            <MdKeyboardArrowDown />
          </li>
          <ul className="sublist">
            <li>
              <BsFillPeopleFill className="nav-icon" />
              <span>Client</span>
            </li>
            <li>
              <FaFileContract className="nav-icon" />
              <span>Contract</span>
            </li>
            <li>
              <GiPathDistance className="nav-icon" />
              <span>Itenerary</span>
            </li>
            <li>
              <CgNotes className="nav-icon" />
              <span>Daily Tracker</span>
            </li>
          </ul>
          <li>
            <span>Calendar</span> <MdKeyboardArrowDown />
          </li>
          <ul className="sublist">
            <li className="active">
              <BsFillPeopleFill className="nav-icon" />
              <span>Event</span>
            </li>
            <li>
              <FaFileContract className="nav-icon" />
              <span>Task</span>
            </li>
          </ul>
          <li>
            <span>Links</span> <MdKeyboardArrowDown />
          </li>
        </ul>
        <div className="footer">
          <span>Account</span>
          <span>Logout</span>
        </div>
      </div>

      <div className="content">
        <Outlet />
      </div>
    </MobileLayoutContainer>
  );
};

export default MobileLayout;
