import styled from "styled-components";

export const FormStyle = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;500;700&display=swap");
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }
  #wait-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  #gif {
    aspect-ratio: 1/1;
    width: 40px;
  }
  #sending {
    color: white;
  }
  #mainform {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    #genform {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      position: relative;
      div {
        button {
          position: absolute;
          z-index: 100;
          right: 0;
        }
      }
      #modform,
      #modform-div,
      #modform-iframe {
        height: 100%;
        width: 100%;
      }
      #modform {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
  }

  #newcase-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mainform-div {
    display: flex;
    width: 70vw;
    padding: 15px;
    border-radius: 15px;
    flex-direction: column;
    h2 {
      margin-left: 10px;
    }
    .page2buttonprev {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    #page3buttonprev {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    #page3buttonprev {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .submitt {
        font-size: 13px;
        cursor: pointer;
        margin: 10px 10px;
        padding: 6px;
        width: 70px;
        height: 100%;
        color: #fff;
        border-radius: 10px;
        border: none;
        background-color: #770716;
      }
    }
    div {
      .button-div {
        float: right;
      }

      .button {
        font-size: 13px;
        cursor: pointer;
        margin: 10px 10px;
        padding: 6px;
        width: 70px;
        height: 100%;

        border-radius: 10px;
        border: 1px solid black;
      }
    }
  }

  #form-div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    .form {
      font-size: 13px;
      display: flex;
      width: 100%;
      flex: 1;
    }
  }
  .form {
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
  .form div {
    width: 100%;
    margin: 5px 0;
  }
  .form label,
  .form input,
  .form select,
  .form h5 {
    width: 100%;
    margin: 0 auto;
  }
  .form label {
    font-weight: 400;
    /* margin-bottom: 5px; */
    display: flex;
    flex-direction: column;
    align-items: left;
    font-weight: 800;
    color: #701018;
  }
  .form input,
  .form select {
    padding: 2px;
    border-radius: 5px;
    border: 1px solid grey;
    width: 100%;
    outline: none;
  }
  .form h5 {
    margin: 0;
  }
  #page2,
  #page3 {
    display: none;
  }
  @media only screen and (max-width: 767px) {
    .mainform-div {
      display: flex;
      flex-direction: column;
      width: 100vw;
      border-radius: 10px;

      h2 {
        font-size: 15px;
      }
    }
    #form-div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      .form {
        font-size: 13px;
        width: 95%;
        margin: 0 auto;
        margin-top: 10px;
        flex: 1;
      }
    }
  }
  #warning {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    #alertdiv {
      background-color: white;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80%;
      /* /height: 45%; */
      padding: 20px;
      background-color: #fff;
      border-radius: 10px;
      div {
        margin-top: 10px;
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 30px;

        button {
          width: 100px;
          padding: 5px;
          border: none;
          border-radius: 5px;
          background-color: var(--theme-color);
          color: #fff;
          font-size: 15px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: #fff;
            color: var(--theme-color);
            border: 1px solid var(--theme-color);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    #signaturediv {
      position: absolute;
      top: 300px;
      height: 100vh;

      .signCanvas {
        background-color: white;
      }
    }
    .signa {
      width: 90%;
      height: 250px;
      canvas {
        height: 55%;
      }
    }
  }
`;
