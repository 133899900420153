import React from "react";
import { VerifyStyle } from "../styles/Verify.styled";

export default function Verify() {
  function goto() {
    sessionStorage.setItem("verified", "oonaman");
    window.location.href = "/client/fillup";
  }
  return (
    <VerifyStyle>
      <div id="main">
        <div id="container">
          <h4>Enter the code sent to your email below</h4>
          <input type="text" placeholder="Enter code" />
          <button onClick={goto}>Proceed</button>
        </div>
      </div>
    </VerifyStyle>
  );
}
