import styled from "styled-components";

export const IteneraryContainer = styled.div`
  padding: 20px;
  h2 {
    margin-bottom: 20px;
  }
  .map {
    height: 80vh;
    font-size: 14px;
    overflow-y: scroll;
    margin-left: 20px;
    width: 70%;
    margin-top: 20px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .map-container {
      margin: 5px 0px;
    }
  }
  .daily-wrapper {
    display: flex;
  }
  form {
    width: 30%;
    height: 80vh;
    overflow-y: scroll;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    button {
      width: 80px;
      height: 30px;
      border: none;
      border-radius: 5px;
      background-color: var(--theme-color);
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #fff;
        color: var(--theme-color);
        border: 1px solid var(--theme-color);
      }
    }
  }
  .form-input {
    width: 300px;
    display: flex;
    align-items: center;

    margin: 10px 0px;
    label {
      width: 100px;
    }
    select {
      width: 180px;
    }
    input {
      width: 180px;
      font-size: 14px;
    }
  }
`;
