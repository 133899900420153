import styled from "styled-components";

export const AddClientContainer = styled.div`
  width: 500px;
  height: 500px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;

  form {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;

    .left {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      .inputs {
        margin-top: 10px;
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;

        label {
          width: 200px;
          height: 30px;
          font-size: 14px;
          font-weight: 600;
          color: var(--theme-color);
          margin: 6px 4px;
        }
        .services {
          width: 100%;

          .rmsc {
            --rmsc-main: #4285f4;
            --rmsc-hover: #f1f3f5;
            --rmsc-selected: #e2e6ea;
            --rmsc-border: #ccc;
            --rmsc-gray: #131313;
            --rmsc-bg: #fff;
            --rmsc-p: 10px;
            --rmsc-radius: 4px;
            --rmsc-h: 40px;
          }
          .dropdown-container {
            height: 30px;
            max-width: 340px;
            min-width: 340px;
            .dropdown-heading {
              position: unset;
              width: 200%;
            }

            .dropdown-content {
              width: 200%;
              * {
                height: unset;
                padding: 0;
                margin: 0;
              }

              label {
                width: 100%;
              }
            }
          }
        }

        input {
          width: 100%;
          height: 30px;
          border: 1px solid #ccc;
          border-radius: 5px;
          padding: 0.5rem;
          font-size: 12px;
          font-weight: 500;
          border: 1px solid #131313;
          border-radius: 5px;
          letter-spacing: 1px;
          outline: none;
        }

        select {
          width: 100%;
          height: 30px;
          border: 1px solid #131313;
          border-radius: 5px;
          padding: 0 10px;
          font-size: 0.8rem;
          font-weight: 500;
          letter-spacing: 1px;
          outline: none;
        }
      }

      .inputss {
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;

        label {
          width: 200px;
          height: 30px;
          font-size: 14px;
          font-weight: 600;
          color: var(--theme-color);
          margin: 6px 4px;
        }
        .services {
          width: 100%;

          .rmsc {
            --rmsc-main: #4285f4;
            --rmsc-hover: #f1f3f5;
            --rmsc-selected: #e2e6ea;
            --rmsc-border: #ccc;
            --rmsc-gray: #131313;
            --rmsc-bg: #fff;
            --rmsc-p: 10px;
            --rmsc-radius: 4px;
            --rmsc-h: 30px;
          }
          .dropdown-container {
            height: 30px;
            width: 300px;
            position: unset;
            .dropdown-content {
              width: 100%;
              * {
                height: unset;
                padding: 0;
                margin: 0;
              }
              div {
                padding: 5px;
              }

              label {
                width: 100%;
                color: black;
              }
            }
          }
        }
      }
    }
  }

  /* form {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    .addClient__body__input {
      width: 100%;
      min-height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 1rem;
      position: relative;

      label {
        position: absolute;
        top: 0;
        left: 10px;
        width: 100px;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 1px;
        margin-bottom: 0.5rem;
        color: #fff;
      }

      input {
        width: calc(100% - 120px);
        height: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 0.5rem;
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: 1px;
        outline: none;
      }

      select {
        width: calc(100% - 120px);
        height: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 0.8rem;
        font-weight: 500;
        letter-spacing: 1px;
        outline: none;

        option {
          font-size: 1rem;
          font-weight: 500;
          letter-spacing: 1px;
        }
      }
    }
    .div {
      width: 100%;
      display: flex;
      justify-content: end;
      margin: 10px 0px;

      span {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .services {
      width: 100%;
      min-height: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1rem;
      gap: 10px;

      label {
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 1px;
        margin-bottom: 0.5rem;

        margin-left: 10px;
        width: 100px;
      }
      .rmsc {
        --rmsc-main: #4285f4;
        --rmsc-hover: #f1f3f5;
        --rmsc-selected: #e2e6ea;
        --rmsc-border: #ccc;
        --rmsc-gray: #131313;
        --rmsc-bg: #fff;
        --rmsc-p: 10px; 
        --rmsc-radius: 4px; 
        --rmsc-h: 30px; 
      }
      .dropdown-container {
        height: 30px;
        max-width: 325px;
        min-width: 325px;

        .dropdown-content {
          width: 100%;
          label {
            width: 100%;
           
          }
        }
      }
     
    }

    .addClient__body__buttons {
      width: 100%;
      min-height: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100px;
        height: 100%;
        border: none;
        border-radius: 5px;
        background-color: var(--theme-color);
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 1px;
        margin-left: 1rem;
        cursor: pointer;
        outline: none;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #fff;
          color: var(--theme-color);
          border: 1px solid var(--theme-color);
        }
      }
    }
  } */
`;
