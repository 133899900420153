import React, { useEffect, useState } from "react";
import Contract from "./Contract";
import axios from "axios";
import { EditContractStyle } from "../styles/EditContract.styled";

export default function EditContract() {
  const [currentPrices, setCurrentPrices] = useState();
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (currentPrices) {
      console.log(Object.keys(currentPrices));
    }
  }, [currentPrices]);

  useEffect(() => {
    async function getPrice() {
      await axios
        .get("https://api5.teamfdjfs.com/prices/getAllPrices")
        .then((res) => {
          console.log(res.data);
          setCurrentPrices(res.data);
          //props.setCurrentPrices(res.data);
        });
    }
    getPrice();
  }, []);
  return (
    <EditContractStyle>
      <div>
        <h1>Edit Contract Price</h1>
        <div className="contt">
          <div className="option">
            <details open>
              <summary>Contracts</summary>
              {currentPrices &&
                Object.keys(currentPrices).map((val, index) => {
                  console.log(val);
                  return (
                    <div key={index} className="label">
                      <label key={index}>
                        <input
                          name="contracts"
                          type="radio"
                          value={currentPrices[val].service}
                          onChange={(e) => {
                            setSelected(e.target.value);
                          }}
                        />
                        {currentPrices[val].name}
                      </label>
                    </div>
                  );
                })}
              {/* <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" />
          <input name="contracts" type="radio" /> */}
            </details>
          </div>
          <div className="contracttt">
            {selected && (
              <Contract
                //setCurrentPrices={setCurrentPrices}
                contractservice={selected}
                contractdata={currentPrices ? currentPrices : null}
                fromProps={true}
              />
            )}
          </div>
        </div>
      </div>
    </EditContractStyle>
  );
}
