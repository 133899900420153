import { PrimaryIntakeContainer } from "../../styles/ProfilePrimaryIntake.styled";
import { getInfo, submitFunc } from "./MemorialCard";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const FlowerInstructions = ({ setShowForms2, setFlowerInstructions }) => {
  let { clientId } = useParams();
  clientId = Number(clientId);

  useEffect(() => {
    getInfo(
      "https://api5.teamfdjfs.com/standby/saveData",
      ".flowerinstruction",
      clientId,
      "flowerinstruction"
    );
  }, []);

  return (
    <PrimaryIntakeContainer id="addclient">
      <form>
        <h3>Flower Instructions</h3>
        <div className="intake__input">
          <label>Type : </label>
          <select className="flowerinstruction">
            <option value="">Select</option>
            <option value="Casket Spray">Casket Spray</option>
            <option value="Standing Spray">Standing Spray</option>
          </select>
        </div>

        <div className="intake__input">
          <label> Ribbon: </label>
          <input className="flowerinstruction" type="txt" />
        </div>

        <div className="intake__input">
          <label>Color</label>
          <input className="flowerinstruction" type="text" />
        </div>

        <div className="intake__input">
          <label>Special instructions : </label>
          <textarea className="flowerinstruction" />
        </div>

        <h3>Additional Flower Order</h3>
        <div className="intake__input">
          <label>Type: </label>
          <input className="flowerinstruction" type="txt" />
        </div>

        <div className="intake__input">
          <label> Ribbon : </label>
          <input className="flowerinstruction" type="txt" />
        </div>

        <div className="intake__input">
          <label>Color</label>
          <input className="flowerinstruction" type="text" />
        </div>

        <div className="intake__input">
          <label>Special instructions: </label>
          <textarea className="flowerinstruction" />
        </div>

        <div className="intake__input">
          <label>Florist: </label>
          <textarea className="flowerinstruction" />
        </div>

        <div className="intake__input">
          <label>Order Number: </label>
          <textarea className="flowerinstruction" />
        </div>
      </form>
      <div className="intake__btnn">
        <button
          onClick={() => {
            submitFunc(
              "https://api5.teamfdjfs.com/standby/saveData",
              ".flowerinstruction",
              clientId,
              "flowerinstruction"
            );
          }}
        >
          Save
        </button>

        <button
          type="button"
          onClick={() => {
            setShowForms2(false);
            setFlowerInstructions(false);
          }}
        >
          Exit Window
        </button>
      </div>
    </PrimaryIntakeContainer>
  );
};

export default FlowerInstructions;
