import styled from "styled-components";

export const ProfilePageContainer = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  flex-direction: column;
  position: relative;
  .divv {
    display: flex;
    align-items: center;
  }

  .wrapper {
    width: 98vw;
    height: 98vh;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .main-content {
      width: calc(100% - 300px);
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding: 0 10px;

      .content {
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        overflow-y: scroll;
      }
    }
  }
  .upd {
    width: 110px;
    height: 30px;
    margin-top: 4px;
    padding: 4px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--theme-color);
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #fff;
      color: var(--theme-color);
      border: 1px solid var(--theme-color);
    }
  }

  .bottom {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    .form-wrapper {
      width: 520px;
      height: 600px;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      backdrop-filter: blur(12px) saturate(138%);
      -webkit-backdrop-filter: blur(12px) saturate(138%);
      background-color: rgba(155, 155, 155, 0.9);
      border-radius: 12px;
      border: 1px solid rgba(209, 213, 219, 0.3);
    }
    .switch {
      width: 100%;
      height: 35px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      padding: 0 10px;
      gap: 10px;
      margin-bottom: 10px;
      position: relative;
      .closee {
        /* background-color: var(--theme-color); */
        position: absolute;
        background-color: var(--theme-color);
        border-radius: 50%;
        color: #fff;
        top: 0;
        right: 0;
        margin-right: -18px;
        margin-top: -26px;
        &:hover {
          /* background-color: #fff; */
          color: #fff;
          border: 1px solid var(--theme-color);
          cursor: pointer;
        }
      }
    }

    .item {
      width: 100%;
      height: 100%;
      background: #fff;
      color: rgb(31 41 55);
      font-weight: 600;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }

    .item.active {
      background: var(--theme-color);
      color: #fff;
      opacity: 1;
    }
  }
`;
