import { useState, useEffect } from "react";
import { CgNotes } from "react-icons/cg";
import { FaGoogleDrive, FaFileContract, FaHeartbeat } from "react-icons/fa";
import logo from "../../images/fdjfslogo.jpg";
import { GiPathDistance, GiPeaceDove, GiCoffin, GiDove } from "react-icons/gi";
import { UserSideContainer } from "../../styles/UserSide.styled";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import {
  SiGmail,
  SiGooglecalendar,
  SiGooglemaps,
  SiCanva,
} from "react-icons/si";
import { IoMdCall } from "react-icons/io";
import {
  AiOutlineCalendar,
  AiOutlineGoogle,
  AiOutlineHome,
  AiFillYoutube,
} from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";
import { SlEvent } from "react-icons/sl";
import { RiCalendarTodoLine } from "react-icons/ri";
import { BiLogOut } from "react-icons/bi";
import { TbLetterD } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useSignOut } from "react-auth-kit";
const UserSide = ({
  setCurrentCase,
  setIteneraries,
  setDailyTracker,
  setEventCalendar,
  setUserProfile,
  setTaskCalendar,
  setSchedule,
  setClient,
  setHome,
  setContract,
  user,
  image,
}) => {
  const [toggle, setToggle] = useState(false);
  const signOut = useSignOut();
  const navigate = useNavigate();

  useEffect(() => {
    let timerId;
    if (toggle) {
      timerId = setTimeout(() => {
        setToggle(false);
      }, 60000); // 60 seconds in milliseconds
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [toggle]);

  return (
    <UserSideContainer>
      <div className="toggle">
        {toggle ? (
          <MdKeyboardArrowLeft
            className="icons"
            onClick={() => {
              setToggle(!toggle);
            }}
          />
        ) : (
          <MdKeyboardArrowRight
            className="icons"
            onClick={() => {
              setToggle(!toggle);
            }}
          />
        )}
      </div>
      <div className={toggle ? "full" : "mini"}>
        <div
          className="side-header"
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          <div className="logo">
            <img
              src={
                logo
                  ? logo
                  : "https://freepngimg.com/thumb/funeral/28587-3-funeral-file-thumb.png"
              }
              alt="logo"
            />
          </div>
          <span className={toggle ? "logo-title" : "Off"}>
            Funeral Application
          </span>
        </div>
        <ul className="side-nav">
          <li
            onClick={() => {
              navigate(`/user/${window.localStorage.getItem("id")}/dashboard`);
            }}
          >
            <AiOutlineHome className="icons" />
            <span className={toggle ? "Title" : "Off"}>Dashboard</span>
          </li>
          <li
            id="funeral-toggle"
            onClick={() => {
              document.getElementById("funerals").classList.toggle("on");
              if (
                document.getElementById("funerals").classList.contains("on")
              ) {
                document
                  .getElementById("funeral-toggle")
                  .classList.add("active");
              }
              if (
                document.getElementById("funerals").classList.contains("on") ===
                false
              ) {
                document
                  .getElementById("funeral-toggle")
                  .classList.remove("active");
              }
            }}
          >
            <GiPeaceDove className="icons" />

            <span className={toggle ? "Title" : "Off"}>Funeral</span>
          </li>
          <div id="funerals" className="sub-menu">
            <ul>
              <li
                onClick={() => {
                  navigate(`/user/${window.localStorage.getItem("id")}/client`);
                }}
              >
                <BsFillPeopleFill className="icons" />
                <span className={toggle ? "Title" : "Off"}>Client</span>
              </li>
              <li
                onClick={() => {
                  navigate(
                    `/user/${window.localStorage.getItem("id")}/contract`
                  );
                }}
              >
                <FaFileContract className="icons" />
                <span className={toggle ? "Title" : "Off"}>Contracts</span>
              </li>
              <li
                onClick={() => {
                  navigate(
                    `/user/${window.localStorage.getItem("id")}/itenerary`
                  );
                }}
              >
                <GiPathDistance className="icons" />

                <span className={toggle ? "Title" : "Off"}>Itineraries</span>
              </li>
              <li
                onClick={() => {
                  navigate(`/user/${window.localStorage.getItem("id")}/daily`);
                }}
              >
                <CgNotes className="icons" />
                <span className={toggle ? "Title" : "Off"}>Daily Tracker</span>
              </li>
            </ul>
          </div>
          <li
            id="calendar-toggle"
            onClick={() => {
              document.getElementById("events").classList.toggle("on");
              if (document.getElementById("events").classList.contains("on")) {
                document
                  .getElementById("calendar-toggle")
                  .classList.add("active");
              }
              if (
                document.getElementById("events").classList.contains("on") ===
                false
              ) {
                document
                  .getElementById("calendar-toggle")
                  .classList.remove("active");
              }
            }}
          >
            <AiOutlineCalendar className="icons" />
            <span className={toggle ? "Title" : "Off"}>Events</span>
          </li>
          <div id="events" className="sub-menu">
            <ul>
              <li
                onClick={() => {
                  navigate(`/user/${window.localStorage.getItem("id")}/event`);
                }}
              >
                <SlEvent className="icons" />
                <span className={toggle ? "Title" : "Off"}>Event Calendar</span>
              </li>
              <li
                onClick={() => {
                  navigate(`/user/${window.localStorage.getItem("id")}/task`);
                }}
              >
                <RiCalendarTodoLine className="icons" />
                <span className={toggle ? "Title" : "Off"}>Task Calendar</span>
              </li>
              {/* <li
                onClick={() => {
                  setCurrentCase(false);
                  setIteneraries(false);
                  setDailyTracker(false);
                  setEventCalendar(false);
                  setUserProfile(false);
                  setTaskCalendar(false);
                  setSchedule(true);
                  setClient(false);
                  setHome(false);
                  setContract(false);
                }}
              >
                <AiOutlineSchedule className="icons" />
                <span className={toggle ? "Title" : "Off"}>Schedule</span>
              </li> */}
            </ul>
          </div>
          <li
            id="toggle-google"
            onClick={() => {
              document.getElementById("google").classList.toggle("on");
              if (document.getElementById("google").classList.contains("on")) {
                document
                  .getElementById("toggle-google")
                  .classList.add("active");
              } else {
                document
                  .getElementById("toggle-google")
                  .classList.remove("active");
              }
              // e.target.classList.remove("active");

              // console.log(document.getElementById("google").classList);
            }}
          >
            <AiOutlineGoogle className="icons" />
            <span className={toggle ? "Title" : "Off"}>Links</span>
          </li>
          <div id="google" className="sub-menu">
            <ul>
              <li
                onClick={() => {
                  window.open("https://drive.google.com/");
                }}
              >
                <FaGoogleDrive className="icons" />
                <span className={toggle ? "Title" : "Off"}>Drive</span>
              </li>
              <li
                onClick={() => {
                  window.open("https://calendar.google.com/calendar/");
                }}
              >
                <SiGooglecalendar className="icons" />
                <span className={toggle ? "Title" : "Off"}>Calendar</span>
              </li>

              <li
                onClick={() => {
                  window.open("https://www.google.com/maps/");
                }}
              >
                <SiGooglemaps className="icons" />
                <span className={toggle ? "Title" : "Off"}>Maps</span>
              </li>
              <li
                onClick={() => {
                  window.open("https://www.gmail.com/");
                }}
              >
                <SiGmail className="icons" />
                <span className={toggle ? "Title" : "Off"}>Gmail</span>
              </li>
              <li
                onClick={() => {
                  window.open(
                    "https://l.facebook.com/l.php?u=https%3A%2F%2Fhcsauth.health.ny.gov%2Fhcsauthenticationendpoint%2Flogin.do%3FRelayState%3D%25252Fhcs%25252Findex.html%26commonAuthCallerPath%3D%252Fsamlsso%26forceAuth%3Dfalse%26passiveAuth%3Dfalse%26tenantDomain%3Dcarbon.super%26sessionDataKey%3D65c2e17a-98dd-4191-a7ed-ccf58015a32a%26relyingParty%3Dhcs%26type%3Dsamlsso%26sp%3Dhcs%26isSaaSApp%3Dfalse%26authenticators%3DBasicInternalCustomAuthenticator%253ALOCAL%26fbclid%3DIwAR1g3C--B02VMSDXj4QgraWMtNJnkayLeAtzrY-0R7Yx8t49Au07EiOfU9Y&h=AT31SjegslSgaMpn4kJVggVoanEfhAXwQ3d1nJ9wuUFMVzhdqfI098jD5TcvTjUgX-N8KKtqql_yj9qS8QcI-52OAqq0sX2Fes9QzaK85ilPmxftxcgqYoBaghrcPm9I64dg2Q"
                  );
                }}
              >
                <TbLetterD className="icons" />
                <span className={toggle ? "Title" : "Off"}>Dave</span>
              </li>
              <li
                onClick={() => {
                  window.open(
                    "https://www.fdjfuneralservices.com/?fbclid=IwAR2yeAJcnXx4H44XACAzvkGi8OnfQbyI9CTYTsRS76z0gCIqnuOFHUbg8Tw"
                  );
                }}
              >
                <GiDove className="icons" />
                <span className={toggle ? "Title" : "Off"}>FDJ Website</span>
              </li>
              <li
                onClick={() => {
                  window.open("https://www.canva.com/");
                }}
              >
                <SiCanva className="icons" />
                <span className={toggle ? "Title" : "Off"}>Canva</span>
              </li>
              <li
                onClick={() => {
                  window.open("https://www.youtube.com/");
                }}
              >
                <AiFillYoutube className="icons" />
                <span className={toggle ? "Title" : "Off"}>Youtube</span>
              </li>
              <li
                onClick={() => {
                  window.open(
                    "https://docs.google.com/spreadsheets/d/1cbsfpOpGO-HBP_bgJU3uxziUwUZcthVO910sYIHhv2E/edit?fbclid=IwAR0cyxfWB7PYEfo_8vqU8go9Cxv2qy71Wuc7uY7PQMgwmGrPdRYYfumGBm4#gid=1546129106"
                  );
                }}
              >
                <GiCoffin className="icons" />
                <span className={toggle ? "Title" : "Off"}>Casket Orders</span>
              </li>

              <li
                onClick={() => {
                  window.open(
                    "https://l.facebook.com/l.php?u=https%3A%2F%2Fwww1.nyc.gov%2Faccount%2Flogin.htm%3FspName%3Da816-evital.nyc.gov-evital%26samlContext%3Dus1_8176884_045d3c59-e9e0-4880-99ba-ae02bdffb83f%26fbclid%3DIwAR3M0bc27JT1jO4BDAmmmxeJt7_cREihgZpAq5kI-yvOwQwmKfpPhEALob4&h=AT2TpTtz8VYsDkxmH8ggPfenajZvjnYKAz6iJglU_RXRWih1GFdER1pE71_5PhQN-tQIFqv7YhmTvDt1DVelYSJenGozE-ZJBZhXBqIdiUIoWSBq9BxIJf3AgHN2C9lZxBukmA"
                  );
                }}
              >
                <FaHeartbeat className="icons" />
                <span className={toggle ? "Title" : "Off"}>E-Vital</span>
              </li>
              <li
                onClick={() => {
                  window.open("https://businessdigital.verizon.com/rep/");
                }}
              >
                <IoMdCall className="icons" />
                <span className={toggle ? "Title" : "Off"}>Call FWD</span>
              </li>
            </ul>
          </div>
        </ul>

        <div
          className="bottom"
          onClick={() => {
            // setCurrentCase(false);
            //       setIteneraries(false);
            //       setDailyTracker(false);
            //       setEventCalendar(false);
            //       setUserProfile(false);
            //       setTaskCalendar(false);
            //       setSchedule(false);
            //       setClient(false);
            //       setHome(false);
            //       setContract(false);
            //       setProfile(true);
            navigate(`/user/${window.localStorage.getItem("id")}/profile`);
          }}
        >
          <div className="bottom_img">
            <img
              src={
                image ? image : "https://www.w3schools.com/howto/img_avatar.png"
              }
              alt="user"
            />
          </div>
          <span className={toggle ? "bottom-title" : "Off"}>
            {user.firstname}
          </span>
          <BiLogOut
            className={toggle ? "icons" : "off"}
            onClick={() => {
              const confirm = window.confirm(
                "Are you sure you want to logout?"
              );
              if (!confirm) return;

              signOut();
              window.localStorage.removeItem("firstname");
              window.localStorage.removeItem("lastname");
              window.localStorage.removeItem("email");
              window.localStorage.removeItem("id");
              window.localStorage.removeItem("role");
              navigate("/");
            }}
          />
        </div>
      </div>
    </UserSideContainer>
  );
};

export default UserSide;
