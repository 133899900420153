import styled from "styled-components";

export const DailyTrackerContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  position: relative;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;

    h1 {
      font-size: 20px;
      font-weight: 500;
    }

    .add_btn {
      padding: 10px 20px;
      border-radius: 5px;
      border: none;
      outline: none;
      cursor: pointer;
      background-color: var(--theme-color);
      color: #fff;
      font-size: 14px;
      font-weight: 500;

      :hover {
        opacity: 0.8;
        transform: scale(0.98);
      }
    }
  }
  .daily-tracker {
    height: 100%;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    margin-top: 20px;
    overflow-y: scroll;

    th,
    td {
      padding: 8px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      font-size: 13px;
      border: 1px solid grey;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    th {
      color: black;
      border: 1px solid grey;
      text-align: center;
      :nth-child(1) {
        width: 10%;
        padding: 10px;
      }

      :nth-child(2) {
        width: 10%;
        padding: 10px;
      }

      :nth-child(3) {
        width: 10%;
        padding: 10px;
      }

      :nth-child(4) {
        width: 10%;
        padding: 10px;
      }

      :nth-child(5) {
        width: 10%;
        padding: 10px;
      }
      :nth-child(6) {
        width: 10%;
        padding: 10px;
      }

      :nth-child(7) {
        width: 10%;
        padding: 10px;
      }

      :nth-child(8) {
        width: 20%;
        padding: 10px;
      }
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: space-around;

    button {
      padding: 10px 20px;
      border-radius: 5px;
      border: none;
      outline: none;
      cursor: pointer;
      background-color: #f2f2f2;
      color: black;
    }
  }
  .edit_tracker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    height: fit-content;
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    h2 {
      font-size: 20px;
      font-weight: 500;
    }

    .edit_tracker_form {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
    }
  }

  .edit_inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    label {
      width: 30%;
      font-size: 14px;
      font-weight: 500;
    }

    input,
    select {
      width: 70%;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #ddd;
      outline: none;
      font-size: 14px;
      font-weight: 500;

      :focus {
        border: 1px solid #000;
      }

      ::placeholder {
        font-size: 14px;
        font-weight: 500;
        color: #ddd;
      }
    }
  }

  .edit_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    button {
      width: 100%;
      padding: 10px 20px;
      border-radius: 5px;
      border: none;
      outline: none;
      cursor: pointer;

      :nth-child(1) {
        background-color: var(--theme-color);
        color: #fff;
      }

      :nth-child(2) {
        background-color: #f2f2f2;
        color: #131313;
        border: 1px solid #ddd;
      }

      :hover {
        opacity: 0.8;
        transform: scale(0.98);
      }
    }
  }

  .add_tracker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    height: fit-content;
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    h2 {
      font-size: 20px;
      font-weight: 500;
    }

    .add_tracker_form {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
    }
  }
`;
