import styled from "styled-components";

export const ProfileNavContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .btn {
    height: 40px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    background: var(--theme-color);
    color: #f5f5f5;
    letter-spacing: 1px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: #fff;
      color: var(--theme-color);
      border: 2px solid var(--theme-color);
    }

    &.active {
      background: #fff;
      color: var(--theme-color);
      border: 2px solid var(--theme-color);
    }
  }
`;
