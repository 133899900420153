import { useEffect, useState, useRef } from "react";
import { TaskContainer } from "../../styles/Task.styled";
// import { format, toDate } from "date-fns";
import axios from "axios";
import io from "socket.io-client";
import AddTask from "./AddTask";
import toast, { Toaster } from "react-hot-toast";
const socket = io.connect("https://api5.teamfdjfs.com/tasks");

const Task = ({ clientId, totaskid }) => {
  const [task, setTask] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [assigned, setAssigned] = useState([]);
  const [addTask, setAddTask] = useState(false);

  // const [isfinished, setIsFinished] = useState();

  const tasktr = useRef(null);

  useEffect(() => {
    const getTask = async () => {
      const { data } = await axios.get(
        `https://api5.teamfdjfs.com/task/${clientId}`
      );
      const value = await axios.get(`https://api5.teamfdjfs.com/auth`);
      const assignedtask = await axios.get(
        `https://api5.teamfdjfs.com/task/assign/${clientId}`
      );
      setUsers(value.data);
      setTask(data);
      setAssigned(assignedtask.data);
    };
    getTask();
  }, [clientId]);

  const bulkStatus = async (e) => {
    console.log(e.target.value);
    const data = {
      status: e.target.value,
    };
    if (selectedTasks.length > 0 && e.target.value !== "") {
      selectedTasks.map(async (id) => {
        await axios
          .put(`https://api5.teamfdjfs.com/task/status/${id}`, data)
          .then((response) => {
            console.log(response.data.message);
            if (response.data.message) {
              const newTask = task.map((task) => {
                if (task.id === id) {
                  return {
                    ...task,
                    status: e.target.value,
                  };
                } else {
                  return task;
                }
              });
              setTask(newTask);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      });
      // alert("Status updated");
    }
  };
  const handleStatus = (id) => async (e) => {
    const { value } = e.target;
    const data = {
      status: value,
    };
    await axios
      .put(`https://api5.teamfdjfs.com/task/status/${id}`, data)
      .then((response) => {
        console.log(response.data.message);
        if (response.data.message) {
          const newTask = task.map((task) => {
            if (task.id === id) {
              return {
                ...task,
                status: value,
              };
            } else {
              return task;
            }
          });
          setTask(newTask);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      tasktr?.current?.focus();
    }, 1000);
  }, []);

  // const formatDate = (date) => {
  //   const options = { day: "2-digit", month: "long", year: "numeric" };
  //   const formattedDate = new Date(date).toLocaleDateString("en-US", options);

  //   const [month, day, year] = formattedDate.split(" ");

  //   return `${day.replace(/,/g, "")} ${month} ${year}`;
  // };

  useEffect(() => {
    console.log(selectedTasks);
  }, [selectedTasks]);
  const formatDate = (date) => {
    if (date !== null || date !== "0000-00-00") {
      const newDate = new Date(date);
      const day =
        newDate.getDate() > 9 ? newDate.getDate() : `0${newDate.getDate()}`;
      const month =
        newDate.getMonth() > 9
          ? newDate.getMonth() + 1
          : `0${newDate.getMonth() + 1}`;
      const year = newDate.getFullYear();
      return `${year}-${month}-${day}`;
    } else {
      return "";
    }
  };
  const handleNotes = (id) => async (e) => {
    const value = e.target.innerHTML.replaceAll("/n", "<br />");
    console.log(value);
    const data = {
      notes: value,
    };
    await axios
      .put(`https://api5.teamfdjfs.com/task/notes/${id}`, data)
      .then((response) => {
        console.log(response.data.message);
        if (response.data.message) {
          const newTask = task.map((task) => {
            if (task.id === id) {
              return {
                ...task,
                notes: value,
              };
            } else {
              return task;
            }
          });
          setTask(newTask);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const bulkAssign = async (e) => {
    const assign_task = document.getElementById("bulk-assign").value;
    console.log(assign_task);
    selectedTasks.map(async (id) => {
      const data = {
        task_id: id,
        assigned_to: assign_task,
        assigned_by: window.localStorage.getItem("id"),
        assigned_date: new Date(),
        client_id: clientId,
      };
      console.log(data);
      if (data.assigned_to) {
        await axios
          .post(`https://api5.teamfdjfs.com/task/assign/${clientId}`, data)
          .then((response) => {
            console.log(response);

            if (response.data.message.text === "already assigned") {
              alert("Tasks already assigned: ");
            }

            if (response.data.tasks) {
              setAssigned(response.data.tasks);
              socket.emit("update-task", { message: "update" });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    });

    alert("Success");
  };
  const handleAssign = (task_id) => () => {
    const user_id = document.getElementById("user" + task_id).value;

    const data = {
      task_id: task_id,
      assigned_to: user_id,
      assigned_by: window.localStorage.getItem("id"),
      assigned_date: new Date(),
      client_id: clientId,
    };
    console.log(data);
    if (data.assigned_to) {
      axios
        .post(`https://api5.teamfdjfs.com/task/assign/${clientId}`, data)
        .then((response) => {
          console.log(response);

          if (response.data.message.text === "already assigned") {
            alert("Tasks already assigned: ");
          } else {
            alert("Success");
          }
          if (response.data.tasks) {
            setAssigned(response.data.tasks);
            socket.emit("update-task", data);
          }
        });
    }
  };
  const bulkDate = async (e) => {
    console.log(e.target.value);
    const data = {
      due_date: e.target.value,
    };
    selectedTasks.map(async (id) => {
      await axios
        .put(`https://api5.teamfdjfs.com/task/date/${id}`, data)
        .then((response) => {
          console.log(response.data.message);
          if (response.data.message) {
            const newTask = task.map((task) => {
              if (task.id === id) {
                return {
                  ...task,
                  due_date: formatDate(e.target.value),
                };
              } else {
                return task;
              }
            });
            setTask(newTask);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    });
    // alert("Success");
  };
  const handleDate = (id) => async (e) => {
    const value = e.target.value;
    const data = {
      due_date: value,
    };
    await axios
      .put(`https://api5.teamfdjfs.com/task/date/${id}`, data)
      .then((response) => {
        console.log(formatDate(value));
        if (response.data.message) {
          const newTask = task.map((task) => {
            if (task.id === id) {
              return {
                ...task,
                due_date: formatDate(value),
              };
            } else {
              return task;
            }
          });
          setTask(newTask);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <TaskContainer>
      <div className="banner">
        <h2 className="title">Task List</h2>
        <div className="button">
          <button
            className="btn"
            onClick={() => {
              setAddTask(true);
            }}
          >
            Add Task
          </button>
        </div>
        <div className="assign">
          <input type="date" onChange={bulkDate} />
          <select name="status" onChange={bulkStatus}>
            <option value="">Select Status</option>
            <option value="pending">Pending</option>
            <option value="inprogress">In Progress</option>
            <option value="completed">Completed</option>
            <option value="blocked">Blocked</option>
          </select>
          <select name="bulk-assign" id="bulk-assign">
            <option value="">--Assign Task to--</option>
            {users.map((user, key) => (
              <option key={key} value={user.id}>
                {user.firstname}
              </option>
            ))}
          </select>
          <button
            className="btn"
            type="button"
            onClick={bulkAssign}
            // disabled={true}
          >
            Go
          </button>
        </div>
      </div>
      {addTask && (
        <AddTask
          clientId={clientId}
          setAddTask={setAddTask}
          setTask={setTask}
          task={task}
          setAssigned={setAssigned}
          assigned={assigned}
          toast={toast}
        />
      )}
      <div className="task-list">
        {/* heading */}
        <table>
          <tbody>
            <tr className="heading">
              <th>Task</th>
              <th>Due Date</th>
              <th>Status</th>
              <th>Task Group</th>
              <th>Notes</th>
            </tr>
            {/* task list */}
            {task.map((task) => {
              const match = assigned.find((t) => t.task_id === task.id);
              let index = assigned.findIndex((i) => i.id === match?.id);
              if (totaskid === task.id) {
                //document.getElementById(`${"tr" + task.id}`).focus();
              }
              return (
                <tr
                  className="task"
                  key={task?.id}
                  id={`${"tr" + task.id}`}
                  tabIndex="0"
                  ref={task?.id === totaskid ? tasktr : null}
                >
                  <td className="name">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedTasks([...selectedTasks, task.id]);
                        } else {
                          setSelectedTasks(
                            selectedTasks.filter((id) => id !== task.id)
                          );
                        }
                      }}
                    />
                    <p>{task.description}</p>
                  </td>
                  <td>
                    <p>
                      <input
                        type="date"
                        value={task?.due_date ? formatDate(task?.due_date) : ""}
                        onChange={handleDate(task.id)}
                      />
                    </p>
                  </td>
                  <td>
                    <select
                      value={task.status}
                      name="status"
                      id="status"
                      onChange={handleStatus(task.id)}
                    >
                      <option value="pending">Pending</option>
                      <option value="inprogress">In Progress</option>
                      <option value="completed">Completed</option>
                      <option value="blocked">Blocked</option>
                    </select>
                  </td>
                  <td>
                    {match?.id ? (
                      <select
                        name={task.id}
                        id={"user" + task.id}
                        // defaultValue={assigned[index]?.assigned_to}
                        value={assigned[index]?.assigned_to}
                        onChange={(e) => {
                          // if (e.target.value == 0) {
                          //   e.target.nextSibling.disabled = true;
                          // } else {
                          //   e.target.nextSibling.disabled = false;
                          // }
                          assigned[index].assigned_to = e.target.value;
                          console.log(assigned[index]);
                          setAssigned([...assigned]);
                        }}
                      >
                        <option value="">--Assign Task to--</option>
                        {users.map((user, key) => (
                          <option key={key} value={user.id}>
                            {user.firstname}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select name={task.id} id={"user" + task.id}>
                        <option value="">--Assign Task to--</option>
                        {users.map((user, key) => (
                          <option key={key} value={user.id}>
                            {user.firstname}
                          </option>
                        ))}
                      </select>
                    )}

                    <button
                      className="btn"
                      type="button"
                      onClick={handleAssign(task.id)}
                      // disabled={true}
                    >
                      Go
                    </button>
                  </td>
                  <td>
                    <p
                      className="notes"
                      contentEditable={true}
                      suppressContentEditableWarning
                      onBlur={handleNotes(task.id)}
                      dangerouslySetInnerHTML={{ __html: task.notes }}
                    >
                      {/* {task.notes} */}
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Toaster />
    </TaskContainer>
  );
};

export default Task;
