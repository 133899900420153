import { useState, useEffect } from "react";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import { UpdateClientContainer } from "../../styles/UpdateClient.styled";
const UpdateClient = ({
  client,
  serviceoption,
  setClient,
  setShowForms,
  setAddClient,
  setNewClient,
  donthidebutton,
}) => {
  const [firstName, setFirstName] = useState(client.firstname);
  const [relationship, setRelationship] = useState(client.relationship);
  const [telephone, setTelephone] = useState(client.telephone);
  const [email, setEmail] = useState(client.email);
  const [decName, setDecName] = useState(client.decname);
  const [serviceTypes, setServiceType] = useState(serviceoption);
  const [services, setServices] = useState([]);
  const [inquiryStatus, setInquiryStatus] = useState(
    client.updatedinquiry ? client.updatedinquiry : client.inquirystatus
  );

  const [notes, setNotes] = useState(client.notes);
  const [viewservice, setViewService] = useState();

  //console.log(cso);
  //setServices(cso);

  useEffect(() => {
    let newval = {
      firstName,
      relationship,
      telephone,
      email,
      decName,
      serviceTypes,
      services,
      inquiryStatus,

      notes,
    };
    setNewClient((prev) => ({ ...prev, client: newval }));
  }, [
    firstName,
    relationship,
    telephone,
    email,
    decName,
    serviceTypes,
    services,
    inquiryStatus,
    notes,
  ]);

  useEffect(() => {
    // console.log(obj);
    //setServiceType(obj);
    setServices(
      client?.services
        ? client.servicetype?.split(", ")?.map((i) => {
            const findItem = serviceoption.find((item) => item.value === i);
            if (findItem) {
              return findItem;
            }
          })
        : []
    );

    //getprice();

    async function getAllDetails() {
      const { data } = await axios.get(
        `https://api5.teamfdjfs.com/client/selectClientAll/${client.id}`
      );
      console.log(data);
    }
    getAllDetails();

    let cso = serviceoption.filter((item) => {
      if (
        client.servicetype?.split(", ")?.includes(item.value) ||
        client.servicetype === item.value
      ) {
        return item;
      }
    });
    setServices(cso);
  }, []);

  useEffect(() => {
    if (viewservice) {
      window.open(`/contracts/${viewservice}`, "_blank");
    }
  }, [viewservice]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (services?.length === 0) {
      alert("Please select at least one service");
      return;
    }
    const serArr = await services.map((service) => service.value).join(", ");
    try {
      const res = await axios.put(
        `https://api5.teamfdjfs.com/client/${client.id}`,
        {
          firstname: firstName === "" ? client.firstname : firstName,
          relationship:
            relationship === "" ? client.relationship : relationship,
          telephone: telephone === "" ? client.telephone : telephone,
          email: email === "" ? client.email : email,
          decname: decName === "" ? client.decname : decName,
          servicetype: serArr,
          inquirystatus: inquiryStatus === "" ? null : inquiryStatus,
          notes: notes === "" ? client.notes : notes,
        }
      );
      if (res.data) {
        setClient(res.data);
        setShowForms(false);
      }
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <UpdateClientContainer>
      <form onSubmit={onSubmit}>
        {/* inputs */}
        <div className="left">
          <div className="inputs">
            <label htmlFor="FirstName">Contact Name</label>
            <input
              className="foraddclient"
              type="text"
              required
              defaultValue={firstName || client.firstname}
              onChange={(e) => setFirstName(e.target.value)}
              // defaultValue={client.firstname}
            />
          </div>

          {/* <div className="addClient__body__input">
          <label htmlFor="MiddleName">Middle Name</label>
          <input
            type="text"
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
          />
        </div>

        <div className="addClient__body__input">
          <label htmlFor="LastName">Last Name</label>
          <input
            type="text"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div> */}

          <div className="inputs">
            <label htmlFor="Relationship">Relationship</label>
            <select
              className="foraddclient"
              defaultValue={relationship || client.relationship}
              onChange={(e) => setRelationship(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Self">Self</option>
              <option value="Authorized Guardian">Authorized Guardian</option>
              <option value="Husband">Husband</option>
              <option value="Wife">Wife</option>
              <option value="Son">Son</option>
              <option value="Daughter">Daughter</option>
              <option value="Brother">Brother</option>
              <option value="Sister">Sister</option>
              <option value="Father">Father</option>
              <option value="Mother">Mother</option>
              <option value="Niece">Niece</option>
              <option value="Nephew">Nephew</option>
              <option value="Cousin">Cousin</option>
              <option value="Friend">Friend</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="inputs">
            <label htmlFor="Telephone">Telephone</label>
            <input
              className="foraddclient"
              type="text"
              required
              defaultValue={telephone || client.telephone}
              onChange={(e) => setTelephone(e.target.value)}
            />
          </div>

          <div className="inputs">
            <label htmlFor="Email">Email</label>
            <input
              className="foraddclient"
              type="email"
              required
              defaultValue={email || client.email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="inputs">
            <label htmlFor="DecName">Decedent Name</label>
            <input
              className="foraddclient"
              type="text"
              required
              defaultValue={decName || client.decname}
              onChange={(e) => setDecName(e.target.value)}
            />
          </div>
          {/* <div className="div">
          <span>Total price: ${totalprice}</span>
        </div> */}

          {/*  */}
          <div className="inputs">
            <label>Inquiry Status</label>
            <select
              className="foraddclient"
              defaultValue={
                client.updatedinquiry
                  ? client.updatedinquiry
                  : client.inquirystatus
              }
              onChange={(e) => setInquiryStatus(e.target.value)}
            >
              <option value="">Select</option>
              <option value="At need">At-need </option>
              <option value="Pre-need">Pre-need</option>
              <option value="Merchandise">Merchandise</option>
              <option value="Miscellaneous">Miscellaneous</option>
              <option value="Pre-need to At-need">Pre-need to At-need</option>
              <option value="Pending Pre-need">Pending Pre-need</option>
              <option value="Pending At-need">Pending At-need</option>
              <option value="Pricing for At-need">Pricing for At-need</option>
              <option value="Pricing for Pre-need">Pricing for Pre-need</option>
            </select>
          </div>

          <div className="inputss">
            <label htmlFor="ServiceType">Service Type</label>

            <div className="services">
              {serviceTypes && (
                <div style={{ position: "relative", width: "200%" }}>
                  <MultiSelect
                    style={{ width: "100%" }}
                    options={serviceTypes}
                    value={services}
                    ItemRenderer={({ option, checked, onClick }) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            marginBottom: "5px",
                          }}
                        >
                          <label
                            style={{
                              border: "1px solid #ccc",
                              fontSize: "10px",
                              width: "100%",
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={checked}
                              onChange={onClick}
                            />
                            {option.label}
                          </label>
                          <button
                            type="button"
                            onClick={() => {
                              //console.log(`Button clicked for ${option.label}`);
                              setViewService(option.value);
                            }}
                          >
                            View
                          </button>
                        </div>
                      );
                    }}
                    onChange={setServices}
                    labelledBy="Select"
                    className="multi foraddclient"
                  />
                </div>
              )}
            </div>
          </div>

          {donthidebutton && (
            <div className="btns">
              <button
                className="bt"
                type="button"
                onClick={() => {
                  setShowForms(false);
                }}
              >
                Exit Window
              </button>
              <button className="bt" type="submit">
                Update Client
              </button>
            </div>
          )}

          {/* <div className="inputs">
            <label htmlFor="Notes">Notes</label>
            <input
              className="foraddclient"
              type="textfield"
              defaultValue={notes || client.notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div> */}
        </div>
      </form>
    </UpdateClientContainer>
  );
};

export default UpdateClient;
