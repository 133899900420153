import { useState, useEffect } from "react";
import axios from "axios";
import { UserProfileContainer } from "../../styles/UserProfile.styled";
import toast from "react-hot-toast";

const UserProfile = () => {
  const id = parseInt(window.localStorage.getItem("id"));
  const [user, setUser] = useState({});
  const [image, setImage] = useState(null);

  useEffect(() => {
    axios
      .get(`https://api5.teamfdjfs.com/auth/`)
      .then((res) => {
        res.data?.find((i) => {
          if (i.id === id) {
            console.log(i?.image ? i?.image : "no image");
            setUser(i);
            setImage(i?.image ? i?.image : null);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    const img = await new Promise((resolve, reject) => {
      // reader.readAsArrayBuffer(file);
      reader.readAsDataURL(file);
      reader.onload = resolve;
      reader.onerror = reject;
    });

    // window.localStorage.setItem("image", img.target.result);
    if (img.target.result.length >= 100000) {
      toast.error("Image size is too large");
      return;
    }
    console.log(img.target.result.length);

    await axios
      .put(`https://api5.teamfdjfs.com/auth/img/${id}`, {
        image: img.target.result,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    toast.success("image uploaded");
    setImage(img.target.result);
    window.location.reload();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const firstname = document.getElementById("firstname").value;
    const lastname = document.getElementById("lastname").value;
    const email = document.getElementById("email").value;
    const username = document.getElementById("username").value;
    const inputpass = document.getElementById("password").value;
    const confirmpass = document.getElementById("confirm").value;

    if (inputpass !== confirmpass) {
      toast.error("Password doesn't match");
      return;
    }

    const request = {
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
      password: inputpass,
    };

    axios.put(`https://api5.teamfdjfs.com/auth/${id}`, request).then((res) => {
      console.log(res.data[0]);
      setUser(res.data[0]);
      toast.success("Profile Updated");
    });
  };
  return (
    <UserProfileContainer>
      <h2>Profile</h2>
      <div className="profile-container">
        <div className="profile-img">
          <label htmlFor="img-profile">
            <img
              src={
                image ? image : "https://www.w3schools.com/howto/img_avatar.png"
              }
              alt="profile"
            />
          </label>
          <input type="file" onChange={handleImageUpload} id="img-profile" />
        </div>

        <div className="profile-info">
          <h4>{user?.firstname + " " + user?.lastname}</h4>
          <p>{user?.email}</p>
          <p>{user?.role}</p>
        </div>
      </div>

      <div className="profile-edit">
        <h2>Edit Profile</h2>
        <div className="inputs">
          <div className="left">
            <div className="input">
              <label htmlFor="firstname">First Name</label>
              <input
                type="text"
                name="firstname"
                id="firstname"
                defaultValue={user?.firstname}
              />
            </div>
            <div className="input">
              <label htmlFor="lastname">Last Name</label>
              <input
                type="text"
                name="lastname"
                id="lastname"
                defaultValue={user?.lastname}
              />
            </div>
            <div className="input">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                defaultValue={user?.email}
              />
            </div>
          </div>
          <div className="right">
            <div className="input">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                name="username"
                id="username"
                defaultValue={user?.username}
              />
            </div>
            <div className="input">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                defaultValue={user?.password}
              />
            </div>

            <div className="input">
              <label htmlFor="confirm">Confirm Password</label>
              <input type="password" name="confirm" id="confirm" />
            </div>
          </div>
        </div>
        <div className="btn">
          <button className="primary" onClick={onSubmit}>
            Save
          </button>
        </div>
      </div>
    </UserProfileContainer>
  );
};

export default UserProfile;
