import styled from "styled-components";

export const ScheduleContainer = styled.div`
  width: 100%;
  height: 100%;

  .off {
    background-color: red;
  }
  .nowork {
    background-color: #f2f2f2;
  }
  .full {
    background-color: lightgreen;
  }
  .morning {
    background-color: lightblue;
  }
  .afternoon {
    background-color: lightpink;
  }
  .absent {
    background-color: yellow;
  }
  table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    thead {
      tr {
        th {
          padding: 10px;
          border: 1px solid #ddd;
          height: 50px;
          background-color: #f2f2f2;

          :nth-child(1) {
            width: 10%;
          }
          :nth-child(2) {
            width: 10%;
          }
          :nth-child(3) {
            width: 10%;
          }
          :nth-child(4) {
            width: 10%;
          }
          :nth-child(5) {
            width: 10%;
          }
          :nth-child(6) {
            width: 10%;
          }
          :nth-child(7) {
            width: 10%;
          }
          :nth-child(8) {
            width: 10%;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 10px;
          border: 1px solid #ddd;
          height: 50px;
        }
      }
    }
  }
`;
