import agreementforms from "../components/Forms/Files/Funeral-Service-Arrangement-Forms.pdf";
import axios from "axios";
import { simpleCrypto } from "../components/Email/EmailFormat";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { Document, Page } from "react-pdf";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { fetchPdf, toBlob } from "../components/Forms/Forms";

import { ClientfilledStyle } from "../styles/ClientFilled.style";
//files
import form1 from "../components/Forms/Files/Funeral Service Arrangement Forms/1 Funeral Service Record Sheet.pdf";
import form2 from "../components/Forms/Files/Funeral Service Arrangement Forms/2 Authorization for Removal Embalming Preparation.pdf";
import form3 from "../components/Forms/Files/Funeral Service Arrangement Forms/3 Control of disposition form.pdf";
import form4 from "../components/Forms/Files/Funeral Service Arrangement Forms/4 At Need Written Statement Of Person Having the Right to Control Disposition.pdf";
import form5 from "../components/Forms/Files/Funeral Service Arrangement Forms/5 Website Agreement.pdf";
import SignatureCanvas from "react-signature-canvas";

let filearray = [form1, form2, form3, form4, form5];
let namesarray = [
  "1 Funeral Service Record Sheet.pdf",
  "2 Authorization for Removal Embalming Preparation.pdf",
  "3 Control of disposition form.pdf",
  "4 At Need Written Statement Of Person Having the Right to Control Disposition.pdf",
  "5 Website Agreement.pdf",
];
let selected = [0, 1, 2, 3, 4];

export default function ClientFilled() {
  const [blobbed, setBlobbed] = useState([]);
  const [files, setFiles] = useState([]);
  const [signatures, setSignatures] = useState(Array(4));
  const [currentpage, setCurrentpage] = useState(0);
  const [decrypted, setDecryoted] = useState();
  const [isSigned, setIsSigned] = useState(false);
  const signatureRef = useRef();
  const location = useLocation();

  let dataset = decodeURIComponent(localStorage.getItem("dataset"));
  let navigator = useNavigate();

  async function submitsign(signaturedata) {
    await axios
      .post("https://api5.teamfdjfs.com/forms/submitSignature", {
        clientId: decrypted.id,
        signature: signatures,
      })
      .then((res) => {
        if (res.data.status === 200) {
          alert("Success");
          localStorage.removeItem("dataset");
          window.location.replace("/");
        }
      });
  }

  //insert sign functions
  async function savewithsign(pdfDoc, index) {
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    let newblobwithsign = [...blobbed];
    newblobwithsign[index] = blob;
    setBlobbed(newblobwithsign);
  }
  async function insertSign(blobbedarray, index, signdata) {
    let existingPdfBytes = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(blobbedarray[index]);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const page = pdfDoc.getPage(0);
    const signimage = await pdfDoc.embedPng(signdata);
    const jpgDims = signimage.scale(0.7);
    console.log(jpgDims);

    switch (index) {
      case 1:
        console.log("page1");
        page.drawImage(signimage, {
          x: page.getWidth() / 4,
          y: 310,
          width: 200,
          height: 36.5,
        });
        savewithsign(pdfDoc, index);
        break;
      case 2:
        page.drawImage(signimage, {
          x: page.getWidth() / 4,
          y: 140,
          width: 200,
          height: 36.5,
        });
        savewithsign(pdfDoc, index);
        break;
      case 3:
        page.drawImage(signimage, {
          x: page.getWidth() / 4,
          y: 90,
          width: 200,
          height: 36.5,
        });
        savewithsign(pdfDoc, index);
        break;
      case 4:
        page.drawImage(signimage, {
          x: page.getWidth() / 4,
          y: 115,
          width: 200,
          height: 36.5,
        });
        savewithsign(pdfDoc, index);
        break;
    }
  }

  useEffect(() => {
    console.log(signatures);
    if (blobbed.length > 0) {
      console.log(blobbed);
      insertSign(blobbed, currentpage, signatures[currentpage - 1]);
    }
  }, [signatures]);

  useEffect(() => {
    console.log(currentpage);
  }, [currentpage]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedObject = searchParams.get("dataset");
    console.log(encodedObject);

    let dataObject = simpleCrypto.decrypt(dataset);
    console.log(dataObject);
    if (dataObject) {
      setDecryoted(dataObject);
      fetchPdf(
        selected,
        filearray,
        namesarray,
        setFiles,
        dataObject,
        setBlobbed
      );
    }

    console.log(location, searchParams);
  }, []);

  return (
    <ClientfilledStyle id="filledformdiv">
      <div style={{ display: "none" }} id="signaturediv">
        <div className="signa">
          <p>E-Signature</p>

          <button
            className="close"
            onClick={(e) => {
              document.getElementById("signaturediv").style.display = "none";
            }}
          >
            Close
          </button>
          <SignatureCanvas
            ref={signatureRef}
            penColor="black"
            canvasProps={{
              width: 500,
              height: 200,
              className: "signCanvas",
              id: "signaturePad",
            }}
            onEnd={() => {
              setIsSigned(true);
            }}
          />
          <button
            className="clear"
            onClick={() => signatureRef.current.clear()}
          >
            Clear
          </button>
          <button
            className="save"
            onClick={() => {
              if (signatureRef.current.isEmpty()) {
                alert("Please Sign");
                return;
              }
              console.log(signatureRef.current.toDataURL());
              console.log(typeof signatureRef.current.toDataURL());
              let newsignarray = [...signatures];
              console.log(simpleCrypto.decrypt(dataset));
              let signaturedata = { id: simpleCrypto.decrypt(dataset.id) };
              console.log(signaturedata);
              newsignarray[currentpage - 1] = signatureRef.current.toDataURL();

              setSignatures(newsignarray);

              signatureRef.current.clear();
              document.getElementById("signaturediv").style.display = "none";
            }}
            disabled={!isSigned}
          >
            Save
          </button>
          {/* okay na pre nagana na try mo pre*/}
        </div>
      </div>
      <div id="genform">
        {blobbed && (
          // blobbed.map((file, key) => (
          <div className="genform" key={currentpage}>
            <Document
              file={blobbed[currentpage]}
              className="doc"
              // onClick={(e) => {
              //   console.log(e.target);
              //   setFormDisplay(key);
              // }}
            >
              <Page
                className="pdf-page"
                pageNumber={1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </Document>
          </div>
        )}
      </div>
      <div id="buttondiv">
        <div>
          <button
            // disabled={currentpage === 0 ? true : false}
            onClick={(e) => {
              if (!currentpage == 0) {
                setCurrentpage(currentpage - 1);
              }
              setIsSigned(false);
            }}
          >
            Previous
          </button>
        </div>
        <div>
          {currentpage > 0 && (
            <button
              id="signbutton"
              onClick={() => {
                if (!signatures[currentpage - 1]) {
                  document.getElementById("signaturediv").style.display =
                    "flex";
                  document.getElementById("signaturediv").style.position =
                    "sticky";
                }
              }}
            >
              Sign Document
            </button>
          )}
        </div>
        <div>
          <button
            // disabled={currentpage === 4 ? true : false}
            onClick={(e) => {
              if (currentpage < 4) setCurrentpage(currentpage + 1);
              //currentpage < 4 ? setCurrentpage(currentpage + 1) : null;

              setIsSigned(false);
            }}
          >
            Next
          </button>
        </div>
        <div className="submitt">
          {!signatures.includes(undefined) && (
            <button onClick={submitsign}> Submit</button>
          )}
        </div>
      </div>
    </ClientfilledStyle>
  );
}
